import React from 'react'
import SEOMeta from './Home/common/SEOMeta'

const DisclimerPolicy = () => {
    return (
        <div className="container mx-auto px-4 py-8">
            <SEOMeta slug="privacy" />
            <h1 className="font-bold mb-8">Disclaimer Policy</h1>
            <div className="bg-white rounded-lg shadow-md p-8 text-justify">
                <div className='mb-4'>
                    <p className="mb-4">We try to keep the website and software (hereinafter referred to as “platform”) up and running and free of annoyances. But we make no promises that we will succeed.</p>
                    <p className='mb-4'>The platform is provided “as is” and “as available” basis and to the extent permitted by law without warranties of any kind, either express or implied, including, but not limited to, implied warranties for a particular purpose, title, and non- infringement. In addition, while the company attempts to provide a good user experience, we do not represent or warrant that: (a) the platform will always be secure, and error-free, uninterrupted (b) the platform will always function without delays, disruptions, or imperfections; or (c) that any content, user content, or information you obtain on or through the platform will be timely or accurate or complete or reliable or suitable or will be available at all times. </p>
                    <p className='mb-4'>You understand and agree that you may be exposed to content that might be offensive, illegal, misleading, or otherwise inappropriate, none of which the company will be responsible for.</p>
                    <p className='mb-4'>The company will not be responsible for any damage suffered by users from the use of the platform provided on the services available on the platform or the content on this platform. This includes but is not limited to loss of revenue/data resulting from delays, as may occur because of any act/omission of parties. This disclaimer of liability also applies to any damages or injury caused by any failure of performance, error, omission, interruption, deletion, defects, delays in operation or transmission, computer viruses, communication line failure, theft or destruction or unauthorized access to, alteration of, or use of records, whether for breach of contract, tortuous behaviour, negligence, or under any other cause of action.</p>
                    <p className='mb-4'>The company also makes it clear that it shall not be held liable for any damage/hurt/inconvenience caused to the user through the course of usage of the platform and/or content or as a result of the users’ actions. The company makes no representations or warranties as to the conduct of the users. The platform may contain links to third-party websites, over which we have no control. The inclusion of these links does not imply endorsement, and pnid.Io disclaims all liability for the content and availability of third-party sites.</p>
                    <p className='mb-4'>The content on the platform are intended to be subject to availability, without any promises or guarantees on the same by the company or by the platform, and while certain information available on the platform  is the property of the company and the company endeavors to keep the said information updated and accurate, the company shall not make any representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the platform  or the information, or related graphics contained on the platform  for any purpose. Any reliance you place on such information is therefore strictly at your own risk.</p>
                    <p className='mb-4'>The company bears no responsibility whatsoever for any consequences arising from the use of the platform by users. The use of the platform is the sole responsibility of the user (or legally authorized person on behalf of the user), and in case of any negligence on the part of the user in acting on the same shall not be construed as imposing any liability, direct or indirect, on the company/ platform.</p>
                    <p className='mb-4'>The company shall disclaim all responsibility and owns no liability to users for any outcome (incidental, direct, indirect or otherwise) from the use of the platform. In no event will the company be liable for any loss or damage, including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of the platform.</p>
                    <p className='mb-4'>Through this platform, you are able to link to other platforms which are not under the control of the company. We have no control over the nature, content, and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them. Every effort is made to keep the platform up and running smoothly. However, the company takes no responsibility for, and will not be liable for, the platform being temporarily unavailable due to technical issues beyond our control.</p>
                    <p className='mb-4'>To the maximum extent permitted by applicable law, the company will have no liability related to user content arising under intellectual property rights, libel, privacy, publicity, obscenity or other laws. The platform also disclaims all liability with respect to the misuse, loss, modification, or unavailability of any user content.</p>
                    <p className='mb-4'>The user understands and agrees that any material or data downloaded or otherwise obtained through the platform are done entirely at his/her own discretion and risk, and he/she will be solely responsible for any damage to his/her computer systems or loss of data that results from the download of such material or data.</p>
                    <p className='mb-4'>By using this platform, users consent to the terms of this disclaimer policy. This is a indian based company and we offer service people in india.</p>
                
                    <div className='w-max mx-auto text-center'>
                        <p className="mb-4">[Gullysports & Digital Media Private Limited]</p>
                        <p className="mb-4">[HYDERABAD]</p>
                        <p className="mb-4">[https://cricketgully.com/]</p>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default DisclimerPolicy