import React, { useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Articles from "./components/common/Articles";
import ExclusivelyVideos from "./components/pages/Home/common/ExclusivelyVideos";
import { LiveMatchesCarousel } from "./components/pages/Home/common/liveMatchesCarousel/component";
import AdSquare from "./components/common/AdSquare";
import { AD_CAMPAIGN } from "./helpers/constants";

const LayoutV1 = () => {
  const location = useLocation();
  const pathname = location.pathname.toLowerCase();
  const [adCampaign, setAdCampaign] = useState("");

  useEffect(() => {
    if (pathname.startsWith("/schedule")) {
      setAdCampaign(AD_CAMPAIGN.SCHEDULE_SQUARE);
    } else if (pathname.startsWith("/cricket-news")
      || pathname.startsWith("/cricket-top-story")
      || pathname.startsWith("/fantasy-cricket-tips")) {
      if (pathname === "/cricket-news/" || pathname === "/cricket-news") {
        setAdCampaign(AD_CAMPAIGN.NEWS_SQUARE);
      } else {
        setAdCampaign(AD_CAMPAIGN.NEWS_DETAILS_SQUARE);
      }
    } else if (pathname.startsWith("/fantasy")) {
      setAdCampaign(AD_CAMPAIGN.FANTASY_SQUARE);
    } else if (pathname.startsWith("/series")) {
      if (pathname.includes("series/")) {
        if (pathname.includes("matches/")) {
          setAdCampaign(AD_CAMPAIGN.MATCH_DETAILS_SQUARE);
        } else {
          setAdCampaign(AD_CAMPAIGN.SERIES_DETAILS_SQUARE);
        }
      } else {
        setAdCampaign(AD_CAMPAIGN.SERIES_SQUARE);
      }
    } else if (pathname.startsWith("/ranking")) {
      setAdCampaign(AD_CAMPAIGN.RANKING_SQUARE)
    } else if (pathname.startsWith("/points-table")) {
      setAdCampaign(AD_CAMPAIGN.POINTS_TABLE)
    } else if (pathname.includes("/teams")) {
      setAdCampaign(AD_CAMPAIGN.TEAMS_SQUARE)
    } else if (pathname.startsWith("/category")) {
      setAdCampaign(AD_CAMPAIGN.CATEGORY_SQUARE)
    } else if (pathname.startsWith("/tag")) {
      setAdCampaign(AD_CAMPAIGN.TAG_SQUARE)
    }
  }, [pathname]);

  const isShowLiveMatchCarousel = pathname.includes("schedule/") || (pathname.includes("series/") && pathname.includes("matches/"));

  return (
    <div className="md:mb-28 mb-4">
      <div className="bg-[#2F27BE]">
        <div className="max-w-[1320px] xl:mx-auto">
          {isShowLiveMatchCarousel &&
            <div className="md:block hidden">
              <LiveMatchesCarousel />
            </div>
          }
        </div>
      </div>
      <div className="max-w-[1320px] md:mx-4 xl:mx-auto lg:grid lg:grid-cols-4 lg:gap-0 gap-6 md:mt-5">
        <main className="col-span-3 lg:mr-6 mr-0 outlet-container min-h-screen">
          <Outlet />
        </main>
        <aside className="col-span-1 hidden md:block">
          <div className="bg-white rounded-xl border py-4 px-3 mt-4 md:mx-0 mx-4 md:min-h-[250px]">
            <AdSquare campaign={adCampaign} />
          </div>
          <div className="bg-white rounded-xl border p-4 md:my-4 md:mx-0 m-4 md:min-h-[425px]">
            <Articles />
          </div>
          <div className="bg-white rounded-xl border md:mx-0 mx-4 md:min-h-[990px]">
            <ExclusivelyVideos />
          </div>
        </aside>
      </div>
    </div>
  );
};

export default LayoutV1;
