import React from "react";
import { MatchesCarousel } from "./MatchCarousel";
import SeriesStatsCommon from "../../Home/common/SeriesStats";
import { logoData } from "./constants";
import Teamform from "./MatchInfo";
import Teams from "./Teams";
import Pitch from "./Pitchreport";
import Weather from "./Weather";

const NewsMatchInfo = ({ newsObj }) => {
  const {
    matchInfo,
    teams,
    injuryNews,
    teamForm,
    pitch,
    c_vc,
    weather,
    avoidablePlayers,
    updatedAt
  } = newsObj.match

  return (
    <div className="">
      {/* Match Info */}
      <div className="bg-white">
      <Teamform data={matchInfo} />
      </div>

      {/* pitch report */}
      <Pitch data={pitch} />
      
      {/* Weather Report */}
      <Weather data={weather} />

      {/* team form */}
      <div id="team-form" className="my-4 md:my-7 md:rounded-[14px] rounded-t-2xl border-[#DAE6F8] border">
        <div className="md:bg-[#E8F1FF] bg-[#FFFFFF] rounded-t-[14px] md:text-2xl text-base leading-6 p-4 font-bold">
          <div className="md:flex items-center gap-2 md:justify-start justify-center">
            <img src={logoData.teamForm.logo} alt={logoData.teamForm.heading} className="w-5 h-6 md:block hidden" />
            <p className="text-center">{logoData.teamForm.heading}</p>
            <p className="text-[10px] text-[#4F4F4F] font-normal md:text-start text-center">(Recent 5 Matches, Latest First)</p>
          </div>
        </div>
        <MatchesCarousel data={teamForm} />
      </div>

      {/* Captain & Vice Captain */}
      {
        (c_vc?.c || c_vc?.vc) &&
        <div id="captain-vice-captain" className="my-4 md:my-7 md:rounded-[14px] border-[#DAE6F8] border bg-white rounded-b-2xl">
          <div className="bg-[#E8F1FF] md:rounded-t-[14px] md:text-2xl text-base leading-6 p-4 font-bold">
            <div className="flex items-center gap-2 md:justify-start justify-center">
              <img src={logoData.captainViceCaptain.logo} alt={logoData.captainViceCaptain.heading} className="md:w-5 md:h-6 w-4 h-4" />
              {logoData.captainViceCaptain.heading}
            </div>
            <p className="text-[10px] text-[#4F4F4F] font-normal md:text-start text-center md:block hidden">Predicted Playing XI  •  Updated 07-Oct-2023  •  11:36AM</p>
          </div>
          <div className="md:px-8 p-4 md:py-6 border-t border-[#DAE6F8]">
            <div className="">
              <p className="md:text-2xl text-xs italic text-justify font-semibold">Captain</p>
              <p className="md:pt-6 pt-2 md:text-base text-xs text-justify">{c_vc?.c}</p>
            </div>
          </div>
          <div className="p-4 md:px-8 md:py-6 border-t border-[#DAE6F8]">
            <div className="">
              <p className="md:text-2xl text-xs  italic text-justify font-semibold">Vice Captain</p>
              <p className="md:pt-6 pt-2 md:text-base text-xs text-justify">{c_vc?.vc}</p>
            </div>
          </div>
        </div>
      }

      {/* Avoidable Players */}
      {
        avoidablePlayers &&
        <div id="avoidable-players" className="mt-4 md:mt-7 md:rounded-[14px] border-[#DAE6F8] border bg-white rounded-b-2xl">
          <div className="bg-[#E8F1FF] md:rounded-t-[14px] md:text-2xl text-base leading-6 p-4 font-bold">
            <div className="flex items-center gap-2 md:justify-start justify-center">
              <img src={logoData.avoidablePlayers.logo} alt={logoData.avoidablePlayers.heading} className="w-5 h-6" />
              {logoData.avoidablePlayers.heading}
            </div>
          </div>
          <p className="md:p-6 p-4 md:text-base text-xs text-justify">
            {avoidablePlayers}
          </p>
        </div>
      }

      {/* Teams */}
      <Teams data={teams} updatedAt={updatedAt}/>

      {/* Injury */}
      {
        injuryNews &&
        <div id="injury" className="my-4 md:my-7 md:rounded-[14px] border-[#DAE6F8] border bg-white">
          <div className="bg-[#E8F1FF] md:rounded-t-[14px] md:text-2xl text-base leading-6 p-4 font-bold border-[#DAE6F8] border-b">
            <div className="flex items-center gap-2 md:justify-start justify-center">
              <img src={logoData.injury.logo} alt={logoData.injury.heading} className="md:w-5 md:h-6 w-4 h-4" />
              {logoData.injury.heading}
            </div>
          </div>
          <p className="md:p-6 p-4 md:text-base text-xs text-justify">
            {injuryNews}
          </p>
        </div>
      }

      {/* Players Stats in current series */}
      <div id="player-stats" className="md:mt-7 mt-4 md:mb-7 md:rounded-[14px] border-[#DAE6F8] border bg-white rounded-2xl">
        <div className="bg-[#E8F1FF] md:rounded-t-[14px] md:text-2xl text-base leading-6 p-4 font-bold md:block hidden">
          {logoData.playerStats.heading}
        </div>
        <div className="md:rounded-t-[14px] text-base leading-6 py-2 font-bold md:hidden">
          <div className="text-center">
            Players Stats
          </div>
          <p className="text-[10px] text-[#4F4F4F] font-normal text-center">in current series</p>
        </div>
        <div className=''>
          <SeriesStatsCommon seriesId={newsObj?.cid} type="fantasy" />
        </div>
      </div>
    </div>
  );
};

export default NewsMatchInfo;
