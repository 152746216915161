import { useEffect, useState } from "react";

export const useTikTik = (data, indexUpdater, interval = 6000) => {
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);

  useEffect(() => {
    let timer;

    // Handle auto-slide with interval
    if (data && data.length > 1) {
      timer = setInterval(() => {
        indexUpdater(prev => {
          const nextIndex = prev + 1;
          return nextIndex < data.length ? nextIndex : 0;
        });
      }, interval);
    }

    return () => {
      clearInterval(timer);
    };
  }, [data, indexUpdater, interval, touchEnd]);

  // Touch event handlers
  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX); // Record starting touch position
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX); // Record touch movement
  };

  const handleTouchEnd = (handleNext, handlePrevious) => {
    const swipeDistance = touchStart - touchEnd;

    if (swipeDistance > 50) {
      handleNext(); // Swipe left
    }

    if (swipeDistance < -50) {
      handlePrevious(); // Swipe right
    }
  };

  return { handleTouchStart, handleTouchMove, handleTouchEnd };
};
