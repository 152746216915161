import React from "react";
import logo from "../../Assets/logo.png";
import footerImage from "../../Assets/footerImg.png";
import { Link } from "react-router-dom";
import { doScrollToTop } from "../../helpers/general";
import instagram from '../../Assets/instagram.svg';
import facebook from '../../Assets/facebook.svg';
import youtube from '../../Assets/youtube.svg';
import twitter from '../../Assets/x(twitter).svg';

const Footer = () => {
  return (
    <div
      className="bg-gradient"
      style={{
        backgroundImage: `url(${footerImage}), linear-gradient(rgb(0, 0, 0), rgb(2, 0, 41), rgb(23 19 96))`,
      }}
    >
      <div className="mx-auto pt-8 pb-8 px-4 lg:max-w-6xl lg:px-8">
        <div className="mt-10 mb-4 gap-y-10 flex flex-wrap justify-between xl:gap-x-8 ">
          <div className="md:mx-0 mx-28">
            <img src={logo} alt="logo" className="w-[162px] mx-auto sm:mx-0" />
          </div>
          <div className="md:ml-0 ml-6">
            <p className="text-white lg:text-xl text:lg font-semibold mb-9">
              QUICK LINKS
            </p>
            <ul className="list-none">
              <li className="mb-4">
                <Link
                  className="text-[#6C6C6C] text-[14px] font-normal mb-6 space-links"
                  to="/"
                  onClick={doScrollToTop}
                >
                  Home
                </Link>
              </li>
              <li className="mb-4">
                <Link
                  className="text-[#6C6C6C] text-[14px] font-normal mb-6 space-links"
                  to="/schedule/live"
                  onClick={doScrollToTop}
                >
                  Schedule
                </Link>
              </li>
              <li className="mb-4">
                <Link
                  className="text-[#6C6C6C] text-[14px] font-normal mb-6 space-links"
                  to="/cricket-news"
                  onClick={doScrollToTop}
                >
                  News
                </Link>
              </li>
              <li className="mb-4">
                <Link
                  className="text-[#6C6C6C] text-[14px] font-normal mb-6 space-links"
                  to="/fantasy"
                  onClick={doScrollToTop}
                >
                  Fantasy
                </Link>
              </li>
              <li className="mb-4">
                <Link
                  className="text-[#6C6C6C] text-[14px] font-normal mb-6 space-links"
                  to="/series"
                  onClick={doScrollToTop}
                >
                  Series
                </Link>
              </li>
            </ul>
          </div>
          <div className="md:mr-0 mr-6">
            <p className="text-white lg:text-xl text:lg font-semibold mb-9">
              COMPANY
            </p>
            <ul className="list-none">
              {/* <li className="mb-4 ">
                <a
                  className="  text-[#6C6C6C] text-[14px] font-normal mb-6 space-links"
                  href="/"
                >
                  Advertise
                </a>
              </li> */}
              <li className="mb-4 ">
                <a
                  className="  text-[#6C6C6C] text-[14px] font-normal mb-6 space-links"
                  href="/privacy"
                >
                  Privacy Policy
                </a>
              </li>
              <li className="mb-4 ">
                <a
                  className="  text-[#6C6C6C] text-[14px] font-normal mb-6 space-links"
                  href="/correction-policy"
                >
                  Correction Policy
                </a>
              </li>
              <li className="mb-4 ">
                <a
                  className="  text-[#6C6C6C] text-[14px] font-normal mb-6 space-links"
                  href="/fact-check-policy"
                >
                  Fact Check Policy
                </a>
              </li>
              <li className="mb-4 ">
                <a
                  className="  text-[#6C6C6C] text-[14px] font-normal mb-6 space-links"
                  href="/terms-of-use"
                >
                  Terms of Use
                </a>
              </li>
              <li className="mb-4 ">
                <a
                  className="  text-[#6C6C6C] text-[14px] font-normal mb-6 space-links"
                  href="/cookie-policy"
                >
                  Cookie Policy
                </a>
              </li>
              <li className="mb-4 ">
                <a
                  className="  text-[#6C6C6C] text-[14px] font-normal mb-6 space-links"
                  href="/dmca-policy"
                >
                  DMCA Policy
                </a>
              </li>
              <li className="mb-4 ">
                <a
                  className="  text-[#6C6C6C] text-[14px] font-normal mb-6 space-links"
                  href="/disclaimer-policy"
                >
                  Disclaimer Policy
                </a>
              </li>
            </ul>
          </div>
          {/* <div className="md:ml-0 ml-6">
            <p className="text-white lg:text-xl text:lg font-semibold mb-9">
              MOBILE SITE & APPS
            </p>
            <ul className="list-none">
              <li className="mb-4 ">
                <a
                  className="  text-[#6C6C6C] text-[14px] font-normal mb-6 space-links"
                  href="/"
                >
                  m.cricketgully.com
                </a>
              </li>
              <li className="mb-4 ">
                <a
                  className="  text-[#6C6C6C] text-[14px] font-normal mb-6 space-links"
                  href="/"
                >
                  Android
                </a>
              </li>
              <li className="mb-4 ">
                <a
                  className="  text-[#6C6C6C] text-[14px] font-normal mb-6 space-links"
                  href="/terms-of-use"
                >
                  iOS
                </a>
              </li>
            </ul>
          </div> */}
          <div className="md:mr-0 mr-6 hidden md:block">
            <p className="text-white lg:text-xl text:lg font-semibold mb-9">
              FOLLOW US ON
            </p>
            <ul className="list-none">
              <li className="mb-4 ">
                <a
                  href="https://www.instagram.com/cricketgullyofficial/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex"
                >
                  <img src={instagram} alt="Instagram"/>
                  <span className="text-[#6C6C6C] text-[14px] font-normal ml-2">
                    Instagram
                  </span>
                </a>
              </li>
              <li className="mb-4 ">
                <a
                  href="https://www.facebook.com/cricketgully.official/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex"
                >
                  <img src={facebook} alt="Facebook"/>
                  <span className="text-[#6C6C6C] text-[14px] font-normal ml-2">
                    Facebook
                  </span>
                </a>
              </li>
              <li className="mb-4 ">
                <a
                  href="https://www.youtube.com/channel/UCTKws3gbVaxeLJv4yXDorVQ"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex"
                >
                  <img src={youtube} alt="Youtube"/>
                  <span className="text-[#6C6C6C] text-[14px] font-normal ml-2">
                    Youtube
                  </span>
                </a>
              </li>
              <li className="mb-4 ">
                <a
                  href="https://x.com/thecricketgully"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex"
                >
                  <img src={twitter} alt="Twitter"/>
                  <span className="text-[#6C6C6C] text-[14px] font-normal ml-2">
                    X (Twitter)
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="pb-6 px-4">
        <p className="text-center text-white text-[14px]">
          © 2024 Cricket Gully
        </p>
      </div>
    </div>
  );
};

export default Footer;
