import React from 'react'
import SEOMeta from './Home/common/SEOMeta';

const TermsOfUse = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <SEOMeta slug="terms-of-use" />
      <h1 className="font-bold mb-8">Terms of Use</h1>
      <div className="p-2 md:p-10 bg-white rounded-xl text-justify">
        <p className="mb-4">Posted as of
          <span className="bg-yellow-200 bg-opacity-50"> "April 24, 2024"</span>
        </p>
        <p className="mb-4">Last updated as of
          <span className="bg-yellow-200 bg-opacity-50"> "April 24, 2024"</span>
        </p>
        <h5>Welcome to [CricketGully & SportsGully] Terms of Use</h5>
        <p className="pl-[32px] my-2">We [Gullysports & Digital Media Private Limited]. (hereinafter referred to as “Company”) and is operating its business under the brand name “[CricketGully & SportsGully]” (hereinafter referred to as “Brand Name”). This Terms of Use (hereinafter referred to as “Terms of Use”) is our commitment towards your trust and we intend to provide you a convenient services. This Terms of Use lays down all the terms and conditions surrounding the usage of our Website for you and the Company. </p>
        <p className="pl-[32px] my-2">Platform herein and in all associated policies refer to [https://cricketgully.com/]. </p>
        <p className="pl-[32px] my-2">Upon usage, registration, or even browsing through our Website creates a contractual relationship between you and us through this Terms of Use and other relevant policies associated with our Website, for all contractual, legal, and compliance purposes. This Terms of Use is considered as a legally binding agreement and is also considered as an electronic record in terms of the Information Technology Act, 2000 and rules thereunder as applicable and the amended provisions about electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.</p>
        <ol className="list-level-1">
          <li className="mb-4">
            <span className='font-bold'>
              Definition and Interpretation
            </span>
            <ol className="list-level-2 mt-4">
              <li className="mb-4">
                <span className='font-bold'>
                  Definition
                </span>
                <ol className="list-level-3 mt-4">
                  <li className="mb-4">
                    Applicable Laws - shall mean and refer to all the laws, rules, ordinances, by-laws, etc., and amendments thereafter, applicable within the territory of India.
                  </li>
                  <li className="mb-4">
                    Content – shall mean and refer to all the text, advertisement, graphics, User interfaces, visual interfaces, photographs, trademarks, logos, brand names, descriptions, sounds, music, and artwork.
                  </li>
                  <li className="mb-4">
                    Third Parties – shall mean and refer to all the legal entities either individual or entity linked on the Website apart from the Users and the creator of this Website.
                  </li>
                  <li className="mb-4">
                    Service – shall mean and refer to all the intermediary services provided by the Website to the User.
                  </li>
                </ol>
              </li>
              <li className="mb-4">
                <span className='font-bold pb-4'>
                  Interpretation
                </span>
                <ol className='list-level-3 mt-4'>
                  <li className="mb-4">
                    Company – For the purposes of this Terms of Use, wherever the context so requires, “We”, “Our”, and “Us” shall mean and refer to the Company and its owned Website.
                  </li>
                  <li className="mb-4">Platform – For the purposes of this Terms of Use, the Website shall be referred to as the Platform, unless specified otherwise in this Terms of Use.</li>
                  <li className="mb-4">User - For the purposes of this Terms of Use, wherever the context so requires, “You”, “Your”, “Yourself”, “User”, and  “Customer” shall mean and refer to natural and legal individuals who shall be users of this Website provided by Us and who is competent to enter into binding contracts, as per law.</li>
                  <li className="mb-4">The headings of each section in these Terms of Use are only for the purpose of organizing the various provisions under these Terms of Use in an orderly manner and shall not be used by you to interpret the provisions contained herein in a manner as may apply to you. Further, it is specifically agreed by you that the headings shall not have legal or contractual value on your usage of the Website.</li>
                  <li className="mb-4">The Parties shall refer and mean the company and User together, for the purpose of interpretation of terms of this Terms of Use. </li>
                  <li className="mb-4">The use of this Website is solely governed by these Terms of Use, Privacy Policy, and any other relevant policies as updated on the Website and any modifications or amendments made thereto by us from time to time, at our sole discretion. If you as a User continue to access and use this Website, you are agreeing to comply with and be bound by the following Terms of Use, Privacy Policy, and any other applicable policies, thereof.
                  </li>
                  <li className="mb-4">You expressly agree and acknowledge that these Terms of Use and Privacy Policy, and any other applicable policies, are co-terminus in nature and that expiry/termination of either one will lead to the termination of the other.</li>
                  <li className="mb-4">You unequivocally agree that these Terms of Use, Privacy Policy, and any other applicable policies, constitute a legally binding agreement between us and that you shall be subject to the rules, guidelines, policies, terms, and conditions applicable to your use of the Platform, and that the same shall be deemed to be incorporated into these Terms of Use and shall be treated as part and parcel of the same. You acknowledge and agree that no signature or express act is required to make these Terms of Use and the Privacy Policy binding on you and that your act of visiting/browsing any part of the Website constitutes your full and final acceptance of these Terms of Use, Privacy Policy, and any other applicable policies.
                  </li>
                  <li className="mb-4">We reserve the sole and exclusive right to amend or modify these Terms of Use without any prior permission or intimation to you, and you expressly agree that any such amendments or modifications shall come into effect immediately. If you do not adhere to the changes, you must stop using the Website at once. Your continued use of the Website will signify your acceptance of the changed Terms of Use.</li>
                </ol>
              </li>
            </ol>
          </li>
          <li className="mb-4">
            <span className='font-bold pb-4'>
              Platform Overview
            </span>
            <p className="pl-[32px] my-2">Welcome to [CricketGully & SportsGully]! </p>
            <p className="pl-[32px] my-2">We provide live cricket match updates and analysis of all cricket matches. We provide live cricket score, commentary, live score board & latest cricket news. Check cricket live line score, stay updated with the latest cricket news, and update to know exciting facts about cricket.</p>
          </li>
          <li className="mb-4">
            <span className='font-bold'>
              Registration and Operations
            </span>
            <ol className='list-level-2 mt-4'>
              <li className="mb-4">To fully avail the Services of the Website, registration is required. You are required to create a profile for Yourself by providing the following information which inter alia includes [Full Name, Email Id, Phone No, Date of Birth, State]. Users who register with us can avail the Services of the Website. The Website offers the Users an option of signing up through other third-party social networking sites, including but not limited to Gmail, etc. Upon such signup, our servers gain access to information about You from Your social networking account, including but not limited to Your profile, date of birth, name and gender, and all such information is stored in Our system. Users who do not want to register with Us can avail the service as a guest user.</li>
              <li className="mb-4">Information collected about you is subject to our Privacy Policy, which is incorporated in these Terms of Use by reference.  Further, at any time during Your use of this Website, including but not limited to the time of registration, You are solely responsible for protecting the confidentiality of Your username and password, and any activity under the account shall be deemed to have been done by You. In the case that You provide Us with false and/or inaccurate details or We have reason to believe You have done so, We hold the right to permanently suspend Your account </li>
            </ol>
          </li>
          <li className="mb-4">
            <span className='font-bold'>
              Eligibility
            </span>
            <ol className='list-level-2 mt-4'>
              <li className="mb-4">You represent and warrant that you are competent and eligible to enter into legally binding agreements and of competent age and that you have the requisite authority to bind yourself/themselves to these Terms of Use as per the Applicable Law. However, if you are a minor using this Website, you may do so with the consent of your legal guardian. All Acts of the minor shall be deemed to be considered as the acts undertaken under the supervision of their legal guardian.</li>
              <li className="mb-4">You further represent that you will comply with these Terms of Use and all applicable local, state, national, and international laws, rules, and regulations.</li>
              <li className="mb-4">You shall not use the Website if you are not competent to enter into a contract or are disqualified from doing so by any other Applicable Laws, rule, or regulation, currently in force.</li>
            </ol>
          </li>
          <li className="mb-4">
            <span className='font-bold pb-4'>
              Content
            </span>
            <ol className='list-level-2 mt-4'>
              <li className="mb-4">General: All the Content is generated/provided or based on information provided by the Users or Third Parties and we have no control and make no guarantees regarding the quality, accuracy, integrity, and/or genuineness of such Content or such other information provided on the Platform.</li>
              <li className="mb-4">All the Content displayed on the Platform is subject to copyright and shall not be reused by You (or a Third-Party) without prior written consent from the Company and the copyright owner.
              </li>
              <li className="mb-4">You are solely responsible for the integrity, authenticity, quality, and/or genuineness of the Content provided by you on the Platform and whilst feedback and comments by You can be made via the Website, we bear no liability whatsoever for any feedback or comments made by the other Users or made in respect of any of the Content on the Platform. Further, the Company reserves its right to suspend the account of any User for an indefinite period to be decided at the discretion of the Company or to terminate the account of any User who is found to have created or shared or submitted any Content or part thereof that is found to be untrue/ inaccurate/ misleading/ offensive/ vulgar. You shall be solely responsible for making good any financial or legal losses incurred through the creation/ sharing/ submission of Content or part thereof that is deemed to be untrue/ inaccurate/ misleading/ offensive/ vulgar.</li>
              <li className="mb-4">You have a personal, non-exclusive, non-transferable, revocable, limited privilege to access the Content on the Platform. You shall not copy, adapt, and modify any Content without written permission from the Company.</li>
            </ol>
          </li>
          <li className="mb-4">
            <span className='font-bold pb-4'>
              Third Party Advertisements
            </span>
            <p className="pl-[32px] my-2">You and all our visitors should be aware that we receive affiliate and advertising revenue from the companies that appear on our Platform and such remuneration may impact the location and order in which the companies' banners and offerings are promoted on our websites.
              Whilst we do our utmost to try to ensure all companies advertised are trustworthy and hold the requisite licences for their offerings, the rankings or prominence of any banners/advertisements do not necessarily imply endorsement of the companies or their products by us. Except as expressly stated in these terms, all representations and warranties regarding the information presented on the Platform are disclaimed. Further, the information which appears on the Platform is subject to change at any time.
            </p>
          </li>
          <li className="mb-4">
            <span className='font-bold pb-4'>
              Indemnity
            </span>
            <p className="pl-[32px] my-2">You agree to indemnify, defend and hold harmless the Company, and its respective directors, officers, employees, and agents (hereinafter collectively referred to as "Parties"), from and against any losses, liabilities, claims, damages, demands, costs, and expenses (including legal fees and disbursements in connection therewith and interest chargeable thereon) asserted against or incurred by us that arise out of, result from, or maybe payable by, any breach or non-performance of any representation, warranty, covenant, or agreement made or obligation to be performed according to these Terms of Use. Further, you agree to hold the Company harmless against any claims made by any Third Party due to/ or arising out of, or in connection with:</p>
            <ol className='mx-16 mb-4' style={{ listStyleType: "lower-alpha" }}>
              <li className="mb-4">
                Your use of the Website;
              </li>
              <li className="mb-4">
                Your violation of these Terms of Use;
              </li>
              <li className="mb-4">
                Your violation of any rights of another Parties, Users, and/or Third-Party;
              </li>
              <li className="mb-4">
                Your alleged improper conduct according to these Terms of Use;</li>
              <li className="mb-4">
                Your conduct in connection with the Website.
              </li>
            </ol>
            <p className="pl-[32px] my-2">You agree to fully cooperate in indemnifying the Company at your own expense. You also agree not to settle with any party without the consent of the Company.</p>
            <p className="pl-[32px] my-2">In no event shall we be liable to compensate you or any Third Party for any special, incidental, indirect, consequential, or punitive damages whatsoever, including those resulting from loss of use, data, or profits, whether or not foreseeable, and whether or not you had been advised of the possibility of such damages, or based on any theory of liability, including breach of contract or warranty, negligence, or other tortuous action, or any other claim arising out of or in connection with your use of, or access, or availing Services from or materials contained therein on the Website.</p>
          </li>
          <li className="mb-4">
            <span className='font-bold pb-4'>
              Limitation of Liability
            </span>
            <ol className='list-level-2 mt-4'>
              <li className="mb-4">
                We are not responsible for any consequences arising out of the following events:
                <ol className='list-level-3 mt-4'>
                  <li className="mb-4">if the Website is inoperative/non-responsive due to any connectivity errors associated with the internet connection such as but not limited to slow connectivity, no connectivity, server failure;
                  </li>
                  <li className="mb-4">if you have fed incorrect information or data or for any deletion of data;
                  </li>
                  <li className="mb-4">if there is an undue delay or inability to communicate through email;
                  </li>
                  <li className="mb-4">if there is any deficiency or defect in the Services managed by the Company;
                  </li>
                  <li className="mb-4">if there is a failure in the functioning of any other Service provided by the Company.
                  </li>
                </ol>
              </li>
              <li className="mb-4">
                The Website accepts no liability for any errors or omissions, on behalf of itself, or for any damage caused to you, your belongings, or any Third-Party, resulting from the use or misuse of the Platform or any Service provided of by the Company through the Platform. The Service and any Content or material displayed of the Services are provided without any guarantees, conditions, or warranties as to its accuracy, suitability, completeness, or reliability. We will not be liable to you for the unavailability or failure of the Platform.
              </li>
              <li className="mb-4">
                You are to comply with all Applicable Laws upon you or on your activities, and with all applicable policies, which are hereby incorporated into this Terms of Use by reference.
              </li>
              <li className="mb-4">
                The Platform expressly excludes any liability for any loss or damage that was not reasonably foreseeable by the Website and which is incurred by you in connection with the Website, including loss of profits; and any loss or damage incurred by you as a result of your breach of these Terms of Use.
              </li>
              <li className="mb-4">
                To the fullest extent permitted by law, We shall not be liable to You or any other party for any loss or damage, regardless of the form of action or basis of any claim. You acknowledge and agree that your sole and exclusive remedy for any dispute with us is to terminate your use of the Website.
              </li>
            </ol>
          </li>
          <li className="mb-4">
            <span className='font-bold pb-4'>
              Term
            </span>
            <ol className='list-level-2 mt-4'>
              <li className="mb-4">These Terms of Use shall continue to form a valid and binding agreement between us and shall continue to be in full force and effect until you continue to access and use the Website.</li>
              <li className="mb-4">You may terminate your use of the Website at any time, as per the process specified under this Terms of Use. </li>
              <li className="mb-4">We may terminate these Terms of Use with you and close your account at any time without notice and/or suspend or terminate Your access to the Website at any time and for any reason if any discrepancy or legal issue arises.</li>
              <li className="mb-4">Such suspension or termination shall not limit our right to take any other action against you that we consider appropriate.</li>
              <li className="mb-4">It is also hereby declared that we may discontinue the Services on the Website without any prior notice.</li>
            </ol>
          </li>
          <li className="mb-4">
            <span className='font-bold pb-4'>
              Termination
            </span>
            <ol className='list-level-2 mt-4'>
              <li className="mb-4">We reserve the right, in its sole discretion, to unilaterally terminate Your access to the Website, or any portion thereof, at any time, without notice or cause. </li>
              <li className="mb-4">We also reserve the universal right to deny access to You, to any/all of are on its Website without any prior notice/explanation to protect the interests of the Website and/or other Users to the Website. </li>
              <li className="mb-4">We reserve the right to limit, deny or create different access to the Website and its features concerning different Users, or to change any of the features or introduce new features without prior notice. </li>
              <li className="mb-4">You shall continue to be bound by these Terms of use, and it is expressly agreed to by You that You shall not have the right to terminate these Terms of Use till the expiry of the same.</li>
            </ol>
          </li>
          <li className="mb-4">
            <span className='font-bold pb-4'>
              Communication
            </span>
            <p className="pl-[32px] my-2">By using this Website and providing your identity and contact information to the Company through the Website, you agree and consent to receive e-mails or SMS from us and/or any of its representatives at any time.</p>
            <p className="pl-[32px] my-2">You can report to “contact@cricketgully.com” if you find any discrepancy with regard to Website or Content-related information and we will take necessary action after an investigation.  The response with resolution (if any issues found) shall be dependent on the time is taken for investigation.</p>
            <p className="pl-[32px] my-2">You expressly agree that notwithstanding anything contained hereinabove, it may be contacted by us relating to any Services availed by you on the Website or anything pursuant thereto and you agree to indemnify us from any harassment claims. It is expressly agreed to by us that any information shared by us shall be governed by the Privacy Policy.</p>
          </li>
          <li className="mb-4">
            <span className='font-bold pb-4'>
              User obligations and formal undertakings as to conduct
            </span>
            <p className="pl-[32px] my-2">
              You agree and acknowledges that you are a restricted user of this Platform and you:
            </p>
            <ol className='list-level-2'>
              <li className="mb-4">agree to provide genuine credentials during the process whenever required on the Website. You shall not use a fictitious identity. We are not liable if you have provided incorrect information;
              </li>
              <li className="mb-4">agree to ensure the Name, Email address, Phone Number, and any such other information that may be provided and is valid at all times and shall keep your information accurate and up-to-date;</li>
              <li className="mb-4">You agree that you are solely responsible for maintaining the confidentiality of your account. You agree to notify us immediately of any unauthorized use of your account. We reserve the right to close your account at any time for any or no reason;</li>
              <li className="mb-4">understand and acknowledge that the data submitted is manually entered into the database of the Platform. You also acknowledge the fact that data so entered into the database is for easy and ready reference for you, and to streamline the Services through the Website;
              </li>
              <li className="mb-4">authorize the Platform to use, store, or otherwise process certain personal information and all published Content, responses, locations, User comments, reviews, and ratings for personalization of Services, marketing, and promotional purposes, and for optimization of User-related options and Services;</li>
              <li className="mb-4">understand and agree that, to the fullest extent permissible by law, the Platform or any of their affiliates or their respective officers, directors, employees, agents, licensors, representatives, operational service providers, advertisers or suppliers shall not be liable for any loss or damage, of any kind, direct or indirect, in connection with or arising from the use of the Platform or this Terms of Use, including, but not limited to, compensatory, consequential, incidental, indirect, special or punitive damages;</li>
              <li className="mb-4">are bound not to cut, copy, modify, recreate, reverse engineer, distribute, disseminate, post, publish or create derivative works from, transfer, or sell any information or obtained from the Platform. Any such use/limited use of the Platform will only be allowed with the prior express written permission;</li>
              <li className="mb-4">agree not to access (or attempt to access) the Platform and/or the materials or Services by any means other than through the interface provided by the Platform. The use of deep-link, robot, spider or other automatic devices, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Platform or its Content, or in any way reproduce or circumvent the navigational structure or presentation of the Platform, materials or any Content, or to obtain or attempt to obtain any materials, documents or information through any means not specifically made available through the Platform will lead to suspension or termination of your access to the Platform. We disclaim any liabilities arising concerning such offensive Content on the Platform; </li>
              <li className="mb-4">expressly agree and acknowledge that the Content generated by the Users and displayed on the Platform is not owned by the Company and that we are in no way responsible for the Content of the same. You may, however, report any offensive or objectionable content, which we may then remove from the Platform, at our sole discretion.</li>
              <p className="-ml-8 my-2">You further undertake not to: </p>
              <li className="mb-4">engage in any activity that interferes with or disrupts access to the Platform or the Services provided therein (or the servers and networks which are connected to the Platform);</li>
              <li className="mb-4">impersonate any person or entity, or falsely state or otherwise misrepresent his/her affiliation with a person or entity;</li>
              <li className="mb-4">probe, scan, or test the vulnerability of the Platform or any network connected to the Platform, nor breach the security or authentication measures on the Platform or any network connected to the Platform. The User may not reverse look-up, trace, or seek to trace any information relating to any other User of, or visitor to the Platform, or any other viewer of the Platform, including any User account maintained on the Platform not operated/managed by the User, or exploit the Platform or information made available or offered by or through the Platform, in any manner;</li>
              <li className="mb-4">disrupt or interfere with the security of, or otherwise cause harm to the Platform, systems resources, accounts, passwords, servers, or networks connected to or accessible through the Platform or any affiliated or linked Platform;</li>
              <li className="mb-4">use the Platform or any material or Content therein for any purpose that is unlawful or prohibited by these Terms of Use, or to solicit the performance of any illegal activity or other activity which infringes the rights of this Platform or any other Third-Party(ies);</li>
              <li className="mb-4">violate any code of conduct or guideline which may apply for or to any particular Service offered through the Platform;</li>
              <li className="mb-4">violate any applicable laws, rules, or regulations currently in force within or outside India;</li>
              <li className="mb-4">violate any portion of these Terms of Use or the Privacy Policy, including but not limited to any applicable additional terms and conditions of the Platform contained herein or elsewhere, whether made by amendment, modification, or otherwise;</li>
              <li className="mb-4">commit any act that causes the Company to lose (in whole or in part) the Services of its Internet Establishment ("ISP") or in any manner disrupts the Services of any other supplier/service provider of the Platform;</li>
              <li className="mb-4">you hereby expressly authorize the Company to disclose any and all information relating to you in our possession to law enforcement or other government officials, as we may in our sole discretion, believe necessary or appropriate in connection with the investigation and/or resolution of possible crimes, especially those involve personal injury and theft/infringement of intellectual property. You further understand that the Platform might be directed to disclose any information (including the identity of persons providing information or materials on the Platform) as necessary to satisfy any judicial order, Applicable Law, regulation or valid governmental request;</li>
              <li className="mb-4">by indicating your acceptance to use any Services offered through the Platform, you are obligated to complete such transactions after making payment. You shall be prohibited from indicating their acceptance to avail Services where the transactions have remained incomplete;</li>
              <li className="mb-4">you agree to use the Services provided by the Company, our affiliates, consultants and contracted companies, for lawful purposes only;</li>
              <li className="mb-4">you agree to provide authentic and true information. We reserve the right to confirm and validate the information and other details provided by you at any point in time. If upon Confirmation such details are found to be false, not to be true (wholly or partly), we shall in our sole discretion reject the order and debar you from using the Platform without prior intimation whatsoever;</li>
              <li className="mb-4">you agree not to post any material on the Platform that is defamatory, offensive, obscene, indecent, abusive, or needlessly distressful, or advertising any goods or services. More specifically, you agree not to host, display, upload, update, publish, modify, transmit, or in any manner share any information that:
                <ol className='list-level-3 mt-4'>
                  <li className="mb-4">belongs to another person and to which you have no right to post or disseminate;</li>
                  <li className="mb-4">is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic, libelous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever;</li>
                  <li className="mb-4">is in any way harmful to minors below the age of 18 years or as maybe decided by the appropriate government from time to time;</li>
                  <li className="mb-4">infringes any patent, trademark, copyright or other proprietary rights;</li>
                  <li className="mb-4">violates any Applicable Law for the time being in force;</li>
                  <li className="mb-4">deceives or misleads the addressee about the origin of such messages or communicates any information which is grossly offensive or menacing;</li>
                  <li className="mb-4">abuse, harass, threaten, defame, disillusion, erode, abrogate, demean or otherwise violate the legal rights of others;</li>
                  <li className="mb-4">impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or entity;</li>
                  <li className="mb-4">publish, post, disseminate, any grossly harmful information, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic, libellous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever; or unlawfully threatening or unlawfully harassing including but not limited to "indecent representation of women" within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986; </li>
                  <li className="mb-4">threatens the unity, integrity, defense, security, or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation.</li>
                </ol>
              </li>
            </ol>
          </li>
          <li className="mb-4">
            <span className='font-bold pb-4'>
              Suspension of User access and activity
            </span>
            <p className="pl-[32px] my-2">
              Notwithstanding other legal remedies that may be available, we may in our sole discretion, limit your access and/or activity by immediately removing your access credentials either temporarily or indefinitely, or suspend/terminate your association with the Platform, and/or refuse to the usage of the Platform, without being required to provide you with notice or cause:
            </p>
            <ol className='mx-16 mb-4' style={{ listStyleType: "lower-alpha" }}>
              <li className="mb-4">
                if you are in breach of any of these Terms of Use, Privacy Policy, or any other applicable policies;
              </li>
              <li className="mb-4">
                if you have provided wrong, inaccurate, incomplete or incorrect information;</li>
              <li className="mb-4">
                if your actions may cause any harm, damage or loss to the other Users or the Company, at our sole discretion.
              </li>
            </ol>
          </li>
          <li className="mb-4">
            <span className='font-bold pb-4'>
              Intellectual Property Rights
            </span>
            <p className="pl-[32px] my-2">Unless expressly agreed to in writing, nothing contained herein shall give you a right to use any of the trade names, trademarks, service marks, logos, domain names, information, questions, answers, solutions, reports, and other distinctive brand features, save according to the provisions of these Terms of Use that shall be available on the Platform. All logos, trademarks, brand names, service marks, domain names, including material, designs, and graphics created by and developed by either the Platform or such other Third-Party and other distinctive brand features of the Platform are the property of the Platform or the respective copyright or trademark owner. Furthermore, concerning the Platform, we shall be the exclusive owner of all the designs, graphics, and the like, related to the Platform.</p>
            <p className="pl-[32px] my-2">You shall not use any of the Intellectual Property displayed on the Platform in any manner that is likely to cause confusion among existing or prospective users of the Platform, or that in any manner disparages or discredits the Platform, to be determined in the sole discretion.</p>
            <p className="pl-[32px] my-2">You are aware all Intellectual Property, including but not limited to copyrights, relating to said Services resides with the owners, and that at no point does any such Intellectual Property stand transferred from the aforementioned creators. You are aware that we merely provide the Platform through which you can communicate with other Users and the Platform does not own any of the Intellectual Property relating to the independent Content displayed on the Platform, apart from created graphics and specified Content.</p>
            <p className="pl-[32px] my-2">You are further aware that any reproduction or infringement of the Intellectual Property of the aforementioned owners by you will result in legal action being initiated against you by the respective owners of the Intellectual Property so reproduced/infringed upon. It is agreed to by you that the Contents of this section shall survive even after the termination or expiry of these Terms of Use and/or Privacy Policy.</p>
          </li>
          <li className="mb-4">
            <span className='font-bold pb-4'>
              Disclaimer of Warranties and Liabilities
            </span>
            <ol className='list-level-2 mt-4'>
              <li className="mb-4">You further agree and undertake that you are accessing the Platform at your sole risk and that you are using the best and prudent judgment before availing of any features on the Platform or accessing/using any information displayed thereon.</li>
              <li className="mb-4">You agree that any kind of information, resources, activities, or recommendations obtained/availed from the Platform, written or oral, will not create any warranty and we disclaim all liabilities resulting from these.</li>
              <li className="mb-4">We do not guarantee that the features and content contained in the Platform will be uninterrupted or error-free, or that the Platform or its server will be free of viruses or other harmful components, and you hereby expressly accepts any associated risks involved with your use of the Platform.</li>
              <li className="mb-4">It is further agreed to by you that the contents of this section shall survive even after the termination or expiry of the Terms of Use and/or Privacy Policy.</li>
            </ol>
          </li>
          <li className="mb-4">
            <span className='font-bold pb-4'>
              FORCE MAJEURE
            </span>
            <p className="pl-[32px] my-2">We will not be liable for damages for any delay or failure to perform our obligations hereunder if such delay or failure is due to cause beyond our control or without its fault or negligence, due to force majeure events including but not limited to acts of war, acts of God, earthquake, riot, fire, festive activities sabotage, labour shortage or dispute, internet interruption, technical failure, breakage of sea cable, hacking, piracy, cheating, illegal or unauthorized.</p>
          </li>
          <li className="mb-4">
            <span className='font-bold pb-4'>
              DISPUTE RESOLUTION AND JURISDICTION
            </span>
            <ol className='list-level-2 mt-4'>
              <li className="mb-4">These Terms shall be governed and interpreted by and construed in accordance with the substantive India and subject to arbitration provisions below, each party hereby irrevocably and finally submits to the exclusive jurisdiction of the courts of Hyderabad, India thereto in respect of any disputes, legal action or proceedings arising out of or in connection with the Terms, interpretation, duties, performance, breach, etc. of these Terms.</li>
              <li className="mb-4">Should any Dispute arise out of or in connection with these Terms, the Parties hereto shall first endeavor to settle such Dispute amicably. If the Dispute is not resolved through amicable settlement within fifteen (15) days after commencement of discussions or such longer period as the Parties mutually agree in writing, then either Party may refer the Dispute for resolution by arbitration according to the provisions of the Arbitration and Conciliation Act, 1996 (India) or any statutory amendment or re-enactment thereof, or any statute enacted to replace the same, for the time being in force. The arbitration shall be conducted by a sole arbitrator appointed by mutual consent of the parties. The seat and venue of arbitration shall be in Hyderabad, India, and the language of the proceedings shall be English.</li>
              <li className="mb-4">The Award rendered in any arbitration commenced hereunder shall be final and conclusive, and judgment thereon may be entered in any court having jurisdiction for its enforcement. The Parties undertake to implement the arbitration award. In addition, the Parties agree that no Party shall have any right to commence or maintain a suit or legal proceeding concerning a Dispute hereunder (other than for preventive or interlocutory relief pending completion of the arbitration proceedings under these Terms) until the Dispute has been determined in accordance with the arbitration procedure provided for herein and then only for the enforcement of the award rendered in such arbitration. When any Dispute is under arbitration, except for the matters under dispute, the Parties shall continue to exercise their remaining respective rights and fulfil their remaining respective obligations under these Terms.</li>
            </ol>
          </li>
          <li className="mb-4">
            <span className='font-bold pb-4'>
              MISCELLANEOUS PROVISIONS
            </span>
            <ol className='list-level-2 mt-4'>
              <li className="mb-4"><strong>Entire Agreement:</strong> These Terms of Use, read with the Privacy Policy and Disclaimer form the complete and final contract between us with respect to the subject matter hereof and supersedes all other communications, representations, and agreements (whether oral, written, or otherwise) relating thereto.</li>
              <li className="mb-4"><strong>Waiver:</strong> The failure at any time to require the performance of any provision of these Terms of Use shall in no manner affect our right at a later time to enforce the same. No waiver by us of any breach of these Terms of Use, whether by conduct or otherwise, in any one or more instances, shall be deemed to be or construed as a further or continuing waiver of any such breach, or a waiver of any other breach of these Terms of Use. </li>
              <li className="mb-4"><strong>Severability:</strong> If any provision/clause of these Terms of Use is held to be invalid, illegal, or unenforceable by any court or authority of competent jurisdiction, the validity, legality, and enforceability of the remaining provisions/clauses of these Terms of Use shall in no way be affected or impaired thereby, and each such provision/clause of these Terms of Use shall be valid and enforceable to the fullest extent permitted by Applicable Law. In such case, these Terms of Use shall be reformed to the minimum extent necessary to correct any invalidity, illegality or unenforceability, while preserving to the maximum extent the original rights, intentions and commercial expectations of the Parties hereto, as expressed herein. </li>
              <li className="mb-4"><strong>Emails, Push Notifications and Communications -</strong> Unless you ask us not to, we may, from time to time, send you emails, push notifications and/or other communications containing offers, tips and other content from Our Products. You are entitled to opt-out of receiving communications from us at any time. Details of how to opt-out will be sent within each communication or you can contact us via the details provided at the end of these terms and conditions.</li>
              <li className="mb-4"><strong>Contact Us:</strong> If you have any questions about these Terms of Use, the practices of the Website, or your experience, you can contact us by emailing us at contact@cricketgully.com or by writing to us at : admin@cricketgully.com</li>
            </ol>
          </li>
        </ol>
      </div>
    </div>
  );
}

export default TermsOfUse;