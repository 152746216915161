import React, { useState, useEffect, useRef } from 'react';
import ground from "../../Assets/matchInstance/ground.jpg";
import logo from "../../Assets/matchInstance/logo.svg";
import C from "../../Assets/matchInstance/c.svg";
import VC from "../../Assets/matchInstance/vc.svg";
import userIcon from "../../Assets/matchInstance/user.png";
import { useAxiosSWR } from '../../../axiosInstance';
import { Link } from 'react-router-dom';
import { doScrollToTop, getNewsDetailsLinkPath } from '../../helpers/general';
import { useHorizontalScroll } from '../../hooks/useHorizontalScroll';

const role = [
    {
        name: "wk",
        value: "WICKET KEEPER"
    },
    {
        name: "bat",
        value: "BATTER"
    },
    {
        name: "img",
        value: <img src={logo} alt="logo" className="mx-auto" />
    },
    {
        name: "all",
        value: "ALL ROUNDER"
    },
    {
        name: "bowl",
        value: "BOWLER"
    }
]

const FantasyCommon = ({ matchId, readFullArticle = true, pageType }) => {
    const [selectedTypeId, setSelectedTypeId] = useState(null);
    const [selectedPlateform, setSelectedPlateform] = useState("");
    const { data: teamsData, isLoading, error } = useAxiosSWR(`/v1/fantasy/teams/${matchId}`);
    const { data: teamInfoData, isLoading: teamInfoloading, error: teamInfoError } = useAxiosSWR(selectedTypeId ? `/v1/fantasy/teamInfo/${selectedTypeId}` : null);
    const plateFroms = teamsData?.platforms;
    const containerRef = useRef();
    const typeContainerRef = useRef();
    useHorizontalScroll(containerRef, selectedPlateform);
    useHorizontalScroll(typeContainerRef, selectedTypeId);
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        if (plateFroms?.length > 0) {
            setSelectedPlateform(plateFroms[0]);
        }
    }, [plateFroms])
    const filteredTeams = teamsData?.teams?.filter(team => team?.platforms?.includes(selectedPlateform));

    useEffect(() => {
        if (filteredTeams?.length > 0 && !selectedTypeId) {
            setSelectedTypeId(filteredTeams[0].id);
        }
    }, [filteredTeams]);

    const handleTypeClick = (id) => {
        setSelectedTypeId(id);
    };

    if (teamInfoloading) {
        return <div></div>;
    }

    const renderPlayers = (players, role, team) =>
        players?.filter(player => player.role === role)?.map(({ name, credit, is_captain, is_vice_captain }) => (
            <div className='md:mt-4 mt-2 relative'>
                <img src={userIcon} alt="" className='mx-auto md:w-auto w-[30px]' />
                <img src={is_captain ? C : is_vice_captain ? VC : ""} alt="" className='absolute top-1 -right-1 md:right-1 md:w-[40px] w-[25px]' />
                <div
                    key={name}
                    className={`flex gap-4 md:px-5 md:py-0.5 px-2 rounded-full leading-[12px] md:leading-5 md:text-[15px] font-semibold text-[8px] ${team === "teama"
                        ? "bg-gradient-to-b from-white via-gray-300 to-gray-500 text-black"
                        : "bg-gradient-to-b from-black via-gray-800 to-gray-700 text-white"
                        } `}
                >
                    {name}
                </div>
                <div className='text-[11px] md:text-[12px]'>
                    {credit}
                </div>
            </div>
        ));

    const teama = teamInfoData?.teamInfo?.players?.filter(({ team_id }) => team_id === teamInfoData?.matchInfo?.teama);
    const teamb = teamInfoData?.teamInfo?.players?.filter(({ team_id }) => team_id === teamInfoData?.matchInfo?.teamb);

    if (teamsData?.teams?.length <= 0) {
        return <div className='p-4 flex justify-center font-extrabold text-xl'>No Fantasy Teams</div>;
    }
    if (error || teamInfoError) {
        return <h2></h2>;
    }

    return (
        <div>
            <div className={`${pageType == "fantasy-details" ? "px-4 md:px-0" : "md:block hidden"}`}>
                <div className='md:grid flex gap-4 md:grid-cols-4 overflow-x-auto hideScrollbar'>
                    {isLoading ? <div className='min-h-[28px] loading'></div> : plateFroms.map((platefromName) => (
                        <div
                            key={platefromName}
                            className={`border rounded-lg text-center md:text-base text-sm px-4 md:px-0 py-0.5 font-medium md:font-semibold cursor-pointer ${selectedPlateform === platefromName ? 'border-[#3A32D1] text-[#3A32D1] bg-[#3255D11A]' : ''}`}
                            onClick={() => {
                                setSelectedPlateform(platefromName)
                                setSelectedTypeId(null)
                            }}
                        >
                            {platefromName}
                        </div>
                    ))}
                </div>
                <div className='md:grid flex gap-4 md:grid-cols-4 overflow-x-auto mt-4 hideScrollbar'>
                    {filteredTeams?.map(({ contest_type, id }) => (
                        <div
                            key={id}
                            className={`border rounded-lg text-center md:text-base text-sm px-4 md:px-0 py-0.5 font-medium md:font-semibold cursor-pointer ${selectedTypeId === id ? 'border-[#3A32D1] text-[#3A32D1] bg-[#3255D11A]' : ''}`}
                            onClick={() => handleTypeClick(id)}
                        >
                            {contest_type}
                        </div>
                    ))}
                </div>
            </div>
            <div className={`bg-white ${pageType === "fantasy-details" ? "hidden" : "md:hidden"}`}>
                <div ref={containerRef} className="overflow-x-auto hideScrollbar mt-4 max-w-max flex">
                    {plateFroms?.map((platefromName) => (
                        <button
                            key={platefromName}
                            data-testid={platefromName}
                            className={`py-2 px-6 font-medium text-[12px] text-nowrap ${platefromName === selectedPlateform
                                ? "text-[#312AB7] bg-[#FFFFFF] border-b-[3px] border-[#312AB7] font-semibold"
                                : "bg-[#FFFFFF] p-1 opacity-90 text-[#3A32D1]"
                                }`}
                            onClick={() => {
                                setSelectedPlateform(platefromName)
                                setSelectedTypeId(null)
                            }}
                        >
                            {platefromName}
                        </button> 
                    ))}
                </div>
            </div>
            <div ref={typeContainerRef} className={`overflow-x-auto hideScrollbar my-4 max-w-max flex gap-2 ${pageType === "fantasy-details" ? "hidden" : "md:hidden"}`}>
                {filteredTeams?.map(({ contest_type, id }) => (
                    <button
                        key={id}
                        data-testid={id}
                        className={`py-2 px-3 font-medium text-[12px] text-nowrap ${selectedTypeId === id
                            ? "text-[#FFFFFF] bg-[#312AB7] border-b-[3px] border-[#312AB7] rounded-lg"
                            : "bg-[#FFFFFF] p-1 rounded-lg"}`}
                        onClick={() => {
                            handleTypeClick(id)
                        }}
                    >
                        {contest_type}
                    </button>
                ))}
            </div>
            <div className={`md:border-none bg-white rounded-2xl ${pageType === "fantasy-details" ? "" : "border"}`}>
                <div className='md:mt-8 font-semibold flex justify-between md:p-0 p-6'>
                    <div>
                        <div className='text-xs md:text-sm text-gray-500'>
                            Players
                        </div>
                        <div className='md:text-xl'>
                            {`${teamInfoData?.teamInfo?.players?.length || 0}/11`}
                        </div>
                    </div>
                    <div className='flex gap-2'>
                        <div className='flex items-center md:px-7 mt-2 px-3 text-xs md:text-base rounded-full bg-gradient-to-b from-white via-gray-300 to-gray-200 text-black'>{teamInfoData?.matchInfo?.teama_name}</div>
                        <div className='flex items-end text-[18px] md:text-[28px] font-extrabold'>
                            {teama?.length} : {teamb?.length}
                        </div>
                        <div className='flex items-center border border-[#0000001A] md:px-7 px-3 text-xs md:text-base mt-2 rounded-full bg-gradient-to-b from-black via-gray-800 to-gray-600 text-white'>{teamInfoData?.matchInfo?.teamb_name}</div>
                    </div>
                    <div className='text-end'>
                        <div className='text-xs md:text-sm text-gray-500'>
                            Credits Left
                        </div>
                        <div className='md:text-xl'>
                            {`${teamInfoData?.teamInfo?.credit_left || 0}/100`}
                        </div>
                    </div>
                </div>
                <div className="relative">
                    <div className="relative">
                        <div className='md:mt-4'>
                            <img src={ground} alt="" className='md:h-auto h-[522px] md:rounded-xl' />
                        </div>
                        <div className="grid grid-rows-5 w-full h-full absolute top-0 py-4 px-2 md:px-4 text-xs md:py-12 left-0 text-[#FFFFFF] text-center">
                            {role.map(({ name, value }) => (
                                <div key={name}>
                                    <div className='opacity-70 text-[12px] md:text-[16px]'>
                                        {value}
                                    </div>
                                    <div className='flex gap-4 md:gap-6 justify-center items-center'>
                                        {renderPlayers(teama, name, "teama")}
                                        {renderPlayers(teamb, name)}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {
                        (readFullArticle && teamsData?.news?.slug) &&
                        <Link to={getNewsDetailsLinkPath(teamsData?.news?.slug, teamsData?.news?.id, teamsData?.news?.type)} onClick={doScrollToTop}>
                            <div className='bg-[#3A32D1] text-white md:text-base text-xs rounded-full md:px-5 px-3 md:py-2 py-1.5 w-max mx-auto mt-4 md:block hidden'>
                                Read Full Article
                            </div>
                        </Link>
                    }
                    {!isExpanded && (readFullArticle && teamsData?.news?.slug) && (
                        <div className="absolute bottom-0 left-0 right-0 flex items-end rounded-b-lg justify-center w-full md:pb-4 h-[150px] bg-gradient-to-t from-white to-transparent md:hidden">
                            <Link to={getNewsDetailsLinkPath(teamsData?.news?.slug, teamsData?.news?.id, teamsData?.news?.type)}
                                onClick={() => {
                                    doScrollToTop();
                                    setIsExpanded(true);
                                }}
                                className="text-white font-semibold text-xs bg-[#3A32D1] rounded-full md:py-3 py-2 md:px-8 px-4 shadow-lg flex items-center gap-2 mb-14"
                            >
                                Read Full Article
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default FantasyCommon;