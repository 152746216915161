import React, { useMemo } from 'react';
import { formatDate } from '../../helpers/dates';
import { shimmerData } from '../../helpers/constants';
import { Link } from 'react-router-dom';
import { doScrollToTop, getNewsDetailsLinkPath, resizedImageURL, srcsetImageURL } from '../../helpers/general';
import { ShimmerEffect } from './ShimmerEffect';

const COMPONENT_TYPES = {
    GROUP_STORIES: 'group_stories',
    SINGLE_STORY: 'single_story'
};


const renderSingleStory = ({ data: story, showHeader, title, showSeeMore, LinkTo, shimmerClassName }) => {
    if (!story) return null;
    const { id, image, title: newsTitle, date, slug, type } = story;
    return (
        <div key={id} className={`bg-white md:border md:rounded-2xl rounded-xl md:mx-0 mx-[6px] ${showHeader ? "mt-0" : "mt-5"}`}>
            {showHeader && (
                <div className='px-5 md:pt-8 py-4 md:pb-4 pb-0'>
                    <div className={`md:min-h-[18px] md:min-w-[180px] ${shimmerClassName} flex items-center min-w-[50px]`}>
                        <span className="custom-border-left mr-3 md:mr-6"></span>
                        <h2 className='text-[16px] md:text-2xl'>{title}</h2>
                        <span className="custom-border-right ml-3 md:ml-6"></span>
                    </div>
                </div>
            )}
            <Link to={getNewsDetailsLinkPath(slug, id, type)} onClick={doScrollToTop}>
                <div className='md:p-5 py-4 px-[10px]'>
                    <ShimmerEffect src={resizedImageURL(image,"332x166")} srcSet={resizedImageURL(image,"576x288","576x288")} alt="news" className={`${shimmerClassName} w-full md:h-[288px] h-[166px] object-cover rounded-xl`} />
                    <p className={`${shimmerClassName} min-h-6 mt-3 news-title leading-[22px] font-bold text-lg`}>
                        {newsTitle}
                    </p>
                    <p className={`${shimmerClassName} mt-2 text-xs leading-[16px] font-semibold text-[#6C6C6C]`}>
                        {formatDate(date)}
                    </p>
                </div>
            </Link>
            {showSeeMore && (
                <>
                    <div className='flex items-end justify-center h-2 w-full bg-gradient-to-t from-[#e2e2e2ab] to-transparent'>
                    </div>
                    <div className='text-[#3A32D1] text-center py-3 md:text-lg text-xs font-semibold'>
                        <Link
                            key='btnSeeMoreNews2'
                            to={LinkTo}
                            onClick={doScrollToTop}
                        >
                            See More
                        </Link>
                    </div>
                </>
            )}
        </div>
    );
};


const renderNewsGroup = ({ data: newsGroup, showHeader, showSeeMore, title, LinkTo, shimmerClassName }) => (
    <div className={`bg-white md:border md:rounded-2xl rounded-xl md:mx-0 mx-[6px] ${showHeader ? "mt-0" : "mt-5"}`}>
        {showHeader && (
            <div className='px-3 md:pt-8 md:pb-4 pt-4'>
                <div className={`md:min-h-[18px] md:min-w-[180px] ${shimmerClassName} flex items-center min-w-[50px]`}>
                    <span className="custom-border-left mr-3 md:mr-6"></span>
                    <h2 className='text-[16px] md:text-2xl'>{title}</h2>
                    <span className="custom-border-right ml-3 md:ml-6"></span>
                </div>
            </div>
        )}
        <div className='grid md:grid-cols-10 md:p-5 py-4 px-[10px]'>
            {newsGroup?.map(({ id, image, title, date, slug, type }, index) => (
                <div key={id} className={`col-span-10 ${index === newsGroup.length - 1 ? "" : "pb-4"}`}>
                    <Link to={getNewsDetailsLinkPath(slug, id, type)} onClick={doScrollToTop}>
                        <div className='grid grid-cols-4 md:grid-cols-5 '>
                            <div className='col-span-1 md:col-span-1'>
                                <ShimmerEffect src={resizedImageURL(image,"90x90")} srcSet={srcsetImageURL(image,"200x100","200x100")} alt="news" className={`${shimmerClassName} h-full w-full md:h-[90px] rounded-xl object-cover`} />
                            </div>
                            <div className='col-span-3 md:ml-[14px] ml-3 md:col-span-4'>
                                <p className={`${shimmerClassName} news-title min-h-7 md:min-h-12 text-xs md:text-base font-semibold leading-[18px]`}>
                                    {title}
                                </p>
                                <p className={`${shimmerClassName} mt-3 md:text-xs text-[10px] leading-[16px] font-semibold text-[#6C6C6C]`}>
                                    {formatDate(date)}
                                </p>
                            </div>
                        </div>
                    </Link>
                </div>
            ))}
        </div>
        {showSeeMore && (
            <>
                <div className='flex items-end justify-center h-2 w-full bg-gradient-to-t from-[#e2e2e2ab] to-transparent'>
                </div>
                <div className='text-[#3A32D1] text-center py-3 md:text-lg text-xs font-semibold'>
                    <Link
                        key='btnSeeMoreNews2'
                        to={LinkTo}
                        onClick={doScrollToTop}
                    >
                        See More
                    </Link>
                </div>
            </>
        )}
    </div>
);

const FantasyNews = ({ title, data = [], LinkTo, isLoading, isError }) => {
    const newsData = isLoading ? shimmerData : data || [];
    const shimmerClassName = newsData[0]?.isShimmer ? "bg-gray-300 loading text-opacity-0" : "";
    if (isError) {
        return (
            <div className='p-4'>
            </div>
        )
    }

    const groupedNews = useMemo(() => getMeGroups(newsData), [newsData]);

    return (
        <>
            {groupedNews.map(({ componentType, data, showHeader, showSeeMore }, index) => (
                <div data-testindex={index} key={index} className='md:mt-4'>
                    {componentType === COMPONENT_TYPES.SINGLE_STORY && renderSingleStory({ data, showHeader, showSeeMore, title, LinkTo, shimmerClassName })}
                    {componentType === COMPONENT_TYPES.GROUP_STORIES && renderNewsGroup({ data, showHeader, showSeeMore, title, LinkTo, shimmerClassName })}
                </div>
            ))}
        </>
    );
}

export default FantasyNews;

function getMeGroups(stories) {
    const res = [];
    for (let i = 0; i < stories.length;) {
        const story = stories[i];
        if (story.isFeatured) {
            res.push({
                componentType: COMPONENT_TYPES.SINGLE_STORY,
                data: story,
                indexes: [i],
                showHeader: res.length === 0,
            });
            i++;
            continue;
        }

        const group = [];
        const obj = {
            componentType: COMPONENT_TYPES.GROUP_STORIES,
            data: group,
            indexes: [i]
        };
        res.push(obj);
        while (i < stories.length && stories[i].isFeatured === false) {
            group.push(stories[i]);
            i++;
        }
        obj.indexes.push(i - 1);
    }

    if (res.length > 0) {
        res[0].showHeader = true;
        const lastIndex = res.length - 1;
        res[lastIndex].showSeeMore = true;
    }
    return res;
}
