import React, { useCallback, useState, useEffect } from "react";
import { Route, Routes, Navigate, useLocation, useParams } from "react-router-dom";
import Navbar from "src/components/navbar";
import { NewsDetails } from "./components/pages/NewsDetails";
import Footer from "./components/footer/Footer";
import Series from "./components/pages/Series/Series";
import SelectSeriesPages from "./components/pages/Series/SeriesInstance/SelectSeriesPages";
import Home from "./components/pages/Home/Home";
import Schedule from "./components/pages/Schedule/Schedule";
import Matches from "./components/pages/Schedule/MatchInstance/component";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import TermsOfUse from "./components/pages/TermsOfUse";
import MobileNavbar from "./components/navbar/MobileNavbar";
import LoginMain from "./components/pages/Login/LoginMain";
import AdSquare from "./components/common/AdSquare";
import LayoutV1 from "./LayoutV1";
import Teams from "./components/pages/Team/Teams";
import SelectTeamView from "./components/pages/Team/TeamView/SelectTeamView";
import EditProfileForm from "./components/pages/UserProfile/EditProfileForm";
import 'react-toastify/dist/ReactToastify.css';
import NewsV2 from "./components/NewsV2";
import FantasyV2 from "./components/pages/FantasyV2";
import TopRanking from "./components/pages/TopRanking";
import { PointsTable, PointsTableOfSeries } from "./components/pages/PointsTable";
import { AD_CAMPAIGN } from "./helpers/constants";
import MoreNews from "./components/common/MoreNews";
import AppContext, { getDefaultAppContext } from "src/context";
import MobileTopNavbar from "./components/navbar/MobileTopNavbar";
import CorrectionPolicy from "./components/pages/CorrectionPolicy";
import FactCheckPolicy from "./components/pages/FactCheckPolicy";
import CookiePolicy from "./components/pages/CookiePolicy";
import DMCAPolicy from "./components/pages/DMCAPolicy";
import DisclimerPolicy from "./components/pages/DisclimerPolicy";
import AboutUs from "./components/pages/AboutUs";
import ContactUsPages from "./components/pages/ContactUs";
import Authors from "./components/pages/Authors";
import CookieConsent from "./components/cookieConsent/CookieConsent";
import SubscribeConsent from "./components/cookieConsent/SubscribeConsent";
import AuthorsInstance from "./components/pages/Authors/AuthorsInstance";

const App = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const isLoginPage = pathname.toLowerCase().includes("/login");
  const isUserFormPage = pathname.toLowerCase().includes("/user-profile");
  useEffect(() => {
    console.log('auth token -- ', process.env.SENTRY_AUTH_TOKEN);
    console.log(' app_env -- ', process.env.APP_ENV);
  }, []);

  return (
    <div className="min-h-screen flex flex-col ">
      <div>
      {/* <SubscribeConsent/> */}
      <CookieConsent/>
      </div>
      <div className="md:block hidden">
        {!isLoginPage && !isUserFormPage && <AdSquare campaign={AD_CAMPAIGN.HOME_TOP_BANNER} />}
      </div>
        <div
          className={`sticky -top-1 z-50 hidden md:block`}
        >
          <Navbar />
        </div>
        <div
          className={`sticky -top-1 z-50 md:hidden ${(isLoginPage || isUserFormPage) ? "hidden md:block" : "" } `}
        >
          <MobileTopNavbar />
        </div>
      <div className="flex-grow">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/correction-policy" element={<CorrectionPolicy />} />
          <Route path="/fact-check-policy" element={<FactCheckPolicy />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/dmca-policy" element={<DMCAPolicy />} />
          <Route path="/disclaimer-policy" element={<DisclimerPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/user-profile" element={<EditProfileForm />} />
          <Route path="/about-us" element={<AboutUs/>}/>
          <Route path="/contact-us" element={<ContactUsPages/>}/>
          <Route path="/authors" element={<Authors/>}/>
          <Route path="/authors/:authorId" element={<AuthorsInstance/>}/>
          <Route path="/login" element={<LoginMain />} />
          <Route element={<LayoutV1 />}>
            <Route path="/points-table" element={<PointsTable />} />
            <Route path="/points-table/:seriesSlug" element={<PointsTableOfSeries />} />
            <Route path="/ranking" element={<TopRanking />} />
            <Route path="/ranking/:type" element={<TopRanking />} />
            <Route path="/ranking/:type/:subType" element={<TopRanking />} />
            <Route path='/news' element={<Navigate to="/cricket-news" />} />
            <Route path="/news/:path" element={<RedirectNewsToCricketNews />} />
            <Route path="/cricket-news" element={<NewsV2 />} />
            <Route path="cricket-top-story" element={<NewsV2 />} />
            <Route path="/schedule/:type" element={<Schedule />} />
            <Route
              path="/series/:seriesId/matches/:matchesId/:type"
              element={<Matches />}
            />
            <Route path="/fantasy" element={<FantasyV2 />} />
            {/* <Route path="/fantasyv2" element={<FantasyV2 />} /> */}
            <Route path="/cricket-news/:id" element={<NewsDetails />} />
            <Route path="/fantasy-cricket-tips/:id" element={<NewsDetails />} />
            <Route path="/cricket-top-story/:id" element={<NewsDetails />} />
            <Route path="/teams" element={<Teams />} />
            <Route path="/teams/:id/:type" element={<SelectTeamView />} />
            <Route path="/series" element={<Series />} />
            <Route path="/series/:id/:type" element={<SelectSeriesPages />} />
            <Route path="/series/:slug/:type/:team" element={<SelectSeriesPages />} />
            <Route path="/category/:category_slug" element={<MoreNews />} />
            <Route path="/tag/:tag" element={<NewsV2 />} />
          </Route>
        </Routes>
      </div>
      {!isLoginPage && !isUserFormPage && <Footer />}
      {!isLoginPage && !isUserFormPage && (
        <div style={{ zIndex: 10000}} className="sticky -bottom-1 md:hidden z-40">
          <MobileNavbar />
        </div>
      )}
      <input
        id="cricketGullyApiBaseUrl"
        type="hidden"
        value={process.env.API_BASE_URL}
      />
    </div>
  );
};

const AppWrapper = () => {
  const [appContextState, setAppContextState] = useState(getDefaultAppContext());

  const updateUrlInfo = useCallback((updatedInfo) => {
    setAppContextState(prevState => {
      const pathName = window.location.pathname;
      const currentUrlInfo = prevState.urlInfoByKey[pathName] || {};
      return {
        ...prevState,
        urlInfoByKey: {
          ...prevState.urlInfoByKey,
          [pathName]: {
            ...currentUrlInfo,
            ...updatedInfo,
          }
        },
      };
    })
  }, []);


  return (
    <AppContext.Provider value={{
      state: appContextState,
      updateUrlInfo,
    }}>
      <App />
    </AppContext.Provider>
  )
}
export default AppWrapper;

function RedirectNewsToCricketNews(props) {
  const { path = '' } = useParams(); // Extract the dynamic part of the path
  const url = `/cricket-news/${path}`;
  window.location.href = url;
}