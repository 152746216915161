import React from 'react'
import SEOMeta from './Home/common/SEOMeta'

const DMCAPolicy = () => {
    return (
        <div className="container mx-auto px-4 py-8">
            <SEOMeta slug="privacy" />
            <h1 className="font-bold mb-8">DMCA Policy</h1>
            <div className="bg-white rounded-lg shadow-md p-8 text-justify">
                <div className='mb-4'>
                    <p className="mb-4">CricketGully would like to state that the photos, videos or any content featured on the website may be freely accessed for use in representational purposes from a variety of online sources and CricketGully may or maynot exercise Intellectual Property Rights over such content except specifically otherwise stated therein. The content, photos and videos may be available in the public domain which may be used by those who are inclined to do so without any violation of intellectual property rights of respective owner(s). While accepting responsibility for our actions, CricketGully explicitly states that it never intends to violate or infringe upon the rights of any natural or artificial person or legal entity. </p>
                    <p className='mb-4'>In case you feel that your intellectual property rights are being violated or are in the verge of violation, it is advisable to inform us if you are the sole owner of any picture or video or content that appears on our website. Any natural or artificial person that feels that CricketGully is using their owned or copy-righted material without permission or without giving proper credit for it may, file a written complaint, notice, or request that the image, content, or other materials be removed on contact@cricketgully.com</p>
                    <p className='mb-4'>It is our policy to respond to claims of infringement in compliance with the Digital Millennium Copyright Act of 1998 ("DMCA"). If you are a copyright owner, or authorized on behalf of one, and you believe that the copyrighted work has been copied in a way that constitutes copyright infringement that is taking place through the Service, please complete the following DMCA Notice and send it to the email address mentioned above.</p>
                    <p className='mb-4'>
                        The following information must be included in the said complaint, notification, or request:
                    </p>
                    <ol className='mx-10 mb-4'>
                        <li className='mb-2'>
                            <p>the complainant's name, valid contact information, and a document attesting to their exclusive right ownership of the picture, material, or anything in question; 
                            </p>
                        </li>
                        <li className='mb-2'>
                            <p>the precise location on the website where the offended party originally read the information, together with a screenshot or URL link to the page as evidence; 

                            </p>
                        </li>
                        <li className='mb-2'>
                            <p>particulars of the picture, text, or something else;
                            </p>
                        </li>
                        <li className='mb-2'>
                            <p>a statement that the complainant/ You are the legitimate owner of the work that has been used improperly; this declaration will be utilized against the firm/ natural or artificial person, should it be discovered that the corporation has made fraudulent, deceptive, or defamatory use of the complaint.
                            </p>
                        </li>
                    </ol>

                    <p className='mb-4'>Make sure to include a letter of authorization with the Declaration that the First Party or the Original Copy Right holder has signed, either in person or electronically, if you are a Second Party writing to us on behalf of them.</p>
                    <p className='mb-4'>For users whose content has been taken down: If you receive a DMCA notification, it means that the content described in the notice has been removed from Medium or access to the content on Medium has been restricted. Please carefully read our notice to you, which includes information about the notification we received as well as instructions on how to file a counter-notice.</p>
                    <h4 className="font-semibold px-2 mb-4 text-blue-900 border rounded-lg bg-gray-100">Filing a Counter-Notice</h4>
                    <p className='mb-4'>If you received a DMCA notification about your material and believe that material was misidentified or removed in error, you should file a counter-notice by following the instructions below.</p>
                    <p className='mb-4'>Re-posting material removed in response to a DMCA notification may result in permanent account suspension. If you believe the content was removed in error, please file a counter-notice instead of re-posting the material.</p>
                   
                    <p className='mb-4'>To submit a counter-notice, please provide us with the following information:</p>
                    <ol className='mx-10 mb-4'>
                        <li className='mb-2'>
                            <p>A physical or electronic signature (typing your full name will suffice);</p>
                        </li>
                        <li className='mb-2'>
                            <p>Identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or access to it was disabled (the description from the DMCA notice will suffice);</p>
                        </li>
                        <li className='mb-2'>
                            <p>A statement under penalty of perjury that you have a good-faith belief that the material was removed or disabled as a result of mistake or misidentification of the material to be removed or disabled; and</p>
                        </li>
                        <li className='mb-2'>
                            <p>Your name, address, and telephone number, and a statement that you consent to the jurisdiction of the federal district court for the judicial district in which your address is located, or if your address is outside of the United States, for any judicial district in which Medium may be found, and that you will accept service of process from the person who provided the copyright notification or an agent of such person.</p>
                        </li>
                    </ol>
                    <p className='mb-4'>To submit a counter-notice, please respond to our original email notification of the removal and include the above information in the body of your reply.</p>
                    <p className='mb-4'>When we receive a valid counter-notice, we will promptly forward a copy to the person who filed the original notification. If we don’t receive notice within 10 business days that the original reporter is seeking a court order to prevent further infringement of the material, we may replace or cease disabling access to the material that was removed.</p>
                    <p className='mb-4'>In some cases, the request may not be considered if any of the above details are filled out incorrectly or incompletely and sent to CricketGully. In serious cases, the company may file a lawsuit against the person or business attempting to falsely frame CricketGully in an unfounded case.</p>
                    <p className='mb-4'>It should be noted that upon receipt of a fully legitimate, verified complaint, notice, or request, the company will acknowledge it in writing (to the email address provided in the complaint, notice, or request) and provide a timeframe within which the matter will be appropriately and addressed.</p>
                    <p className='mb-4'>In accordance with the DMCA and other applicable law, we have adopted a policy of terminating, in appropriate circumstances, users who are deemed to be repeat infringers. We may also limit access to the Service and/or terminate the accounts of any users who infringe any intellectual property rights of others, whether or not there is any repeat infringement.</p>
                    <p className='mb-4'>Taking into account all of these details and provisions as part of the company's founding policy, CricketGully never seeks to knowingly claim ownership of any property that belongs to someone other than CricketGully or its Associates or Affiliates.</p>
                </div>
            </div>
        </div >
    )
}

export default DMCAPolicy