export const AD_CAMPAIGN = {
    HOME_TOP_BANNER: "home-top-banner",
    HOME_LEFT_SQUARE: "home-left-square",
    HOME_RIGHT_SQUARE: "home-right-square",
    FANTASY_SQUARE: "fantasy-square",
    NEWS_SQUARE: "news-square",
    NEWS_DETAILS_SQUARE: "news-details-square",
    SCHEDULE_SQUARE: "schedule-square",
    SERIES_SQUARE: "series-square",
    SERIES_DETAILS_SQUARE: "series-details-square",
    MATCH_DETAILS_SQUARE: "match-details-square",
    TEAMS_SQUARE: "teams-square",
    RANKING_SQUARE: "ranking-square",
    POINTS_TABLE: "points-table-square",
    CATEGORY_SQUARE: "category-square",
    TAG_SQUARE: "tag-square"
}

export const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const shimmerData = [
    {
        "id": 11,
        "title": "",
        "slug": "",
        "excerpt": "",
        "isFeatured": false,
        "image": "https://static.cricketgully.com/filters:grayscale()/filters:blur(6)/cricketgully-logo.jpg",
        "shareText": "",
        "date": "2024-07-29T14:35:37.219Z",
        isShimmer: true
    },
    {
        "id": 313,
        "title": "",
        "slug": "",
        "excerpt": "",
        "isFeatured": true,
        "image": "https://static.cricketgully.com/filters:grayscale()/filters:blur(6)/cricketgully-logo.jpg",
        "shareText": "",
        "date": "2024-07-29T12:04:58.520Z",
        isShimmer: true
    },
    {
        "id": 309,
        "title": "",
        "slug": "",
        "excerpt": "",
        "isFeatured": true,
        "image": "https://static.cricketgully.com/filters:grayscale()/filters:blur(6)/cricketgully-logo.jpg",
        "shareText": "",
        "date": "2024-07-29T10:55:36.212Z",
        isShimmer: true
    },
    {
        "id": 283,
        "title": "",
        "slug": "",
        "excerpt": "",
        "isFeatured": false,
        "image": "https://static.cricketgully.com/filters:grayscale()/filters:blur(6)/cricketgully-logo.jpg",
        "shareText": "",
        "date": "2024-05-15T08:34:31.961Z",
        isShimmer: true
    },
    {
        "id": 280,
        "title": "",
        "slug": "",
        "excerpt": "",
        "isFeatured": false,
        "image": "https://static.cricketgully.com/filters:grayscale()/filters:blur(6)/cricketgully-logo.jpg",
        "shareText": "",
        "date": "2024-05-03T10:45:03.254Z",
        isShimmer: true
    }
]