import React from 'react'
import aboutusBanar from "../../Assets/aboutus.svg"
import aboutusBanarMobile from "../../Assets/aboutusMoile.svg"
import img from "../../Assets/placeholder.png"
import ContactUs from '../common/ContactUs'

const AboutUs = () => {
    return (
        <div>
            <div className='hidden sm:block'>
                <img src={aboutusBanar} alt="" />
            </div>
            <div className='sm:hidden '>
                <img src={aboutusBanarMobile} className='w-full' alt="" />
            </div>
            <div className=''>
                <div className='bg-white border rounded-3xl mx-1 md:mx-[80px] lg:mx-[140px] xl:mx-[180px] md:my-[80px] mt-1 mb-[40px] md:rounded-[20px] md:py-10 py-6 md:px-[30px] px-[20px]'>
                    <div>
                        <div className={`md:min-h-[18px] md:min-w-[180px] flex items-center justify-center min-w-[50px]`}>
                            <span className="custom-border-left mr-3 md:mr-6 "></span>
                            <div className='text-[18px] md:text-[30px] leading-5 md:leading-10 py-3 md:py-1.5'><span className='font-bold'>Our </span>Story</div>
                            <span className="custom-border-right ml-3 md:ml-6 "></span>
                        </div>
                        <p className='text-[14px] md:text-[24px] pt-[7px] md:pt-7 leading-5 md:leading-10'>
                            Cricket Gully was established in 2024 by three passionate cricket enthusiasts: Dileep Kureti, Sai Kiran Vakalapudi, and Vedant Awalegaonkar. United by a lifelong love for cricket, they forged their friendship through countless matches and spirited discussions about players and strategies. Recognizing a gap in cohesive and engaging cricket coverage, they set out to create a platform that not only provides reliable information but also fosters a sense of community among fans. Their journey began with the vision of connecting cricket lovers worldwide, quickly evolving into a premier resource for all things cricket.
                        </p>
                    </div>
                    <div className='mt-10 md:mt-20'>
                        <div className={`md:min-h-[18px] md:min-w-[180px] flex items-center justify-center min-w-[50px]`}>
                            <span className="custom-border-left mr-3 md:mr-6 "></span>
                            <div className='text-[18px] md:text-[30px] leading-5 md:leading-10 py-3 md:py-1.5'><span className='font-bold'>Our </span>Mission and Vision</div>
                            <span className="custom-border-right ml-3 md:ml-6 "></span>
                        </div>
                        <p className='text-[14px] md:text-[24px] pt-[7px] md:pt-7 leading-5 md:leading-10'>
                            At Cricket Gully, our mission is to deliver comprehensive and engaging cricket coverage that resonates with fans at every level. We are committed to providing insightful analysis, real-time updates, and diverse content that enriches the cricket-watching experience. Our vision is to become the leading platform for cricket coverage, where every fan can access the information, analysis, and community support needed to celebrate the game they love.
                        </p>
                    </div>
                    <div className='mt-10 md:mt-20'>
                        <div className={`md:min-h-[18px] md:min-w-[180px] flex items-center justify-center min-w-[50px]`}>
                            <span className="custom-border-left mr-3 md:mr-6 "></span>
                            <div className='text-[18px] md:text-[30px] leading-5 md:leading-10 py-3 md:py-1.5'><span className='font-bold'>What</span> We Offer?</div>
                            <span className="custom-border-right ml-3 md:ml-6 "></span>
                        </div>
                        <p className='text-[14px] md:text-[24px] pt-[7px] md:pt-7 leading-5 md:leading-10'>
                            Cricket Gully offers a wide range of content tailored for cricket enthusiasts, including:
                        </p>
                        <ul className='ml-8'>
                            <li><span className='font-bold'>Analysis</span>: In-depth breakdowns of team strategies and player performances.</li>
                            <li><span className='font-bold'>Player Profiles</span>: Detailed insights into players’ careers, statistics, and achievements.</li>
                            <li><span className='font-bold'>Match Previews and Reviews</span>: Comprehensive assessments before and after matches.</li>
                            <li><span className='font-bold'>Fantasy Articles</span>: Tips and strategies for fantasy league players.</li>
                            <li><span className='font-bold'>Latest News</span>: Timely coverage of cricket events and developments.</li>
                            <li><span className='font-bold'>Facts and Records</span>: A rich repository of cricket trivia and historical data.</li>
                        </ul>
                    </div>
                    <div className='mt-10 md:mt-20'>
                        <div className={`md:min-h-[18px] md:min-w-[180px] flex items-center justify-center min-w-[50px]`}>
                            <span className="custom-border-left mr-3 md:mr-6 "></span>
                            <div className='text-[18px] md:text-[30px] leading-5 md:leading-10 py-3 md:py-1.5'><span className='font-bold'>Live </span>Score</div>
                            <span className="custom-border-right ml-3 md:ml-6 "></span>
                        </div>
                        <p className='text-[14px] md:text-[24px] pt-[7px] md:pt-7 leading-5 md:leading-10'>
                            Our live score feature sets us apart by providing fast and accurate updates on matches occurring globally. Users can follow live scores on a single platform, access detailed scoreboards, and receive live commentary and win probabilities. Additionally, our site offers head-to-head statistics, team form analysis, recent match results, venue statistics, and essential weather and pitch reports, ensuring fans are fully equipped to enjoy every match.
                        </p>
                    </div>
                    <div className='mt-10 md:mt-20'>
                        <div className={`md:min-h-[18px] md:min-w-[180px] flex items-center justify-center min-w-[50px]`}>
                            <span className="custom-border-left mr-3 md:mr-6 "></span>
                            <div className='text-[18px] md:text-[30px] leading-5 md:leading-10 py-3 md:py-1.5'><span className='font-bold'>Why </span>Cricket?</div>
                            <span className="custom-border-right ml-3 md:ml-6 "></span>
                        </div>
                        <p className='text-[14px] md:text-[24px] pt-[7px] md:pt-7 leading-5 md:leading-10'>
                        Cricket transcends the boundaries of sport; it is a global phenomenon that unites individuals from diverse cultures and backgrounds. The founders' passion for the game fuels Cricket Gully’s commitment to cultivating a vibrant cricket community. We believe in the sport's unique ability to inspire and connect people, and we are dedicated to fostering inclusivity and enthusiasm within the cricketing world. Our goal is to ensure that the spirit of cricket flourishes for generations to come.
                        </p>
                    </div>
                </div>
                <div className='bg-white border rounded-3xl mx-1 md:mx-[180px] min-h-[500px] md:mb-[80px] mb-[40px] md:rounded-[20px] md:py-10 py-6 md:px-[30px] px-[20px]'>
                    <div className={`md:min-h-[18px] md:min-w-[180px] flex items-center justify-center min-w-[50px]`}>
                        <span className="custom-border-left mr-3 md:mr-6 "></span>
                        <div className='text-[18px] md:text-[30px] leading-5 md:leading-10 py-3 md:py-1.5'><span className='font-bold'>Our</span> Team of Experts</div>
                        <span className="custom-border-right ml-3 md:ml-6 "></span>
                    </div>
                    <div className='md:grid grid-cols-3 md:gap-10 md:mt-4'>
                        <div className='md:p-6 p-3 border rounded-2xl mt-6'>
                            <img src={img} alt="" />
                            <div className='md:pt-10 pt-4 text-center'>
                                <div className='font-bold text-[20px] leading-[20px]'>
                                    Angela Moss
                                </div>
                                <p className='text-[12px] text-[#6C6C6C] pt-5'>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type
                                </p>
                                <div className='pt-5 text-[#3A32D1] text-[12px]'>
                                    Designation
                                </div>
                            </div>
                        </div>
                        <div className='md:p-6 p-3 border rounded-2xl mt-6'>
                            <img src={img} alt="" />
                            <div className='md:pt-10 pt-4 text-center'>
                                <div className='font-bold text-[20px] leading-[20px]'>
                                    Angela Moss
                                </div>
                                <p className='text-[12px] text-[#6C6C6C] pt-5'>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type
                                </p>
                                <div className='pt-5 text-[#3A32D1] text-[12px]'>
                                    Designation
                                </div>
                            </div>
                        </div>
                        <div className='md:p-6 p-3 border rounded-2xl mt-6'>
                            <img src={img} alt="" />
                            <div className='md:pt-10 pt-4 text-center'>
                                <div className='font-bold text-[20px] leading-[20px]'>
                                    Angela Moss
                                </div>
                                <p className='text-[12px] text-[#6C6C6C] pt-5'>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type
                                </p>
                                <div className='pt-5 text-[#3A32D1] text-[12px]'>
                                    Designation
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ContactUs />
            </div>
        </div>
    )
}

export default AboutUs
