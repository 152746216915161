import React from 'react'
import ContactUs from '../common/ContactUs'
import aboutusBanar from "../../Assets/contactusdectop.png"
import aboutusBanarMobile from "../../Assets/contactus.png"

const ContactUsPages = () => {
    return (
        <div>
            <div className='hidden sm:block'>
                <img src={aboutusBanar} alt="" />
            </div>
            <div className='sm:hidden '>
                <img src={aboutusBanarMobile} className='w-full' alt="" />
            </div>
            <div className=''>
                <div className='bg-white border md:grid grid-cols-3 md:gap-9 rounded-3xl mx-4 md:mx-[80px] lg:mx-[140px] xl:mx-[180px] md:my-[80px] mt-4 mb-[40px] md:rounded-[20px] md:py-[54px] py-6 md:px-[54px] px-[20px]'>
                    <input type="text" className='border rounded-xl p-4 md:p-5 block w-full' placeholder='Full Name*' />
                    <input type="text" className='border rounded-xl p-4 md:p-5 block w-full md:mt-0 mt-4' placeholder='Email ID*' />
                    <div className="relative">
                        <span className="absolute inset-y-0 left-0 pl-4 flex justify-center items-center font-bold">
                            +91
                        </span>
                        <input
                            type="number"
                            className="border rounded-xl pl-[50px] md:pl-[51px] p-4 md:p-5 block w-full mt-4 md:mt-0"
                            placeholder="Enter Phone number"
                        />
                    </div>
                    <input type="text" className='border rounded-xl p-4 md:p-5 block w-full mt-4 md:mt-0' placeholder='Company' />
                    <input type="text" className='border rounded-xl p-4 md:p-5 block w-full mt-4 md:mt-0' placeholder='City' />
                    <input type="text" className='border rounded-xl p-4 md:p-5 block w-full mt-4 md:mt-0' placeholder='Subject of contact*' />
                    <textarea className='border rounded-xl p-4 md:p-5 col-start-1 col-end-4 block mt-4 md:mt-0 w-full' placeholder='Message*'></textarea>
                    <button type="text" className='border rounded-xl p-4 md:p-5 bg-[#3A32D1] text-white mt-4 block w-full' > Send</button>
                </div>
                <ContactUs />
            </div>
        </div>
    )
}

export default ContactUsPages
