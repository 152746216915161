import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext,
} from "react";
import { Link, Navigate, useParams, useLocation } from "react-router-dom";
import {
  Live,
  Scorecard,
  Commentary,
  Highlights,
  Playing11,
  News,
  Fantasy,
  MatchInfo,
} from "../MatchInstance/components";
import { useAxiosSWR } from "root/axiosInstance";
import { getRightOrder } from "../../Home/common/liveMatchesCarousel/helper";
import PointTable from "./components/PointTable";
import { ShimmerEffect } from "../../../common/ShimmerEffect";
import liveIcon from "../../../../Assets/red-dot.svg";
import { useHorizontalScroll } from "../../../../hooks/useHorizontalScroll";
import { getSeriesMatchID } from "./components/common/helper";
import Video from "./components/Video";
import { formatDate } from "../../../../helpers/dates";
import SEOMeta from "../../Home/common/SEOMeta";
import SeriesStats from "./components/SeriesStats";
import Fantasy2 from "./components/Fantasy2";
import { FakeLiveMatchChartBox } from "./components/FakeLiveMatchChartBox";
import { doScrollToTop, getSanitizedWinPer } from "../../../../helpers/general";
import LiveStats from "./components/LiveStats";
import AppContext, { URL_TYPES } from "src/context";
import { getClassByFormat, MatchFormatShortNames } from "../../Home/constants";
const LazyBall = React.lazy(() => import('./StatsByCode/Ball'));

const Pages = {
  // Live: "live",
  Matchinfo: "matchinfo",
  Fantasy: "fantasy",
  Commentary: "commentary",
  Scorecard: "scorecard",
  LiveStats: "livestats",
  PointTable: "pointtable",
  SeriesStats: "seriesstats"
};

const PageForUpcomingMatch = {
  Matchinfo: "matchinfo",
  Fantasy: "fantasy",
  PointTable: "pointtable",
  SeriesStats: "seriesstats"
};

const LabelToName = {
  Commentary: "Commentary",
  Scorecard: "Scorecard",
  Highlights: "Highlights",
  Playing11: "Playing11",
  LiveStats: "Live Stats",
  PointTable: "Point Table",
  Squads: "Squads",
  News: "News",
  Fantasy: "Fantasy",
  Matchinfo: "Match Info",
  Videos: "Videos",
  SeriesStats: "Series Stats"
}
export const LiveMatch = ({ matchData, orderedTeams, renderCurrentRRLine, renderLiveMatchIcon, liveScore, chartData, tieChance }) => {
  const {
    subtitle,
    format,
    status_note,
    tournament_name,
    status,
    isShimmer,
    tournament_slug
  } = matchData;
  const classname = isShimmer ? "bg-gray-300 loading text-opacity-0" : "";
  const queryParams = new URLSearchParams(location.search);

  return (
    <>
      {/* Dekstop screen */}
      <div className="mt-4 p-3 md:p-0 md:block hidden">
        <div className="grid grid-cols-3 md:grid-cols-10 items-center md:justify-between">
          <div className="col-span-2 md:col-span-4">
            {orderedTeams.map((team, i) => (
              <div className="grid md:grid-cols-2 w-max md:w-auto grid-cols-5 md:m-2 m-1" key={i}>
                <div className="flex items-center md:col-span-1 col-span-2 md:gap-4 gap-2">
                  <ShimmerEffect className="h-[25px] w-[25px]" src={team?.logo} alt="" />
                  <p className="md:text-[16px] text-[14px]">{team?.short_name}</p>
                </div>
                {team && team.scores && (
                  <div className="flex md:col-span-1 col-span-3">
                    {renderLiveMatchIcon(i + 1 === matchData?.live_inning_number)}
                    <p>
                      <span className="font-medium">{team?.scores?.replace(/\/10/g, "")}</span>
                    </p>
                    {(i + 1 === matchData?.live_inning_number || matchData?.status === "Completed") && (
                      <p className="md:text-[12px] text-[10px] md:pl-1 h-max mt-1 text-[#808080]">
                        ({team?.overs}/{team?.max_overs} ov{liveScore?.target > 0 ? `, T:${liveScore?.target}` : ""})
                      </p>
                    )}
                  </div>
                )}
              </div>
            ))}
            <div>
              {renderCurrentRRLine()}
              <p className="font-bold md:mt-1 md:text-[16px] text-[12px]">
                {matchData?.status_note}
              </p>
            </div>
          </div>
          {
            !chartData[0].percentage && !chartData[1].percentage && <div className="md:col-span-3 hidden md:block"></div>
          }
          <div className="flex justify-center h-full items-center md:col-span-3">
            <div>
              <p className="">
                {/* {matchData?.game_state_str === "Play Ongoing" || matchData?.game_state_str === "Inning Break"
                  ? */}
                <span className="md:text-[30px] text-[25px] font-bold flex items-center justify-center">
                  <MatchDataBallEvent
                    key={`${matchData?.ball}_${matchData?.ball_code}`}
                    event={matchData?.ball}
                    code={queryParams.get("ball_event") || `ball_${matchData?.ball_code}`}
                  />
                </span>
                {/* :
                  (
                    matchData?.game_state_str === 'Default' ?
                      null :
                      <>
                        <span className="md:text-[30px] text-[25px] font-bold">Stumps</span>
                      </>
                  )
                } */}
              </p>
            </div>
          </div>
          {
            (chartData[0].percentage || chartData[1].percentage) &&
            <div className={`hidden md:block md:col-span-3`}>
              <div className="flex justify-end">
                <FakeLiveMatchChartBox data={chartData} tieChance={tieChance} />
              </div>
            </div>
          }
        </div>
      </div>
      {/* Mobile screen */}
      <div className="bg-white rounded-[18px] border border-[#E3E3E4] px-2 relative md:hidden ">
        <div className={`${classname} flex justify-between items-center mt-2`}>
          <p className={`text-[10px] text-gray-600 flex-grow live-match font-medium pl-1`}>
            {subtitle} • {""}
            <Link to={`/series/${tournament_slug}/schedule`}>
              <span className="text-[#6C6C6C] border-b border-[#6C6C6C] border-dashed">{tournament_name}</span>
            </Link>
          </p>
          <p className={`rounded-[40px] min-w-[33px] whitespace-nowrap text-[10px] px-2 ${getClassByFormat(format)}`}>
            {MatchFormatShortNames[format] || format}
          </p>
        </div>
        <div className="grid grid-cols-3 items-center border-b custom-border">
          <div className="col-span-2 md:col-span-4">
            {orderedTeams.map((team, i) => (
              <div className="grid w-max grid-cols-5 m-1" key={i}>
                <div className="flex items-center col-span-2 gap-2">
                  <div className="p-[2px] mr-0.5">
                    {!isShimmer && (
                      <ShimmerEffect
                        src={team?.logo}
                        alt={team?.name}
                        className="h-5 w-5 my-auto"
                      />
                    )}
                  </div>
                  <p className={`${classname} min-h-[17px] min-w-[28px] text-sm !leading-[17px] ${i + 1 !== matchData?.live_inning_number ? "text-[#6c6c6c]" : ""} ${team?.scores ? "font-medium" : "text-[#6C6C6C]"} mr-2`}>
                    {team?.short_name}
                  </p>
                </div>
                {team && team.scores && (
                  <div className="flex md:col-span-1 col-span-3">
                    {renderLiveMatchIcon(i + 1 === matchData?.live_inning_number)}
                    <span className={`text-[14px] leading-3 mt-[6px] font-bold ${i + 1 !== matchData?.live_inning_number ? "text-[#6c6c6c]" : ""}`}>
                      {team?.scores?.replace(/\/10/g, '')}
                    </span>
                    {(i + 1 === matchData?.live_inning_number || matchData?.status === "Completed") && (
                      <p className="md:text-[12px] text-[10px] md:pl-1 h-max mt-1 text-[#808080]">
                        ({team?.overs}/{team?.max_overs} ov{liveScore?.target > 0 ? `, T:${liveScore?.target}` : ""})
                      </p>
                    )}
                  </div>
                )}
              </div>
            ))}
            <div className={`"max-h-[40px] min-h-[40px] w-full text-[#797979] text-[10px] font-semibold py-2 overflow-hidden`}>
              <div className="leading-3 mb-1.5">
                {renderCurrentRRLine()}
              </div>
              <div className={`${classname} w-full live-match text-[#797979] text-[10px] font-semibold overflow-hidden leading-3`}>
                {status_note}
              </div>
            </div>
          </div>
          {
            !chartData[0].percentage && !chartData[1].percentage && <div className="md:col-span-3 hidden md:block"></div>
          }
          <div className="flex justify-center items-center w-5/6 md:col-span-3 min-h-[102px] min-w-[102px] max-h-[102px] max-w-[102px]">
            <div>
              <p className="">
                {/* {matchData?.game_state_str === "Play Ongoing" || matchData?.game_state_str === "Inning Break"
                  ? */}
                <span className="md:text-[30px] text-[25px] font-bold flex items-center justify-center">
                  <MatchDataBallEvent
                    key={`${matchData?.ball}_${matchData?.ball_code}`}
                    event={matchData?.ball}
                    code={queryParams.get("ball_event") || `ball_${matchData?.ball_code}`}
                  />
                </span>
                {/* :
                  (
                    matchData?.game_state_str === 'Default' ?
                      null :
                      <>
                        <span className="md:text-[30px] text-[25px] font-bold">Stumps</span>
                      </>
                  )
                } */}
              </p>
            </div>
          </div>
        </div>
        <div className="flex items-center text-sm my-2">
          <div className=" flex items-center">
            {!isShimmer && (
              <img className="mr-1" src={liveIcon} alt="live" />
            )}
            <span className={`${classname} min-w-[40px] text-green-600`}>{status}</span>
          </div>
          <div className="ml-auto text-[10px]">
            <Link
              to={`/series/${matchData.tournament_slug}/pointtable`}
              onClick={doScrollToTop}
              className={`${classname} min-h-[15px] min-w-[30px] mx-1 hover:text-[#3A32D1] text-[#6C6C6C] ${isShimmer ? '' : 'border-b border-[#6C6C6C] border-dashed'}`}>
              Table
            </Link>
            <Link
              to={`/series/${matchData.tournament_slug}/schedule`}
              className={`${classname} min-h-[15px] min-w-[30px] mx-1 hover:text-[#3A32D1] text-[#6C6C6C] ${isShimmer ? '' : 'border-b border-[#6C6C6C] border-dashed'}`}>
              Schedule
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

const CompletedMatch = ({ matchData, orderedTeams, liveScore }) => {
  const {
    subtitle,
    format,
    tournament_name,
    isShimmer,
    tournament_slug
  } = matchData;
  const classname = matchData.isShimmer ? "bg-gray-300 loading text-opacity-0" : "";
  return (
    <>
      {/* Dekstop screen */}
      <div className="md:block hidden">
        <div className="mt-4 md:py-2 flex flex-col md:flex-row md:items-center md:justify-between p-3 md:px-0">
          <div className="">
            <div>
              {orderedTeams.map((team, i) => (
                <div className="grid md:grid-cols-2 grid-cols-5 md:m-2 m-1" key={i}>
                  <div className="flex items-center md:col-span-1 col-span-2 md:gap-4 gap-2">
                    <ShimmerEffect className="h-[25px] w-[25px]" src={team?.logo} alt="" />
                    <p className="md:text-[16px] text-[14px]">{team?.short_name}</p>
                  </div>
                  {team?.scores && (
                    <div className="flex md:col-span-1 col-span-3">
                      <p>
                        <span className="font-medium">{team?.scores?.replace(/\/10/g, "")}</span>
                      </p>
                      <p className="md:text-[12px] text-[10px] md:pl-1 h-max mt-1 text-[#808080]">
                        ({team?.overs}/{team?.max_overs} ov{liveScore?.target > 0 ? `, T:${liveScore?.target}` : ""})
                      </p>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="font-bold md:text-[16px] mx-2 mt-1 text-[14px] whitespace-nowrap">
              {matchData?.status_note}
            </div>
          </div>
          <div className="md:border-l md:w-[250px] text-[12px] md:pl-4">
            {
              matchData?.man_of_the_match &&
              <div className="flex items-center md:ml-0 ml-2">
                <div className="border rounded-full w-11 h-11 text-lg font-semibold text-white flex justify-center items-center bg-[#D9D9D9]">{matchData.man_of_the_match[0]}</div>
                <div className="ml-2 whitespace-nowrap mx-4">
                  <p className="font-bold text-[#3BAA4E] leading-[14px]">Player of the Match:</p>
                  <p className="font-bold md:text-[16px] text-[14px] leading-[19px] my-0.5">
                    {matchData.man_of_the_match}
                    {/* <span className="font-normal">(IND)</span> */}
                  </p>
                  {/* <p className="font-bold text-[#929292]">3/18 & 2 catches</p> */}
                </div>
              </div>
            }
            {
              matchData?.man_of_the_series &&
              <div className="flex items-center md:ml-0 ml-2 mt-2">
                <div className="border rounded-full w-11 h-11 text-lg font-semibold text-white flex justify-center items-center bg-[#D9D9D9]">{matchData.man_of_the_series[0]}</div>
                <div className="ml-2 whitespace-nowrap mx-4">
                  <p className="font-bold text-[#3BAA4E] leading-[14px]">Player of the Series:</p>
                  <p className="font-bold md:text-[16px] text-[14px] leading-[19px] my-0.5">
                    {matchData.man_of_the_series}
                    {/* <span className="font-normal">(IND)</span> */}
                  </p>
                  {/* <p className="font-bold text-[#929292]">3/18 & 2 catches</p> */}
                </div>
              </div>
            }
          </div>
        </div>
      </div>

      {/* Mobile screen */}
      <div className="bg-white rounded-2xl border border-[#E3E3E4] px-2 relative md:hidden">
        <div className={`${classname} flex justify-between items-center mt-2`}>
          <p className="text-[10px] text-gray-600 flex-grow live-match font-medium pl-1">
            {subtitle} • {" "}
            <Link to={`/series/${tournament_slug}/schedule`}>
              <span className="text-[#6C6C6C] border-b border-[#6C6C6C] border-dashed">{tournament_name}</span>
            </Link>
          </p>
          <p className={`rounded-[40px] max-w-[150px] whitespace-nowrap text-[10px] px-2 ${getClassByFormat(format)}`}>
            {MatchFormatShortNames[format] || format}
          </p>
        </div>
        {orderedTeams.map((team, i) => (
          <div data-teamid={team.team_id} key={i} className="flex items-center pb-1 pt-0.5 text-[#6c6c6c]">
            <div className="flex items-center w-[120px]">
              {!isShimmer && (
                <ShimmerEffect
                  src={team?.logo}
                  alt={team?.name}
                  className="h-6 w-6 my-auto p-[2px] mr-2"
                />
              )}
              <p className={`${classname} min-h-[17px] min-w-[28px] text-sm !leading-[17px] ${team?.scores ? "font-medium" : "text-[#6C6C6C]"} mr-2`}>
                {team?.short_name}
              </p>
            </div>
            <div className={`${classname} min-h-[16px] min-w-[83px] flex items-center whitespace-nowrap`}>
              <span className="text-[14px] leading-4 font-bold ml-4">
                {team?.scores?.replace(/\/10/g, '')}
              </span>
              {team.scores && (
                <span className="text-[10px] text-[#808080] ml-1">
                  ({team?.overs}/{team?.max_overs} ov{liveScore?.target > 0 ? `, T:${liveScore?.target}` : ""})
                </span>
              )}
            </div>
          </div>
        ))}
        <div className="custom-border border-b">
          <p className={`${classname} max-h-[20px] min-h-[20px] w-full live-match text-[10px] font-semibold overflow-hidden text-[#797979]`}>
            {matchData.status_note}
          </p>

        </div>
        <div className="flex items-center text-sm my-2">
          <span className={`${classname} min-h-[20px] min-w-[40px] text-green-600`}>{matchData.status}</span>
          <div className="ml-auto text-[10px]">
            <Link
              to={`/series/${matchData.tournament_slug}/pointtable`}
              onClick={doScrollToTop}
              className={`${classname} min-h-[15px] mx-1 hover:text-[#3A32D1] text-[#6C6C6C] ${isShimmer ? '' : 'border-b border-[#6C6C6C] border-dashed'}`}>
              Table
            </Link>
            <Link
              to={`/series/${matchData.tournament_slug}/schedule`}
              className={`${classname} min-h-[15px] mx-1 hover:text-[#3A32D1] text-[#6C6C6C] ${isShimmer ? '' : 'border-b border-[#6C6C6C] border-dashed'}`}>
              Schedule
            </Link>
          </div>
        </div>
      </div>
      {(matchData?.man_of_the_match || matchData?.man_of_the_series) ? <div className="mt-4 bg-white px-[14px] py-3 rounded-[18px] border md:hidden">
        {
          matchData?.man_of_the_match &&
          <div className={`flex items-center ${matchData?.man_of_the_series ? "pb-3" : ""}`}>
            <div className="border rounded-full w-10 h-10 text-lg font-semibold text-white flex justify-center items-center bg-[#D9D9D9]">{matchData.man_of_the_match[0]}</div>
            <div className="ml-2 whitespace-nowrap mx-4">
              <p className="font-bold text-[#3BAA4E] text-[9px] leading-[8px]">Player of the Match</p>
              <p className="font-bold text-[14px] leading-4 my-1.5">
                {matchData.man_of_the_match}
              </p>
            </div>
          </div>
        }
        {
          matchData?.man_of_the_series &&
          <div className="flex items-center pt-3 border-t">
            <div className="border rounded-full w-10 h-10 text-lg font-semibold text-white flex justify-center items-center bg-[#D9D9D9]">{matchData.man_of_the_series[0]}</div>
            <div className="ml-2 whitespace-nowrap mx-4">
              <p className="font-bold text-[#3BAA4E] text-[9px] leading-[8px]">Player of the Series</p>
              <p className="font-bold text-[14px] my-0.5">
                {matchData.man_of_the_series}
              </p>
            </div>
          </div>
        }
      </div> : null }
    </>
  );
};

const ScheduledMatch = ({ matchData, orderedTeams }) => {
  const {
    isShimmer,
  } = matchData;
  const classname = matchData.isShimmer ? "bg-gray-300 loading text-opacity-0" : "";

  return (
    <>
      {/* Dekstop screen */}
      <div className="mt-4 md:pb-4 p-3 md:p-0 md:block hidden">
        <div className="grid md:grid-cols-3 grid-cols-2 items-center md:justify-between">
          <div className="md:col-span-2 md:grid md:grid-cols-2 md:items-center md:justify-between">
            <div>
              {orderedTeams.map((team, i) => (
                <div className="grid md:grid-cols-2 grid-cols-5 md:m-2 m-1" key={i}>
                  <div className="flex items-center md:col-span-1 col-span-2 md:gap-4 gap-2">
                    <ShimmerEffect className="h-[25px] w-[25px]" src={team?.logo} alt="" />
                    <p className="md:text-[16px] text-[14px]">{team?.short_name}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="md:mx-4 mx-2 md:mt-0 mt-2 md:text-center">
              <p className={`font-bold md:mt-8 md:text-[16px] text-[14px] ${matchData?.status === "Cancelled" ? 'font-bold' : 'text-[#000000]'} text-opacity-40 whitespace-nowrap`}>
                {matchData?.status === "Cancelled" ? matchData?.status_note : matchData?.game_state_str}
              </p>
            </div>
          </div>
          <p className="text-right md:mx-0 mx-2">
            <span className="text-xs text-gray-500">{matchData?.dateStrings?.string1}</span> <br />
            <span className="md:text-[30px] text-[25px] font-bold">{matchData?.dateStrings?.string2}</span>
          </p>
        </div>
      </div>
      {/* Mobile screen */}
      <div className="bg-white rounded-2xl border border-[#E3E3E4] px-2 relative md:hidden">
        <div className={`${classname} flex justify-between items-center mt-2`}>
          <p className="text-[10px] text-gray-600 flex-grow live-match font-medium pl-1">
            {matchData.subtitle} • {" "}
            <Link to={`/series/${matchData.tournament_slug}/schedule`}>
              <span className="text-[#6C6C6C] border-b border-[#6C6C6C] border-dashed">{matchData.tournament_name}</span>
            </Link>
          </p>
          <p className={`rounded-[40px] max-w-[150px] whitespace-nowrap text-[10px] px-2 ${getClassByFormat(matchData.format)}`}>
            {MatchFormatShortNames[matchData.format] || matchData.format}
          </p>
        </div>
        {orderedTeams.map((team, i) => (
          <div data-teamid={team.team_id} key={i} className="flex items-center pb-1 pt-0.5">
            <div className="flex items-center w-[120px]">
              {!isShimmer && (
                <ShimmerEffect
                  src={team?.logo}
                  alt={team?.name}
                  className="h-6 w-6 my-auto p-[2px] mr-2"
                />
              )}
              <p className={`${classname} min-h-[17px] min-w-[28px] text-sm !leading-[17px] ${team?.scores ? "font-medium" : "text-[#6C6C6C]"} mr-2`}>
                {team?.short_name}
              </p>
            </div>
          </div>
        ))}
        <div className="text-right absolute top-0 right-0 mt-6 mr-2">
          <p className={`${classname} min-h-[40px] min-w-[60px]`}>
            <span className="text-xs text-gray-500">{matchData.dateStrings?.string1}</span> <br />
            <span className="text-[18px] font-bold">{matchData.dateStrings?.string2}</span>
          </p>
        </div>
        <div>
          <p className={`${classname} max-h-[20px] min-h-[20px] w-full live-match text-[#797979] text-[10px] custom-border border-b font-semibold overflow-hidden`}>
            {matchData.game_state_str || "-"}
          </p>
        </div>
        <div className="flex items-center text-sm my-2">
          <div className=" flex items-center">
            <span className={`${classname} min-w-[40px] text-green-600`}>{matchData.status}</span>
          </div>
          <div className="ml-auto text-[10px]">
            <Link
              to={`/series/${matchData.tournament_slug}/pointtable`}
              onClick={doScrollToTop}
              className={`${classname} min-h-[15px] min-w-[30px] mx-1 hover:text-[#3A32D1] text-[#6C6C6C] ${isShimmer ? '' : 'border-b border-[#6C6C6C] border-dashed'}`}>
              Table
            </Link>
            <Link
              to={`/series/${matchData.tournament_slug}/schedule`}
              className={`${classname} min-h-[15px] min-w-[30px] mx-1 hover:text-[#3A32D1] text-[#6C6C6C] ${isShimmer ? '' : 'border-b border-[#6C6C6C] border-dashed'}`}>
              Schedule
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

const Matches = () => {
  const { seriesId: seriesSlug, matchesId: matchSlug, type } = useParams();
  const [schedulePageType, setSchedulePageType] = useState(type);
  const [seoMeta, setSEOMeta] = useState(null);
  const containerRef = useRef(null);
  const selectedRef = useRef(null);
  const queryParams = new URLSearchParams(location.search);
  const isSticky = queryParams.get('sticky') === 'true';
  useEffect(() => {
    setSchedulePageType(type);
  }, [type]);

  useHorizontalScroll(containerRef, schedulePageType);
  const { matchId } = getSeriesMatchID(seriesSlug, matchSlug);
  const [refreshInterval, setRefreshInterval] = useState(0);
  // const queryParams = new URLSearchParams(location.search);
  // const showPlayerDetails = queryParams.get('showPlayerDetails') === 'true';

  const renderComponent = useCallback(() => {
    switch (type) {
      case Pages.Live:
        return <Live />;
      case Pages.Scorecard:
        return <Scorecard />;
      case Pages.Commentary:
        return <Commentary />;
      case Pages.Highlights:
        return <Highlights />;
      case Pages.PointTable:
        return <PointTable />;
      case Pages.LiveStats:
        return <LiveStats />;
      case PageForUpcomingMatch.Squads:
        return <Playing11 isSquad="true" />;
      case Pages.Playing11:
        return <Playing11 isSquad="false" />;
      case Pages.News:
        return <News />;
      case Pages.Fantasy:
        return <Fantasy2 />;
      case Pages.Matchinfo:
        return <MatchInfo />;
      case Pages.Videos:
        return <Video />;
      case Pages.SeriesStats:
        return <SeriesStats />;
      default:
        return <Navigate to={`/series/${seriesSlug}/matches/${matchSlug}/matchinfo`} />;
    }
  }, [type]);

  const { data, error, isLoading } = useAxiosSWR(`v1/matches/${matchId}`, {
    refreshInterval,
  });
  const LiveMatchStatus = data && data?.match?.status === "Live";

  useEffect(() => {
    if (LiveMatchStatus) {
      setRefreshInterval(5000);
    } else {
      setRefreshInterval(0);
    }
  }, [LiveMatchStatus]);

  useEffect(() => {
    if (data?.match) {
      const matchData = data.match;
      let title = [`${matchData?.title}, ${matchData?.subtitle}`];
      title.push(formatDate(matchData?.date_start_utc, false));
      title.push(matchData?.status_note);
      title.push('Cricketgully');
      setSEOMeta({
        title: title.join(' | '),
        description: `${matchData?.title} ${matchData?.status_note}, ${matchData?.subtitle} ${formatDate(matchData?.date_start_utc, false)}`,
        canonicalURL: `/series/${matchData?.tournament_slug}/matches/${matchData?.slug}/matchinfo`
      });
    }
  }, [data]);
  const matchData = data?.match;
  const liveScore = data?.info?.live_score;
  const teamA = matchData?.teama;
  const teamB = matchData?.teamb;

  const PagesArray = useMemo(() => {
    const basePages = Object.keys(
      matchData?.status === "Scheduled" ? PageForUpcomingMatch : Pages
    );

    if (matchData?.tournament_type !== "tournament") {
      return basePages.filter(page => page !== "PointTable");
    }

    return basePages;
  }, [matchData]);
  const appContext = useContext(AppContext);
  const { pathname: pathName } = useLocation();
  useEffect(() => {
    const urlInfoObj = {
      type: URL_TYPES.MATCH_INSTANCE,
      path: pathName,
      reqState: {
        data: {
          teamAName: teamA?.short_name,
          teamBName: teamB?.short_name
        },
      }
    };
    appContext.updateUrlInfo(urlInfoObj);
  }, [pathName, teamA, teamB]);


  const chartData = useMemo(() => {
    if (!teamA || !teamB) {
      return []
    }
    return [teamA, teamB].map(t => ({
      logoUrl: t.logo,
      name: (t.short_name + "").slice(0, 3),
      percentage: t.win_per ? getSanitizedWinPer(t.win_per) : null,
    }))
  }, [teamA, teamB]);
  if (isLoading) {
    return <div className="min-h-screen"> </div>;
  }

  if (error) {
    return (
      <h2></h2>
    );
  }

  const orderedTeams = getRightOrder(matchData?.teama, matchData?.teamb);
  const renderLiveMatchIcon = (index) => {
    if (index + 1 == matchData?.live_inning_number) {
      return <img src={liveIcon} className=" h-2 mt-2 mr-1" />;
    } else {
      return <span className="ml-2">&nbsp;</span>;
    }
  };
  const renderCurrentRRLine = () => {
    if (matchData?.status === "Completed") {
      return null;
    }
    const arr = [];
    liveScore?.runrate && liveScore?.runrate > 0
      ? arr.push(`Current RR: ${liveScore?.runrate}`)
      : "";
    liveScore?.required_runrate && liveScore?.required_runrate > 0
      ? arr.push(`Required RR: ${liveScore?.required_runrate}`)
      : "";
    // if (matchData?.last_five_overs) {
    //   let last5 = `${matchData?.last_five_overs}`.split(" ");
    //   if (last5.length > 1) {
    //     arr.push(`Last 5 ov (RR): ${last5[0]} (${last5[1]})`);
    //   }
    // }
    return (
      <div className="text-[#808080] text-[10px] md:text-[14px]">
        {arr.join(" • ")}
      </div>
    );
  };

  const renderMatchStatus = () => {
    switch (matchData?.status) {
      case "Live":
        return <LiveMatch chartData={chartData} matchData={matchData} tieChance={matchData?.tie_per} orderedTeams={orderedTeams} renderCurrentRRLine={renderCurrentRRLine} renderLiveMatchIcon={renderLiveMatchIcon} liveScore={liveScore} />;
      case "Completed":
        return <CompletedMatch matchData={matchData} orderedTeams={orderedTeams} liveScore={liveScore} />;
      case "Scheduled":
      default:
        return <ScheduledMatch matchData={matchData} orderedTeams={orderedTeams} />;
    }
  };

  return (
    <div className="">
      {seoMeta && <SEOMeta data={seoMeta} />}
      <div className="md:mt-4">
        <div className="md:my-4">
          <div className="bg-[#FFFFFF] md:mx-5 md:rounded-[20px] border rounded-2xl md:pl-[30px] md:p-6 p-4 hidden md:block">
            <h2 className="md:text-[24px] text-[20px] font-bold md:leading-8 leading-6">
              {matchData?.title}, {matchData?.subtitle}
            </h2>
            <div className="md:flex text-[14px] gap-11">
              <p className="text-[14px] text-[#646464] leading-5 pt-2">{matchData?.date_start}</p>
              <Link to={`/series/${matchData?.tournament_slug}/schedule`}>
                <p className="text-[14px] text-[#646464] leading-5 pt-2">
                  Series: <span className="text-[#000000] font-semibold">{matchData?.tournament_name}</span>
                </p>
              </Link>
              <p className="text-[14px] text-[#646464] leading-5 pt-2">Venue: {matchData?.venue}</p>
            </div>
          </div>
        </div>
        <div className="">
          <div className="">
            <div className="">
              <div className={`md:p-5 md:rounded-2xl md:border md:bg-white md:mx-5 bg-[#312AB7] pb-5 ${isSticky ? "sticky md:static top-[48px] z-40" : ""} `}>
                <div className="md:border overflow-x-auto max-w-max hideScrollbar md:rounded-xl" ref={containerRef}>
                  <div className="flex border-b border-[#3A32D1] md:border-none text-sm w-max cursor-pointer md:p-[1px]">
                    {PagesArray.map((type) => (
                      <Link
                        to={`/series/${matchData?.tournament_slug}/matches/${matchData?.slug}/${type.toLowerCase()}`}
                        key={type}
                        data-testid={type.toLowerCase()}
                      >
                        <div
                          ref={type.toLowerCase() === schedulePageType ? selectedRef : null}
                          className={`md:px-9 px-4 py-2.5 text-white uppercase md:normal-case ${schedulePageType === type.toLowerCase() ? "text-white md:bg-blue-700 md:rounded-xl p-1 font-medium md:border-none border-b-[3px]" : "md:text-black"}`}
                          onClick={() => {
                            setSchedulePageType(type.toLowerCase());
                          }}
                        >
                          {LabelToName[type] || type}
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
                <div className="mx-5 md:mx-0 mt-4">
                  {renderMatchStatus()}
                </div>
                {/* <div className="pb-4 md:text-base text-xs"></div> */}
              </div>
              {matchData?.status === "Live" && (
                <div className="md:hidden bg-white mx-[6px] mt-5 rounded-xl border border-[#DAE6F8] py-2">
                  <FakeLiveMatchChartBox data={chartData} type={schedulePageType} />
                </div>
              )}
              {renderComponent()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Matches;

const MatchDataBallEvent = ({ event, code }) => {
  const [lazyState, setLazyState] = useState({
    animationData: null,
    error: null,
    isLoading: true,
  })

  React.useEffect(() => {
    let isMounted = true;
    const loadAnimationData = async () => {
      try {
        const data = await import(`./lotties/${code}.json`);
        if (isMounted) {
          setLazyState({
            animationData: data.default,// use `data.default` because of the ES module import
            error: null,
            isLoading: false,
          })
        }
      } catch (error) {
        console.error("Error loading JSON:", error);
        setLazyState({
          animationData: null,
          error,
          isLoading: false,
        })
      }
    };

    if (code) {
      loadAnimationData();
    }

    return () => {
      isMounted = false;
    };
  }, [code]);

  const { isLoading, animationData, error } = lazyState;

  if (isLoading) {
    return null;
  }

  if (animationData && !error) {
    return (
      <div data-testid="ball_event_image" className="md:w-1/2">
        <React.Suspense>
          <LazyBall code={code} animationData={animationData} />
        </React.Suspense>
      </div>
    );
  }

  return <div className="md:mx-0 mx-1">{event}</div>;
};