import React from 'react'
import SEOMeta from './Home/common/SEOMeta'

const CorrectionPolicy = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <SEOMeta slug="privacy"/>
      <h1 className="font-bold mb-8">Correction Policy</h1>
      <div className="bg-white rounded-lg shadow-md p-8 text-justify">
        <div className='mb-4'>
          <p className="mb-4">
            CricketGully.com (hereinafter called “CricketGully” or “We” (hereinafter referred to as “CricketGully” / “Us” (hereinafter referred to as “CricketGully” / “Us “Our”). We’d be pleased to inform you via this Corrections Policy regarding the measures we take when we discover that certain incorrect information was published on our website. We are aware of the responsibility we owe to our users and are fully aware of our responsibility of us to correct the error and inform you about it when we can.</p>
        </div>

        <div className='mb-4'>
          <h4 className="font-semibold px-2 mb-4 text-blue-900 border rounded-lg bg-gray-100">Conclusion</h4>
          <p className='mb-4'>CricketGully strives to give its readers accurate and complete news stories based on all the information available at the time we are presenting the news. We strive to be prompt and quick to correct any errors in the information published on Our website when it comes to our notice. We, as a digital platform offer a correction as well as a clarification on our social media profiles or post an editor’s notes describing the changes made and the nature of the error in detail, apart from updating the content. In the event of a serious error, which could result in disseminating misinformation, we apologize to our readers in a prompt and timely way. Updates to a Report We have a habit of noting down stories when the story has been revised. It is crucial to Us to include corrections, clarifications or editor’s notes in order to inform readers when we correct an error of a major nature or alter the story to include any comments, changes, or updates from the stakeholder. If an error is discovered during Our fact-checking (which is carried out in accordance with our Fact-Check Policy which can be found in “Fact-Check Policy” The Policy on Fact-Checking) and after it is brought to our attention, we modify the article to reflect the change and at the end of each article we include in bold “[Correction Correction: Updated Info] …]” and describe the changes we that was made. We also ask our readers to report the correction to contact@cricketgully.com as well as at the end of each article, we give readers the option to suggest corrections in comments if they are needed. Corrections If we are serious about correcting an article, caption for a photo headline video, graphic or any other type of material, we promptly release a correction that explains the reason for the modification.</p>
        </div>

        <div className='mb-4'>
          <h4 className='font-semibold px-2 mb-4 text-blue-900 border rounded-lg bg-gray-100'>Clarification</h4>
          <p>When our journalism is accurate, however, the language we used to explain the facts isn’t as precise or as precise as it should be, the text is revised and clarification is added to the article. Clarification is also used to indicate if we initially did not seek a response or response which has now been included in the article or if the news reporting has changed the story of an incident.</p>
        </div>
        <div className='mb-4'>
          <h4 className='font-semibold px-2 mb-4 text-blue-900 border rounded-lg bg-gray-100'>Editor’s Notes</h4>
          <p>Any correction that puts into doubt the entirety of an essay or raises an important ethical dilemma could warrant an editor’s note. This is followed by a description of the issue. An editor’s supervisor approves adding an editor’s note to an article.</p>
        </div>
        <div className='mb-4'>
          <h4 className='font-semibold px-2 mb-4 text-blue-900 border rounded-lg bg-gray-100'>Other Corrections Policies</h4>
          <p>If an error is discovered by a user and is posted in the comments stream, our community engagement team can state in the comments that the error is now fixed. When we post incorrect information on social media we correct the error on the platform. We remove the incorrect information when we are unable to modify the information. We do not assign blame to editors or reporters (e.g. “because of a mistake in reporting” (or “because the editor made a mistake”). Inaccuracy in reporting If you think a report we’ve published is incorrect Please contact our editorial team through the comments section at the end of each web-based article when it is posted. You can also mail us at contact@cricketgully.com</p>
        </div>
        <div className='mb-4'>
          <h4 className="font-semibold px-2 mb-4 text-blue-900 border rounded-lg bg-gray-100">Requests to Take Down</h4>
          <p className='mb-4'>As a matter of our editorial policy, we are not able to accept requests for take-downs. If the person who wrote the story claims that the article was incorrect We are willing to examine the issue and, if needed, publish an update. When fairness demands an update or follow-up of Our previous coverage, we are obliged to consider whether additional editorial action is necessary, but not to take the story down as if it was never published. When we publish publicly accessible personal data, we will only look into takedown requests if the individual involved is in danger of physical harm as a result of the existence of such information.</p>
        </div>
      </div>
    </div >
  )
}

export default CorrectionPolicy