import React from 'react';
const LazyChart = React.lazy(() => import('react-apexcharts'));

const ApexChart = ({ data, type, plotOptions }) => {
    const options = {
        xaxis: data?.xaxis,
        yaxis: data?.yaxis,
        colors: ['#3A32D1', '#889BB0'],
    };
    if (plotOptions) {
        options['plotOptions'] = plotOptions;
    }
    const series = data?.series;

    if (!data)
        return '';
    else
        return (
            <React.Suspense fallback={<button className="bg-[#ECECEC] rounded-xl py-3"></button>}>
                <LazyChart options={options} series={series} type={type} />
            </React.Suspense>
        );

};
export default ApexChart;
