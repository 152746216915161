import React from "react";
import CommonPointTable from "../../../common/PointTable";
import { useAxiosSWR } from "root/axiosInstance";
import { getId } from "./helper";
import { useParams } from "react-router-dom";
import { getSanitizedArray } from "../../../../helpers/general";
import SEOMeta from "../../Home/common/SEOMeta";
import { useState } from "react";
import { useEffect } from "react";
import { formatDate } from "../../../../helpers/dates";

const SeriesPointTable = () => {
  const { id: slug } = useParams();
  const [seoMeta, setSEOMeta] = useState(null);
  const seriesId = getId(slug);
  const reqObj = useAxiosSWR(`/v1/series/${seriesId}/pointtable`);
  const { data, error, isLoading } = reqObj;
  const pointTable = getSanitizedArray(data?.groups);
  useEffect(() => {
    if (data?.series) {
      const seriesData = data?.series;
      seriesData.game_format = seriesData?.game_format || "";
      setSEOMeta({
        title: `${seriesData?.title} ${seriesData?.season} Points Table | ${seriesData?.game_format.toUpperCase()} | ${seriesData?.abbr} | Cricketgully`,
        description: `Points table of ${seriesData?.title} ${seriesData?.season} scheduled on ${formatDate(seriesData?.date_start, false)} to ${formatDate(seriesData?.date_end, false)} | ${seriesData?.game_format.toUpperCase()} | ${seriesData?.abbr}`,
        canonicalURL: `/series/${seriesData?.slug}/schedule`
      });
    }
  }, [data]);
  if (isLoading) {
    return <div>Loding...</div>;
  }
  if (error) {
    return <div></div>;
  }
  return (
    <div className="md:bg-white md:rounded-2xl md:border md:overflow-auto md:p-5 px-1">
      <div className="">
        {seoMeta && <SEOMeta data={seoMeta} />}
        {pointTable.length > 0 ? (
          <CommonPointTable data={data} />
        ) : (
          <div>
            No Data Found
          </div>
        )}
      </div>
    </div>
  );
};

export default SeriesPointTable;
