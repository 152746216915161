import one from "../../../.././Assets/seriesStats/one.svg"
import two from "../../../.././Assets/seriesStats/two.svg"
import three from "../../../.././Assets/seriesStats/three.svg"
import four from "../../../.././Assets/seriesStats/four.svg"
import five from "../../../.././Assets/seriesStats/five.svg"
import six from "../../../.././Assets/seriesStats/six.svg"
import seven from "../../../.././Assets/seriesStats/seven.svg"
import eight from "../../../.././Assets/seriesStats/eight.svg"
import nine from "../../../.././Assets/seriesStats/nine.svg"
import ten from "../../../.././Assets/seriesStats/ten.svg"
import { useHorizontalScroll } from '../../../.././hooks/useHorizontalScroll'
import { useRef, useState } from "react"
import { useAxiosSWR } from "../../../../../axiosInstance"

const tabName = [
    {
        value: "batting_most_runs",
        name: "Most Runs"
    },
    {
        value: "batting_highest_strikerate",
        name: "Best Striker Rate"
    },
    {
        value: "bowling_top_wicket_takers",
        name: "Most Wickets"
    },
    {
        value: "bowling_best_economy_rates",
        name: "Best Economy Rate"
    }
]
const number = {
    1: one,
    2: two,
    3: three,
    4: four,
    5: five,
    6: six,
    7: seven,
    8: eight,
    9: nine,
    10: ten
}

const valueToShow = {
    batting_most_runs: "runs",
    bowling_top_wicket_takers: "wickets",
    bowling_best_economy_rates: "econ",
    batting_highest_strikerate: "strike"
}

const subtitle = {
    "batting_most_runs": "Runs",
    "batting_highest_strikerate": "SR",
    "bowling_top_wicket_takers": "Wickets",
    "bowling_best_economy_rates": "Econ",
}
const SeriesStatsCommon = ({ seriesId, type = '' }) => {
    const [selectedTab, setSelectedTab] = useState("batting_most_runs");
    const containerRef = useRef();
    useHorizontalScroll(containerRef, selectedTab, { type });
    const {
        data,
        error: isError,
        isLoading,
      } = useAxiosSWR(`v1/series/${seriesId}/stats`);

      if(isLoading){
        return <div></div>
      }
      if(isError){
        return <div>somthing went wrong...</div>
      }

      if (!data) {
        return null;
      }
    return (
        <div className={`${type === 'fantasy' ? '' : 'border border-[#DAE6F8] rounded-xl'} bg-white`}>
            {type === 'fantasy' ? '' :
                <div>
                    <h1 className='md:text-[20px] text-[16px] md:text-start text-center leading-[20px] font-bold p-[14px] md:bg-[#E8F1FF] md:border-b border-[#DAE6F8] rounded-t-xl'>Players Stats <div className="text-[10px] md:text-[20px] md:text-black text-[#909090] md:font-bold md:inline-block font-medium"> in current series</div></h1>
                </div>
            }
            <div className={`${type === 'fantasy' ? 'px-4 pb-4 md:p-4' : 'md:p-4 px-2 pb-4'}`}>
                <div ref={containerRef} className='overflow-x-auto hideScrollbar'>
                    <div className='md:grid md:grid-cols-4 flex text-nowrap gap-2 md:gap-4 text-center'>
                        {
                            tabName?.map(({ value, name }) => (
                                <div data-testid={value} key={value} className={`cursor-pointer rounded-lg border md:text-base text-[10px] px-3 md:px-0 py-[10px] leading-[10px] md:leading-6 md:py-0.5 ${selectedTab === value ? "bg-[#3A32D1] text-white botder-[#3A32D1]" : ""}`} onClick={() => setSelectedTab(value)}>{name}</div>
                            ))
                        }
                    </div>
                    <div className="bg-gradient-to-r from-[#DAE6F800] via-[#DAE6F8] mt-4 to-[#DAE6F800] h-[1px] md:hidden">
                    </div>  
                </div>
                <div className='grid gap-4 md:grid-cols-2 mt-4'>
                    {data[selectedTab]?.filter(o => o.title)?.map((object, index) => (
                        object.title ? 
                        <div key={object.pid} className='flex md:gap-3 gap-2 items-center border border-[#DAE6F8] rounded-xl p-2'>
                            <div>
                                <img src={number[index + 1]} alt="image" className='md:h-[50px] h-[34px] w-[44px] md:w-[60px] mt-2 md:mt-4' />
                            </div>
                            <div className='md:h-[55px] md:w-[55px] h-[38px] w-[38px] text-white flex justify-center items-center bg-[#D9D9D9] rounded-full'>
                                {object.title[0]}
                            </div>
                            <div>
                                <div className='font-semibold text-xs md:text-base live-match'>
                                    {object.title}
                                </div>
                                <div className='md:text-xs text-[10px] text-[#929292] leading-[12px] live-match md:max-w-[120px] max-w-[100px]'>
                                    {object.team_name}
                                </div>
                            </div>
                            <div className='text-end ml-auto'>
                                <div className='text-[#3A32D1] font-semibold text-xs md:text-base'>
                                    {object[valueToShow[selectedTab]]} {subtitle[selectedTab]}
                                </div>
                                <div className='md:text-xs text-[10px] text-[#929292] leading-[12px]'>
                                    {object.matches} Matches
                                </div>
                            </div>
                        </div>
                        : null
                    ))}
                </div>
            </div>
        </div>
    );
}


export default SeriesStatsCommon