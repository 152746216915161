import React from 'react';
import { useAxiosSWR } from "../../../axiosInstance";
import { ShimmerEffect } from "./ShimmerEffect";
// import ads from "../../Assets/250_250.png";

const AdSquare = ({campaign}) => {
    const className = campaign?.includes("square") ? "min-h-[250px] " : "md:h-[90px] h-[50px] "
    const { data: data, isLoading, error: isError } = useAxiosSWR(campaign ? `/v1/ads/${campaign}` : null);
    // const shimmerClassName = isLoading ? "bg-gray-300 loading" : ""
    // const src = isLoading ? "" : data?.image

    if (isLoading) {
        return <div className={className}></div>;
    }

    return (
        <div className={`flex justify-center items-center ${className}`}>
            <a href={data?.target || "/"} target='_blank' rel="nofollow">
                <ShimmerEffect src={data?.image} alt="ads" className={`${className} w-auto`} />
            </a>
        </div>
    );
}
export default React.memo(AdSquare);