import React, { useEffect, useState, useRef, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../Assets/logo.png";
import "./navbar.css";
import { doScrollToTop } from "../../helpers/general";
import livescore from "../../Assets/navbar/livescore.svg";
import pointtable from "../../Assets/navbar/pointtable.svg";
import teams from "../../Assets/navbar/teams.svg";
import ranking from "../../Assets/navbar/ranking.svg";
import instagram from '../../Assets/instagram.svg';
import facebook from '../../Assets/facebook.svg';
import youtube from '../../Assets/youtube.svg';
import twitter from '../../Assets/x(twitter).svg';
import AppContext, { URL_TYPES } from "src/context";

const linksForMobile = [
    { label: "Live Score", icon: livescore, target: "/schedule/live" },
    { label: "Points Table", icon: pointtable, target: "/points-table" },
    { label: "Teams", icon: teams, target: "/teams" },
    { label: "Ranking", icon: ranking, target: "/ranking" },
];

const MobileTopNavbar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const pathname = location.pathname.split("/")[1];
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [moreDropdownOpen, setMoreDropdownOpen] = useState(false);
    const cGullyLoginTokenFromStorage = localStorage.getItem("cGullyLoginToken");
    const dropdownRef = useRef(null);
    const [navbarTitleState, setNavbarTitleState] = useState("");

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setMoreDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    useEffect(() => {
        if (menuIsOpen) {
            document.body.classList.add("overflow-hidden");
        } else {
            document.body.classList.remove("overflow-hidden");
        }
    }, [menuIsOpen]);

    const handleCloseMenu = () => {
        setMenuIsOpen(false);
    };

    const handleLoginClick = () => {
        if (cGullyLoginTokenFromStorage) {
            navigate("/user-profile");
        } else {
            navigate("/login");
        }
    };
    const handalBack = () => {
        try {
            // Check if there is a referrer or history length to go back
            if (document.referrer || window.history.state) {
                navigate(-1);
            } else {
                navigate("/home");
            }
        } catch (error) {
            console.error("Error while navigating:", error);
            navigate("/home"); // fallback to home if an error occurs
        }
    };
    const { state: { urlInfoByKey } } = useContext(AppContext);
    const { pathname: pathName } = useLocation();
    const currnetUrlInfo = urlInfoByKey[pathName] || {};
    const navbarTitle = {
        SCHEDULE_LIVE: 'Schedule',
        SCHEDULE_RESULT: 'Schedule',
        SCHEDULE_UPCOMING: 'Schedule',
        NEWS_HOME: 'Cricket News',
        FANTASY_CATEGORY: 'Fantasy Tips',
        SERIES_HOME: 'Series',
        RANKING: 'Ranking',
        POINT_TABLE: 'Points Table',
        TEAMS_HOME: 'Teams',
    };

    useEffect(() => {
        if (currnetUrlInfo?.type && navbarTitle[currnetUrlInfo.type]) {
            setNavbarTitleState(navbarTitle[currnetUrlInfo.type]);
        } else if (currnetUrlInfo?.reqState?.data?.title) {
            setNavbarTitleState(currnetUrlInfo.reqState.data.title);
        } else if (currnetUrlInfo?.reqState?.data?.teamAName && currnetUrlInfo?.reqState?.data?.teamBName) {
            setNavbarTitleState(`${currnetUrlInfo.reqState.data.teamAName} vs ${currnetUrlInfo.reqState.data.teamBName}`);
        } else if (currnetUrlInfo?.type === "HOME") {
            setNavbarTitleState("")
        }
    }, [currnetUrlInfo]);

    return (
        <nav>
            {menuIsOpen && <div className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-50" onClick={handleCloseMenu}></div>}
            <div className={`transition-all overflow-hidden absolute md:hidden top-0 text-nowrap duration-300 ease-in-out w-[80%] ${menuIsOpen ? "ml-0" : "-ml-[80%]"} z-50`}>
                <div className="">
                    <div className="h-[52px] flex items-center justify-between bg-blue-700">
                        <p className="text-2xl font-bold text-white pl-8">Menu</p>
                        <button onClick={() => setMenuIsOpen(false)} className="text-white focus:outline-none pr-4">
                            <svg className="h-6 w-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                <path d="M6 18L18 6M6 6l12 12"></path>
                            </svg>
                        </button>
                    </div>
                    <div className="bg-white block md:hidden min-h-screen pt-2">
                        {linksForMobile.map((link, index) => (
                            <Link key={index} to={link.target} onClick={handleCloseMenu}>
                                <div className="py-4 mx-6 font-bold flex items-center gap-4">
                                    <img src={link.icon} alt="" />
                                    {link.label}
                                </div>
                            </Link>
                        ))}
                        <div className="bg-white rounded-xl p-4 px-6">
                            <h3 className="text-lg font-semibold pb-3">FOLLOW US</h3>
                            <div className="text-sm space-y-3">
                                <div className="py-2">
                                    <a className="flex items-center" href="https://www.instagram.com/cricketgullyofficial/" target="_blank" rel="noopener noreferrer">
                                        <img src={instagram} alt="Instagram" className="h-4 w-4" />{" "}
                                        <p className="pl-3">Instagram</p>
                                    </a>
                                </div>
                                <div className="py-2 ">
                                    <a className="flex items-center" href="https://www.facebook.com/cricketgully.official/" target="_blank" rel="noopener noreferrer">
                                        <img src={facebook} alt="Facebook" className="h-4 w-4" />{" "}
                                        <p className="pl-3">Facebook</p>
                                    </a>
                                </div>
                                <div className="py-2 ">
                                    <a className="flex items-center" href="https://www.youtube.com/channel/UCTKws3gbVaxeLJv4yXDorVQ" target="_blank" rel="noopener noreferrer">
                                        <img src={youtube} alt="Youtube" className="h-4 w-4" />{" "}
                                        <p className="pl-3">Youtube</p>
                                    </a>
                                </div>
                                <div className="py-2">
                                    <a className="flex items-center" href="https://x.com/thecricketgully" target="_blank" rel="noopener noreferrer">
                                        <img src={twitter} alt="Twitter" className="h-4 w-4" />{" "}
                                        <p className="pl-3">
                                            X <span className="text-[#6C6C6C]">(Twitter)</span>{" "}
                                        </p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-[#3A32D1] relative">
                <div className={`max-w-[1320px] xl:mx-auto flex justify-between items-center h-[52px]`}>
                    <div className="md:hidden absolute left-4">
                        {currnetUrlInfo?.reqState?.data ? (
                            <button onClick={handalBack} className="text-white focus:outline-none">
                                <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 1L1 8L8 15" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M1 8H17" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </button>
                        ) : (
                            <button onClick={() => setMenuIsOpen(true)} className="text-white focus:outline-none">
                                <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 12V10H18V12H0ZM0 7V5H18V7H0ZM0 2V0H18V2H0Z" fill="white" />
                                </svg>

                            </button>
                        )}
                    </div>
                    <div className="flex items-center md:w-auto w-full">
                        <div className={`${currnetUrlInfo?.reqState?.data && navbarTitleState ? "ml-[50px]" : "mx-auto"} max-w-60 live-match items-center text-white`}>
                            {
                                navbarTitleState
                                    ?
                                    navbarTitleState
                                    :
                                    <div className="flex items-center w-20">
                                        <Link to="/" className="flex md:mx-0 mx-auto items-center" onClick={doScrollToTop}>
                                            <img src={logo} alt="logo" />
                                        </Link>
                                    </div>
                            }
                        </div>
                    </div>
                    <div className="absolute md:static right-0 md:pr-4 pr-2 text-[12px] md:text-base" onClick={handleLoginClick}>
                        <button className="md:px-6 px-2 py-1 md:py-2 bg-[#ffffff] rounded-md text-[#3A32D1]">
                        {cGullyLoginTokenFromStorage ? "Profile" : "Login"}
                        </button>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default MobileTopNavbar;
