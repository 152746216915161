import React from 'react'
import SEOMeta from './Home/common/SEOMeta'

const FactCheckPolicy = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <SEOMeta slug="fact-check-policy" />
      <h1 className="font-bold mb-8">Fact Check Policy</h1>
      <div className="bg-white rounded-lg shadow-md p-8 text-justify">
        <div className='mb-4'>
          <p className="mb-4">
            CricketGully would like to inform you about the care and caution that We take in order to ensure the due accuracy of Our content, which is addressed by Our Fact-Checking Policy.</p>
        </div>

        <div className='mb-4'>
          <h4 className="font-semibold px-2 mb-4 text-blue-900 border rounded-lg bg-gray-100">Due accuracy of all Our content</h4>
          <p className='mb-4'>For any journalistic platform, the trust of its audience is the most important aspect. Trust can only be gained and sustained on the basis of accurate, fair and balanced reporting. It is essential that We remain committed to attaining due accuracy in all Our content to the extent possible. Our understanding of ‘due accuracy’ is that accuracy is not just of the requisite standard but also satisfactory in essence. Factors such as the subject and nature of the information being provided, the expectations of the audience etc. are also taken into consideration by Us in pursuit of due accuracy. We strive to give the most accurate account which is corroborated by the direct stakeholders of the news in every single news report. We investigate claims with scepticism, question assumptions, and challenge conventional wisdom. We acknowledge areas of uncertainty, which will always exist despite Our best efforts to resolve them. However, the stringency required to fact-check the information on soft and hard stories differs. For example, sources required for a positive story on an NGO work would be different from an investigative story.</p>
        </div>

        <div className='mb-4'>
          <h4 className='font-semibold px-2 mb-4 text-blue-900 border rounded-lg bg-gray-100'>We adhere to the guidance hereinbelow to ensure due accuracy in Our content:</h4>
          <p>We ensure that any broadcast by Us is derived from a reliable source, based on concrete and corroborated evidence. In case of a lack of direct sources, We are mandated to attribute the stories to the platform from where it is sourced.</p>
          <p>We endeavour to verify any claims or allegations or information attributed to public authorities or from someone, who We believe, has a cause beyond just giving an account of the truthfulness of the event. Therefore, We qualify and call out such information, including claims or allegations, that we are unable to corroborate.</p>
          <p>We stand by the information We publish and deem it to be accurate. If proven otherwise, We change the news item/information as swiftly as reasonably possible and We ensure that We duly inform Our readers of the changes made in such news item/information.</p>
          <p>We understand that Our audiences’ trust in Us is of utmost significance. Therefore, it is Our endeavour that We do not consciously misinform anyone, and that We do not tweak any information or present any made-up information as factual content. Further, where serious factual errors come to the fore, We publicly accept them and ensure that they are rectified in the shortest time possible in a clear and appropriate manner.</p>
          <p>We ensure that a fair opportunity is given to the public to report any inaccuracies or errors on Our Website via the “Suggest A Correction” segment that is provided at the end of all Our reportage is presented and published on Our Website https://CricketGully.com/</p>
          <p>The foremost responsibility of Our journalists is to report, write, and fact-check the news/information/stories. In fact, Our stories are subjected to scrutiny on multiple levels, including a robust fact-check internal procedure wherein thorough due diligence is carried out on every piece and it further reviewed by one or more of Our editors. It needs to be specified that the seniority of editors who undertake a review of the stories preceding their publication on the Website differs and depends on various factors such as the complexity and sensitivity of the issue, and the pressure of time.</p>
          <p>In case of an allegation, We ensure to reach out to all the concerned parties. We then independently verify the information in question and the one being provided so as to achieve the most accurate result.</p>
        </div>
        <div className='mb-4'>
          <h4 className='font-semibold px-2 mb-4 text-blue-900 border rounded-lg bg-gray-100'>Sourcing Information for Our Content</h4>
          <div className='md:ml-6 ml-4 '>
            <h5 className='font-semibold mb-2'>We source information in the most accurate way by following the given guidelines:</h5>
            <p>Verify each and every piece of information with at least two sources.</p>
            <p>In the case of a single source, the credibility of the source is ensured through corroboration of what the person is saying.</p>
            <p>Look for documentary evidence in every case possible, instead of solely relying on a human source.</p>
            <p>In the case of a survey, it is Our duty that provides the way the information was collected and how the data was interpreted. If there are chances of Our data not leading to accurate information, We convey the inconsistencies to the audience at the earliest possible time.</p>
            <p>The aim and intent are to get accurate information at the first instance instead of making it public first and then subsequently addressing any doubts whatsoever.</p>
            <p>Always put effort to take and talk on the record with the stakeholders of the information/news. Explain why a source is not named when an anonymous source is being used depending on the circumstances, and work out a way with such sources to provide the readers with as much information as possible about them so that readers can assess the sources’ reliability.</p>
            <p>Share information about sources with Our editors to enable them (editors as well reporters) to assess whether the concerned piece of information is appropriate for use and the manner it may be used. The conversation between the reporter and editor must be reflected in anonymous quotations.</p>
            <p>Have succinct conversations with sources as to how to use the information furnished by them, especially when the sources do not have significant experience in engaging with the media. Clarify a source’s expectations of keeping information “off the record”, and/or “on background”, and/or other statuses because such terms can have different meanings for different people.</p>
            <p>Give people the right to respond to reporting that may portray them in a negative light, and explain to readers the efforts We put in to seek a response in cases where sources do not respond.</p>
            <p>Consciously seek such sources who are in dearth any access to wide public platforms in conjunction with those sources who are influential and powerful.</p>
            <p>A senior resource or the person who is heading the newsroom at CricketGully can always be consulted in case one is in a dilemma or is unable to make a decision by themselves in order to avoid any wrong piece of information being presented to the audience.</p>
          </div>
        </div>
        <div className='mb-4'>
          <h4 className='font-semibold px-2 mb-4 text-blue-900 border rounded-lg bg-gray-100'>User-Generated Content</h4>
          <p>User-generated content possesses inherent challenges. We do not assume that the material shared with Us is accurate and, depending on how We plan to use it, We take reasonable steps to verify the veracity of such content. We are mindful of how to employ information supplied by a member of a lobby group or anyone with a vested interest in the story, rather than a disinterested bystander. We ensure that user-generated content is clearly identified as such. Additionally, We follow the given guidelines:</p>
          <p>Sources of information which may seem reliable on the internet may not always be duly accurate. It may be necessary to check who is running the website and/or confirm with an individual or organisation that the material relevant to them is genuine.</p>
          <p>Care is taken to distinguish fact from rumour. This is particularly but by no means exclusively, true for material available on social media where distortions may be deliberate or unintended but where error or rumour can spread like wildfire within minutes among the audience around the world, while corrections find it much harder to achieve the same momentum.</p>
          <p>Additional scrutiny may be necessary where material from a social media site or other internet source is being used to corroborate a fact. We qualify and call out all material that was not gathered by Us.</p>
        </div>
      </div>
    </div >
  )
}

export default FactCheckPolicy