import React from 'react'
import { formatDate } from '../../helpers/dates'
import { useAxiosSWR } from '../../../axiosInstance';
import { Link } from 'react-router-dom';
import { doScrollToTop, getNewsDetailsLinkPath, resizedImageURL } from '../../helpers/general';

const Link_for_seemore = {
    "news": "/cricket-news",
    "fantasy": "/fantasy",
    "top_story": "/cricket-top-story",
    "weekly_recap": "/cricket-news",
}
const MoreStories = ({ newsType, currentNewsID }) => {
    const {
        data,
        isLoading: isNewsLoading,
        isError: isNewsError,
    } = useAxiosSWR(`/v1/news/home/${newsType}?id=${currentNewsID}&per_page=6`);
    const dataArray = data?.data || [];
    if (isNewsLoading) {
        return <div></div>
    }
    if(isNewsError){
        <div className='p-4'></div>
    }
    return (
        <div className='bg-white border rounded-2xl'>
            <div className='md:min-h-[18px] md:min-w-[180px] px-5 md:py-8 py-4 min-w-[50px] text-[24px] leading-[28px] text-[#000000] font-bold flex items-center'>
                <span className="custom-border-left mr-3 md:mr-6"></span>
                <h2 className='text-2xl'>More <span className='font-normal'>Stories</span></h2>
                <span className="custom-border-right ml-3 md:ml-6"></span>
            </div>
            <div className='grid md:grid-cols-3 gap-5 items-stretch px-5 pb-6'>
                {dataArray?.map((news) => (
                    <div key={news.id}>
                        <Link to={getNewsDetailsLinkPath(news?.slug, news?.id, news?.type)} onClick={doScrollToTop}>
                            <div>
                                <img src={resizedImageURL(news.image,"320x160")} alt="news image" className='h-[160px] w-full rounded-xl object-cover' />
                            </div>
                            <div>
                                <p className='mt-2 news-title md:min-h-12 leading-[22px]'>
                                    {news.title}
                                </p>
                                <p className='mt-1 text-sm leading-[16px] font-semibold text-[#6c6c6c96]'>
                                    {formatDate(news.date)}
                                </p>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
            <div className='text-[#3A32D1] text-center py-3 border-t'>
                <Link to={Link_for_seemore[newsType]} onClick={doScrollToTop}>
                    <div>
                        See More
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default MoreStories
