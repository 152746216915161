import React from 'react';
import { formatDate } from '../../helpers/dates';
import { doScrollToTop, getNewsDetailsLinkPath, resizedImageURL, srcsetImageURL } from '../../helpers/general';
import { Link } from 'react-router-dom';
import { shimmerData } from '../../helpers/constants';

const  HomeNewsContainer = ({ title, data, subTitle = "", isLoading, isError, LinkTo, type="" }) => {
    const newsData = isLoading ? shimmerData : data || [];
    const shimmerClassName = newsData?.[0]?.isShimmer ? "bg-gray-300 loading text-opacity-0" : ""

    if (isError) {
        return (
            <div className='p-4'></div>
        )
    }

    const firstNewsContainerData = newsData?.[0];
    const otherNewsContainerData = newsData?.slice(1);

    return ( 
        <div className='bg-white border rounded-2xl'>
            <div className='px-5 md:py-8 py-4'>
                <div className={`md:min-h-[18px] md:min-w-[180px] ${shimmerClassName} flex items-center min-w-[50px]`}>
                    <span className="custom-border-left mr-3 md:mr-6"></span>
                    <h2 className='text-[16px] md:text-2xl'>{title}</h2>
                    <span className="custom-border-right ml-3 md:ml-6"></span>
                </div>
                <p className={`${shimmerClassName} text-center text-[#787878] md:text-base text-sm`}>{subTitle}</p>
            </div>

            <div className='px-[12px] md:px-5'>
                <div className={`${otherNewsContainerData.length > 0 ? "pb-4 border-[#E3E3E4] border-b md:border-none" : ""}`}>
                    <Link to={getNewsDetailsLinkPath(firstNewsContainerData?.slug, firstNewsContainerData?.id, firstNewsContainerData?.type)} onClick={doScrollToTop}>
                        <div>
                            <img src={resizedImageURL(firstNewsContainerData?.image,"332x166")} srcSet={srcsetImageURL(firstNewsContainerData?.image,"576x288","576x288")} alt="news" className={` ${shimmerClassName} w-full md:h-[288px] h-[166px] object-cover rounded-xl`} />
                        </div>
                        <div>
                            <p className={`${shimmerClassName} min-h-6 mt-3 news-title md:leading-[22px] leading-[16px] font-semibold md:text-lg text-[13px]`}>
                                {firstNewsContainerData?.title}
                            </p>
                            <p className={`${shimmerClassName}  md:text-sm text-[10px] md:mt-3 mt-2 md:leading-[16px] leading-3 md:font-medium text-[#787878]`}>
                                {formatDate(firstNewsContainerData?.date)}
                            </p>
                        </div>
                    </Link>
                </div>
            </div>

            {
                otherNewsContainerData.length > 0 && type === 'news' &&
                <div className='md:flex overflow-x-auto space-x-4 md:space-x-0 hideScrollbar overflow-y-hidden items-stretch px-3 md:px-1'>
                    <div className='gap-[25px] md:gap-0 md:py-0 py-6 grid grid-cols-2'>
                        {otherNewsContainerData?.map((news, index) => (
                            <div key={news?.id} className="md:p-4">
                                <Link key={index} to={getNewsDetailsLinkPath(news?.slug, news?.id, news?.type)} onClick={doScrollToTop}>
                                    <div>
                                        <img src={resizedImageURL(news?.image,"160x85")} srcSet={srcsetImageURL(news?.image,"250x124","250x124")} alt="news image" className={`${shimmerClassName} md:h-[124px] h-[84px] w-full rounded-xl object-cover`} />
                                    </div>
                                    <div>
                                        <p className={`${shimmerClassName} mt-2 news-title min-h-7 md:min-h-12 md:text-base text-[13px] font-semibold leading-[14px] md:leading-[22px]`}>
                                            {news?.title}
                                        </p>
                                        <p className={`${shimmerClassName} mt-2 md:text-sm text-[10px] leading-[12px] md:leading-[16px] md:font-medium text-[#787878]`}>
                                            {formatDate(news?.date)}
                                        </p>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            }
               {
                otherNewsContainerData.length > 0 && type !== 'news' &&
                <div className='flex overflow-x-auto hideScrollbar md:overflow-y-hidden md:items-stretch md:px-1'>
                    <div className='md:gap-0 md:p-0 md:grid grid-cols-2 flex gap-[10px] pt-6 pb-2 px-3'>
                        {otherNewsContainerData?.map((news, index) => (
                            <div key={news?.id} className="md:w-full w-[140px] md:p-4">
                                <Link key={index} to={getNewsDetailsLinkPath(news?.slug, news?.id, news?.type)} onClick={doScrollToTop}>
                                    <div>
                                        <img src={resizedImageURL(news?.image,"160x85")} srcSet={srcsetImageURL(news?.image,"250x124","250x124")} alt="news image" className={`${shimmerClassName} md:h-[124px] w-[140px] h-[84px] md:w-full rounded-xl object-cover`} />
                                    </div>
                                    <div>
                                        <p className={`${shimmerClassName} mt-2 news-title min-h-7 md:min-h-12 md:text-base text-[13px] font-semibold leading-[14px] md:leading-[22px]`}>
                                            {news?.title}
                                        </p>
                                        <p className={`${shimmerClassName} mt-2 md:text-sm text-[10px] leading-[12px] md:leading-[16px] font-medium text-[#787878]`}>
                                            {formatDate(news?.date)}
                                        </p>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            }
            <div className='flex items-end justify-center h-2 w-full bg-gradient-to-t from-[#e2e2e2ab] to-transparent'>
            </div>
            <div className='text-[#3A32D1] text-center py-3 md:text-sm text-xs font-semibold'>
                <Link
                    key='btnSeeMoreNews2'
                    to={LinkTo}
                    onClick={doScrollToTop}
                >
                    See More
                </Link>
            </div>
        </div>
    );
};

export default HomeNewsContainer;