import React from 'react'
import { SocialLinksComponent } from '../pages/NewsDetails/general'
import instagram from "../../Assets/instagram.svg";
import youtube from "../../Assets/youtube.svg";
import twitter from "../../Assets/x(twitter).svg";
import facebook from "../../Assets/facebook.svg";
import EmailIcon from "../../Assets/contactusEmailIcon.svg";
const socialLinksV2 = [
    {
        name: "Instagram",
        url: "https://www.instagram.com/cricketgullyofficial/",
        icon: instagram,
    },
    {
        name: "Facebook",
        url: "https://www.facebook.com/cricketgully.official/",
        icon: facebook,
    },
    {
        name: "Youtube",
        url: "https://www.youtube.com/channel/UCTKws3gbVaxeLJv4yXDorVQ",
        icon: youtube,
    },
    {
        name: "Twitter",
        url: "https://x.com/thecricketgully",
        icon: twitter,
        displayName: "X (Twitter)",
    },
];
const ContactUs = () => {
    return (
        <div className='bg-white md:px-[80px] lg:px-[140px] xl:px-[180px] py-10 md:py-20'>
            <div className='md:grid grid-cols-3 px-6 md:px-0'>
                <div>
                    <div className='md:text-[24px]'>Contact Us</div>
                    <div className='md:text-[48px] text-[40px] font-bold mt-2 md:mt-0'> Query for More Inforkation</div>
                </div>
                <div className='mx-auto mt-[44px] md:mt-8'>
                    <div className='font-extrabold text-[20px] md:text-[24px]'>
                        Email Address
                    </div>
                    <div className='md:mt-7 mt-5'>
                        <div className='flex items-center gap-1'>
                            <img src={EmailIcon} alt="email" />
                            <div className='md:text-[20px] md:leading-5 text-[#3A32D1]'>
                                contact@cricketgully.com
                            </div>
                        </div>
                        <div className='flex items-center gap-1 mt-3 md:mt-6'>
                            <img src={EmailIcon} alt="email" />
                            <div className='md:text-[20px] md:leading-5 text-[#3A32D1]'>
                                advertise@cricketgully.com
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mx-auto mt-[54px] md:mt-8'>
                    <div className='font-extrabold text-[20px] md:text-[24px]'>Follow us</div>
                    <div className='flex gap-2.5 justify-around md:justify-start md:mt-7 mt-5'>
                        {socialLinksV2.map((link, index) => (
                            <div key={index} className="py-2 flex">
                                <a
                                    className="flex flex-col items-center gap-2"
                                    href={link.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img src={link.icon} alt={link.name} className="w-10 h-10 object-cover rounded-lg" />
                                    <p className="text-[14px] opacity-60 md:font-medium md:px-2">
                                        {link.displayName || link.name}
                                    </p>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs
