import React, { useState, useEffect } from "react";
import { useAxiosSWR } from "root/axiosInstance";
import { getTimeLabelForPastDateTime } from "../../../../../helpers/dates";
import one from '../../../../../Assets/index1.svg';
import two from '../../../../../Assets/index2.svg';
import three from '../../../../../Assets/index3.svg';
import four from '../../../../../Assets/index4.svg';
import five from '../../../../../Assets/index5.svg';
import king from '../../../../../Assets/king.svg';
import { Link } from "react-router-dom";
import { doScrollToTop } from "../../../../../helpers/general";

const Teams = "Teams";
const Category = [
  { key: "Teams", label: "Team" },
  { key: "BatsMen", label: "Batting" },
  { key: "Bowlers", label: "Bowling" },
  { key: "All-Rounders", label: "All-Rounder" },
];

const Odi = "ODI";
const Test = "Test";
const T20 = "T20";
const matchTypeObj = {
  ODI: "odis",
  Test: "tests",
  T20: "t20s",
};
const shimmerAry = [];

for (let i = 0; i < 5; i++) {
  shimmerAry.push({
    isShimmer: true,
    rank: 0,
    player: "",
    team: "",
    rating: 0,
  });
}

const numberForDextop = {
  1: one,
  2: two,
  3: three,
  4: four,
  5: five,
}

const TopRankings = () => {
  const { data, error: isError, isLoading } = useAxiosSWR("/v1/misc/ranking");
  const [category, setCategory] = useState(Teams);
  const [matchType, setMatchType] = useState(T20);
  const [topRankingData, setTopRankingData] = useState(shimmerAry);
  const [displayedItemsCount, setDisplayedItemsCount] = useState(5);

  useEffect(() => {
    if (!isError && !isLoading) {
      updateDisplayedData(category, matchType);
    }
  }, [isLoading, isError, category, matchType]);

  const topRanking = data || {};

  const updateDisplayedData = (cat, type) => {
    if (!isLoading) {
      const categoryData =
        topRanking[cat.toLowerCase()][matchTypeObj[type]] || [];
      setTopRankingData(categoryData);
    } else {
      setTopRankingData(shimmerAry);
    }
  };

  if (isError) {
    return <h2></h2>;
  }

  const shimmerClassName = topRankingData?.[0]?.isShimmer
    ? "bg-gray-300 loading"
    : "";

  const handleCategoryChange = (cat) => {
    setCategory(cat);
    updateDisplayedData(cat, matchType);
  };

  const handleMatchTypeChange = (type) => {
    setMatchType(type);
    setCategory(Teams);
    updateDisplayedData(category, type);
  };

  const handleLoadMore = () => {
    setDisplayedItemsCount((prevCount) => prevCount + 5);
  };

  return (
    <div className="bg-white rounded-xl md:my-4 mt-5 border">
      <div className="md:px-4 pt-4">
        <h2 className="md:text-lg text-sm font-bold text-center md:pt-2">🤩 Top Rankings</h2>
        <div className="flex justify-center mx-auto md:text-sm text-xs border border-[#D8E7FF] rounded-full md:my-[18px] mt-3 mb-6 w-max cursor-pointer md:h-[32px] h-[25px]">
          {[T20, Odi, Test].map((type, index) => {
            const isActive = matchType === type;
            const roundedClass =
              type === T20 ? "rounded-l-full" :
                type === Test ? "rounded-r-full" :
                  "";
            return (
              <div
                key={type}
                className={`flex justify-center items-center ${isActive ? "text-white bg-blue-700" : ""} ${roundedClass}`}
                onClick={() => handleMatchTypeChange(type)}
              >
                <div className={`sm:px-4 px-4 ${!isActive ? "border-r border-[#D8E7FF]" : ""}`}>
                  {type}
                </div>
              </div>
            );
          })}
        </div>

        <div className="border-b">
          <div className="flex justify-between text-sm cursor-pointer overflow-auto">
            {Category.map(({ key, label }, i) => (
              <button
                key={i}
                className={`text-xs text-[#6C6C6C] mx-1 px-1 pb-2.5 ${category === key ? `border-b-[3px] text-blue-700 border-blue-700` : ""
                  }`}
                onClick={() => handleCategoryChange(key)}
              >
                {label}
              </button>
            ))}
          </div>
        </div>

        <div className="mt-6 text-[#6C6C6C] text-[14px] font-medium">
          <div className="grid grid-cols-5 text-[12px] font-semibold text-center">
            <div className="text-start ml-5 md:ml-0">Rank</div>
            <div className="col-span-3 text-start">{category === Teams ? 'Team' : 'Player'}</div>
            <div>Rating</div>
          </div>
          <div>
            {topRankingData.slice(0, displayedItemsCount).map((player, index) => (
              <div
                key={`${player?.pid} - ${index}`}
                className={`grid grid-cols-5 items-center ml-3 md:ml-0`}
              >
                <div>
                  {numberForDextop[index + 1] ? (
                    <img src={numberForDextop[index + 1]} alt={`Rank ${index + 1}`} className="mt-5" />
                  ) : (
                    player.rank
                  )}
                </div>
                <div className={`col-span-3 min-h-9 min-w-12 ${shimmerClassName}`}>
                  <p className="text-black font-semibold flex items-center gap-1">
                    {player.player || player.team}
                    {index === 0 && (
                      <img src={king} alt="King" />
                    )}
                  </p>
                  <p className={`text-[10px] min-h-[15px] min-w-[30px] uppercase font-semibold ${shimmerClassName}`}>
                    {player.team}
                  </p>
                </div>
                <div className={`${shimmerClassName} text-center text-xs font-semibold`}>
                  {player.rating}
                </div>
              </div>
            ))}
          </div>
        </div>
        <Link to={"/ranking/teams/t20s"} onClick={doScrollToTop}>
          <div
            className={`text-center text-[#3A32D1] text-sm font-semibold cursor-pointer pb-6 pt-2`}
            onClick={handleLoadMore}
          >
            Load More
          </div>
        </Link>
      </div>
      <div className="flex justify-center items-center text-center text-[#6C6C6C] text-sm bg-[#EEF0F3] bg-opacity-30 text-opacity-50 h-[50px]">
        Latest Updated on {getTimeLabelForPastDateTime(topRanking?.updatedAt)}
      </div>
    </div>
  );
};

export default TopRankings;
