import React from 'react'
import SEOMeta from './Home/common/SEOMeta'

const CookiePolicy = () => {
    return (
        <div className="container mx-auto px-4 py-8">
            <SEOMeta slug="privacy" />
            <h1 className="font-bold mb-8">Cookies Policy</h1>
            <div className="bg-white rounded-lg shadow-md p-8 text-justify">
                <div className='mb-4'>
                    <p className="mb-4">A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. By continuing to browse the site, you are agreeing to our use of cookies. Cookies contain information that is transferred to your computer’s hard drive. You can set your browser to refuse all or some browser cookies, or to alert you when Platforms set or access cookies. If you disable or refuse cookies, please note that some parts of this Platform may become inaccessible or not function properly. A list of the type of cookies we use is as follows;</p>
                    <ol className='mx-10 mb-4'>
                        <li className='mb-2'>
                            <p><storng>Strictly necessary cookies. </storng>These are cookies that are required for the operation of our Platform. They include, for example, cookies that enable you to log into secure areas of our Platform, use a shopping cart or make use of e-billing services.</p>
                        </li>
                        <li className='mb-2'>
                            <p><storng>Analytical/performance cookies.</storng>They allow us to recognize and count the number of visitors and to see how visitors move around our Platform when they are using it. This helps us to improve the way our Platform works, for example, by ensuring that users are finding what they are looking for easily.</p>
                        </li>
                        <li className='mb-2'>
                            <p><storng>Functionality cookies. </storng>These are used to recognize you when you return to our Platform. This enables us to personalize our content for you, greet you by name and remember your preferences (for example, your choice of language or region).</p>
                        </li>
                        <li className='mb-2'>
                            <p><storng>Targeting cookies.</storng> These cookies record your visit to our Platform, the pages you have visited and the links you have followed. We will use this information to make our Platform and the advertising displayed on it more relevant to your interests. We may also share this information with third-parties for this purpose.</p>
                        </li>
                    </ol>
                    <p className="mb-4">Please note that third-parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies, over which we have no control. These cookies are likely to be analytical/performance cookies or targeting cookies. You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our Platform.</p>
                </div>

                <div className='mb-4'>
                    <h4 className="font-semibold px-2 mb-4 text-blue-900 border rounded-lg bg-gray-100">GOOGLE ANALYTICS</h4>
                    <p className='mb-4'>We use Google Analytics to help us to understand how you make use of our content and work out how we can make things better. These cookies follow your progress through us, collecting anonymous data on where you have come from, which pages you visit, and how long you spend on the site. This data is then stored by Google to create reports. These cookies do not store your personal data. </p>
                    <p className='mb-4'>The information generated by the cookie about your use of the Platform, including your IP address, may be transmitted to and stored by Google on servers in the United States. Google may use this information for the purpose of evaluating your use of the Platform, compiling reports on Platform activity for us and providing other services relating to Platform activity and internet usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google's behalf. Google will not associate your IP address with any other data held by Google. By using this Platform, you consent to the processing of data about you by Google in the manner and for the purposes set out above. </p>
                    <p className='mb-4'>The Google Platform contains further information about Analytics and a copy of Google's privacy policy pages.</p>
                </div>

                <div className='mb-4'>
                    <h4 className="font-semibold px-2 mb-4 text-blue-900 border rounded-lg bg-gray-100">GOOGLE ADSENSE</h4>
                    <p className='mb-4'>Google AdSense is a tool that allows Platform publishers to deliver advertisements to site visitors in exchange for revenue calculated on a per-click or per-impression basis. To do this, Google <storng> uses cookies and tracking technology</storng> to deliver ads personalized to a Platform user/visitor. In this regard the following terms are specified to the Users:</p>
                    <ol className='mx-10 mb-4' style={{ listStyleType: "lower-alpha" }}>
                        <li className='mb-2'>
                            <p>Third-party vendors, including Google, use cookies to serve ads based on your prior visits to our Platform or other Platforms.
                            </p>
                        </li>
                        <li className='mb-2'>
                            <p>Google's use of advertising cookies enables us and our partners to serve advertisements to you based on their visit to our Platform and/or other Platforms on the Internet.
                            </p>
                        </li>
                        <li className='mb-2'>
                            <p>You may opt-out of personalized advertising by visiting Ads Settings.
                            </p>
                        </li>
                        <li className='mb-2'>
                            <p>All advertisements of third parties on our Platform are for informative purposes only and neither the Platform nor the Firm guarantees or bears liability for the authenticity of the advertisements.
                            </p>
                        </li>
                        <li className='mb-2'>
                            <p>At no point will the Firm permit its competitors to advertise on the Platform.
                                You may visit the links in the advertisements at your own risk or choose to not accept the cookies permitting third parties to display their advertisements.
                            </p>
                        </li>
                    </ol>
                </div>
            </div>
        </div >
    )
}

export default CookiePolicy