import React, { useState } from "react";
import { formatDate } from "../../../../helpers/dates";
import next from "../../../../Assets/next-svg.svg";
import privius from "../../../../Assets/prev-svg.svg";
import { useAxiosSWR } from "root/axiosInstance";
import { ShimmerEffect } from "../../../common/ShimmerEffect";
import { getSanitizedArray } from "../../../../helpers/general";
import { useTikTik } from "src/hooks/useTikTik";

const shimmerAry = [];
for (let i = 0; i < 5; i++) {
  shimmerAry.push({ image: "", title: "", date: "", isShimmer: true });
}

const LegendaryMomentum = () => {
  const { data, error: isError, isLoading } = useAxiosSWR("v1/misc/moments");
  const [currentIndex, setCurrentIndex] = useState(0);

  const legendaryMomentumData = getSanitizedArray(isLoading ? shimmerAry : data);
  const shimmerClassName = legendaryMomentumData?.[0]?.isShimmer
    ? "bg-gray-300 loading text-opacity-0"
    : "";

  const handleNext = () => {
    if (currentIndex < legendaryMomentumData.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  // Use the enhanced useTikTik hook
  const { handleTouchStart, handleTouchMove, handleTouchEnd } = useTikTik(
    legendaryMomentumData,
    setCurrentIndex
  );

  const handleIndicatorClick = (index) => {
    setCurrentIndex(index);
  };

  if (isError) {
    return <h2></h2>;
  }

  return (
    <div>
      <div className="border pt-4 order-2 md:rounded-xl rounded-[15px] bg-white md:mt-5 mt-1 lg:order-1">
        <h3 className="md:text-lg text-sm px-4 mb-4 font-bold leading-[18px] text-center">
          ⭐ Legendary Momentum
        <p className="text-xs opacity-60 text-center md:hidden mt-[10px]">
          {formatDate(legendaryMomentumData[currentIndex].date, false)}
        </p>
        </h3>
        {legendaryMomentumData.length > 0 && (
          <div
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={() => handleTouchEnd(handleNext, handlePrevious)} // Handle touch end with reset
            className="relative"
          >
            <div className="md:px-4 px-[10px] overflow-hidden relative">
              <ShimmerEffect
                className={`${shimmerClassName} h-[298px] w-full rounded-xl`}
                src={legendaryMomentumData[currentIndex].image}
                alt="Legendary Momentum"
              />
              <div className="absolute bottom-0 left-0 pl-8 py-4 w-[200px] max-w-[200px] text-[#FFFFFF]">
                <p className="text-lg">{legendaryMomentumData[currentIndex].title}</p>
                <p className="text-xs opacity-60 hidden md:block">
                  {formatDate(legendaryMomentumData[currentIndex].date, false)}
                </p>
              </div>
            </div>
            <div className="flex justify-between items-center my-3 md:my-0">
              <button
                onClick={handlePrevious}
                disabled={currentIndex === 0}
                className={`disabled:opacity-50 hidden md:block ${currentIndex === 0 ? "invisible" : "visible"
                  }`}
              >
                <img src={privius} alt="Previous" className="" />
              </button>
              <div className="flex justify-center flex-grow overflow-hidden mx-[10px] md:mx-0">
                {legendaryMomentumData.map((_, index) => (
                  <div
                    key={index}
                    className={`h-1 md:w-6 w-full mx-0.5 rounded-full ${index === currentIndex ? "bg-[#3A32D1]" : "bg-[#D8D6F6]"
                      } cursor-pointer`}
                    onClick={() => handleIndicatorClick(index)}
                  ></div>
                ))}
              </div>
              <button
                onClick={handleNext}
                disabled={currentIndex === legendaryMomentumData.length - 1}
                className={`disabled:opacity-50 hidden md:block ${currentIndex === legendaryMomentumData.length - 1
                    ? "invisible"
                    : "visible"
                  }`}
              >
                <img src={next} alt="Next" />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LegendaryMomentum;