import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAxiosSWR } from "root/axiosInstance";
import { getSanitizedArray } from "../../../../helpers/general";

const shimmerAry = [{ isShimmer: true, abbr: "" },
{ isShimmer: true, abbr: "", image: "" },
{ isShimmer: true, abbr: "", image: "" },
{ isShimmer: true, abbr: "", image: "" },
{ isShimmer: true, abbr: "", image: "" }]

const Series = () => {
  const { data, error, isLoading } = useAxiosSWR("/v1/misc/currentSeries");
  const [showMore, setShowMore] = useState(false);

  const seriesList = getSanitizedArray(isLoading ? shimmerAry : data);
  const shimmerClassName = seriesList?.[0]?.isShimmer ? "bg-gray-300 loading text-opacity-0" : ""

  if (error) {
    return <div></div>;
  }

  if (!data || !Array.isArray(seriesList)) {
    return <div>No data available</div>;
  }

  const seriesToList = showMore ? seriesList : seriesList.slice(0, 4);

  return (
    <>
      <div className="md:block hidden bg-white p-4 md:rounded-xl mt-5 border">
        <h2 className="text-lg leading-[18px] mb-4 font-bold text-center">🏏 current series</h2>
        {seriesToList.map((obj, i) => (
          <div key={i} className={`bg-[#E8F1FF80] p-3 text-xs rounded-xl my-3 `}>
            <Link to={!obj.isShimmer && `/series/${obj.slug}/schedule`} >
              <img className={`${shimmerClassName} h-24 w-full rounded-2xl object-cover`} src={obj.image} alt="current series" />
              <p className={`${shimmerClassName} min-h-4 w-full mt-2 font-semibold text-sm text-center`}>{obj.abbr}</p>
            </Link>
          </div>
        ))}
        {seriesList.length > 4 && (
          <Link to="/series">
          <div className="text-[#3A32D1] text-sm text-center font-semibold cursor-pointer">
            {showMore ? "" : "See More"}
          </div>
          </Link>
        )}
      </div>

      <div className="md:hidden bg-white mt-4 px-[10px] py-4 rounded-xl border">
        <h2 className="text-sm leading-[18px] mb-4 font-bold text-center">🏏 Current Series</h2>
        <div className={`flex ${seriesList.length < 5 ? "justify-center" : "justify-start"} overflow-x-auto space-x-4 hideScrollbar`}>
          {seriesList.map((obj, i) => (
            <div key={i} className="text-xs">
              <Link to={!obj.isShimmer && `/series/${obj.slug}/schedule`}>
                <img
                  className={`${shimmerClassName} h-10 w-10 rounded-xl object-cover`}
                  src={obj.image}
                  alt="current series"
                />
                <p className={`${shimmerClassName} my-1 font-medium text-[10px] leading-3 w-12 text-center`}>
                  {obj.abbr}
                </p>
              </Link>
            </div>
          ))}
        </div>
      </div>

    </>
  );
};

export default Series;