import React, { useState } from "react";
import next from "../../../../Assets/next-svg.svg";
import privius from "../../../../Assets/prev-svg.svg";
import { useAxiosSWR } from "root/axiosInstance";
import { ShimmerEffect } from "../../../common/ShimmerEffect";
import { getSanitizedArray } from "../../../../helpers/general";
import { useTikTik } from "src/hooks/useTikTik";

const shimmerAry = [];
for (let i = 0; i < 5; i++) {
  shimmerAry.push({ image: "", isShimmer: true });
}

const Facts = () => {
  const { data, error: isError, isLoading } = useAxiosSWR("v1/misc/facts");
  const [currentIndex, setCurrentIndex] = useState(0);
  const factsData = getSanitizedArray(isLoading ? shimmerAry : data);
  const shimmerClassName = factsData?.[0]?.isShimmer ? "bg-gray-300 loading text-opacity-0" : "";

  const handleNext = () => {
    if (currentIndex < factsData.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  // Use useTikTik hook with touch handling
  const { handleTouchStart, handleTouchMove, handleTouchEnd } = useTikTik(data, setCurrentIndex);

  const handleIndicatorClick = (index) => {
    setCurrentIndex(index);
  };

  if (isError) {
    return <h2></h2>;
  }

  return (
    <div>
      <div className="pt-4 order-2 md:rounded-xl rounded-[15px] border bg-white md:mt-5 mt-1 overflow-hidden lg:order-1">
        <h3 className="md:text-lg text-sm mb-4 px-4 font-bold leading-[18px] text-center">🤔 Interesting Facts</h3>
        {factsData.length > 0 && (
          <div
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={() => handleTouchEnd(handleNext, handlePrevious)}
            className="relative"
          >
            <div className="md:px-4 px-[10px]">
              <ShimmerEffect
                className={`${shimmerClassName} h-[298px] w-full rounded-xl object-cover`}
                src={factsData[currentIndex].image}
                alt="Interesting Fact"
              />
            </div>
            <div className="flex justify-between items-center my-3 md:my-0">
              <button
                onClick={handlePrevious}
                disabled={currentIndex === 0}
                className={`disabled:opacity-50 hidden md:block ${currentIndex === 0 ? "invisible" : "visible"}`}
              >
                <ShimmerEffect src={privius} alt="Previous" />
              </button>
              <div className="flex justify-center flex-grow overflow-hidden mx-[10px] md:mx-0">
                {factsData.map((_, index) => (
                  <div
                    key={index}
                    className={`h-1 md:w-6 w-full mx-0.5 rounded-full ${index === currentIndex ? "bg-[#3A32D1]" : "bg-[#D8D6F6]"} cursor-pointer`}
                    onClick={() => handleIndicatorClick(index)}
                  ></div>
                ))}
              </div>
              <button
                onClick={handleNext}
                disabled={currentIndex === factsData.length - 1}
                className={`disabled:opacity-50 hidden md:block ${currentIndex === factsData.length - 1 ? "invisible" : "visible"}`}
              >
                <ShimmerEffect src={next} alt="Next" />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Facts;