import React, { useState } from "react";
import { useAxiosSWR } from "root/axiosInstance";
import { getSanitizedArray } from "../../../../helpers/general";
import close from "../../../../Assets/ClosePointTable.svg"
const shimmerAry = [{
  "cid": 0,
  "title": "",
  "type": "",
  "slug": "",
  "table": []
}]
for (let i = 0; i < 10; i++) {
  shimmerAry[0].table.push({
    "isShimmer": true,
    "tid": 0,
    "title": "",
    "abbr": "",
    "thumb_url": "",
    "played": "0",
    "win": "0",
    "loss": "0",
    "draw": "0",
    "netrr": "0",
    "points": "0",
  })
}
const PointTable = () => {
  const [selectedData, setSelectedData] = useState(0);
  const {
    data,
    isLoading,
    error: isError
  } = useAxiosSWR("v1/series/pointTable");
  const pointTable = getSanitizedArray(isLoading ? shimmerAry : data);
  const shimmerClassName = pointTable?.[0]?.table?.[0]?.isShimmer ? "bg-gray-300 loading text-opacity-0" : ""
  if (isError) {
    return <h2></h2>;
  }

  const handleSelectChange = (event) => {
    setSelectedData(parseInt(event.target.value));
  };

  return (
    <div className="bg-white rounded-xl p-4 mb-5 border">
      <h2 className="md:text-lg text-sm font-bold mb-4 text-center">📊 Points Table</h2>

      <div className="relative">
        <select
          className="md:text-sm text-xs border border-[#E3E3E4] shadow-lg bg-[#FFFFFF] w-full rounded-lg p-4 md:py-[10px] py-2 pr-8 appearance-none"
          value={selectedData}
          onChange={handleSelectChange}
        >
          {pointTable.map((data, index) => (
            <option key={index} value={index}>
              {data.title}
            </option>
          ))}
        </select>
        <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
          <img src={close} alt="close" />
        </div>
      </div>
      <div className="overflow-auto">
        <table className="table-auto border-collapse text-[#6C6C6C] w-full">
          <thead>
            <tr className="text-xs font-light gap-2">
              <th className="py-[10px] text-start">Team</th>
              <th className="py-[10px]">M</th>
              <th className="py-[10px]">W</th>
              <th className="py-[10px]">L</th>
              <th className="py-[10px]">T</th>
              <th className="py-[10px]">PT</th>
              <th className="py-[10px]">NRR</th>
            </tr>
          </thead>
          <tbody className="md:text-xs text-[11px] text-center">
            {getSanitizedArray(pointTable[selectedData]?.table).map((team, index) => (
              <tr key={index}>
                <td className="pr-1 py-[10px]">
                  <div className="flex items-center">
                    {!team.isShimmer ? <img
                      src={team.thumb_url}
                      alt="logo"
                      className={`m-1 rounded-full h-[30px] w-[30px]`}
                    /> : <div className={`${shimmerClassName} m-1 h-5 w-5 rounded-full `}></div>}
                    <div className={`flex flex-col text-start`}>
                      <span className={`${shimmerClassName} md:min-h-4 min-h-[11px] min-w-12 text-[#000000] text-xs font-semibold`}>
                        {team.abbr}
                      </span>
                      <span className={`text-[8px] font-semibold text-wrap ${shimmerClassName} min-h-2 min-w-12`}>{team.title}</span>
                    </div>
                  </div>
                </td>
                <td className="px-1 py-[10px]">{team.played}</td>
                <td className="px-1 py-[10px]">{team.win}</td>
                <td className="px-1 py-[10px]">{team.loss}</td>
                <td className="px-1 py-[10px]">{team.draw}</td>
                <td className="px-1 py-[10px]">{team.points}</td>
                <td className="px-1 py-[10px]">{team.netrr}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PointTable;
