import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAxiosSWR } from "root/axiosInstance";
import vector1 from "../../../../../Assets/vector1.svg";
import vector2 from "../../../../../Assets/vector2.svg";
import iconSUB from "../../../../../Assets/sub.svg";
import iconIMP from "../../../../../Assets/imp.svg";
import { useParams } from "react-router-dom";
import { getSeriesMatchID } from "./common/helper";
import { matchObj, scorecard } from "./common/ShimmerData";
import toss from "../../../../../Assets/toss.svg";
import serieslogo from "../../../../../Assets/series.svg";
import datelogo from "../../../../../Assets/date.svg";
import umpires from "../../../../../Assets/umpires.svg";
import thirdUmpire from "../../../../../Assets/thirdUmpire.svg";
import referee from "../../../../../Assets/referee.svg"
import { useHorizontalScroll } from "../../../../../hooks/useHorizontalScroll";
import { getSnakeCaseId } from "../../ScheduleInstance/helper";

const Scorecard = () => {
  const { seriesId: seriesSlug, matchesId: matchSlug } = useParams();
  const { matchId } = getSeriesMatchID(seriesSlug, matchSlug);
  const [refreshInterval, setRefreshInterval] = useState(0);
  const [selectedIninng, setSelectInnings] = useState("");
  const containerRef = useRef(null);
  const {
    data,
    error: isError,
    isLoading,
  } = useAxiosSWR(`v1/matches/${matchId}/scorecard`, {
    refreshInterval,
  });
  const LiveMatchStatus = data && data?.match?.status === "Live";
  useEffect(() => {
    if (LiveMatchStatus) {
      setRefreshInterval(5000);
    } else {
      setRefreshInterval(0);
    }
  }, [LiveMatchStatus]);
  useHorizontalScroll(containerRef, getSnakeCaseId(selectedIninng));
  const inningsData = isLoading ? scorecard : data || {};
  const matchData = isLoading ? matchObj : data?.match || {};
  useEffect(() => {
    const firstInning = inningsData?.info?.scorecard?.innings?.[0]?.name || "";
    if (!selectedIninng && firstInning) {
      setSelectInnings(firstInning);
    }
  }, [inningsData]);

  const renderTeamScorecard = (inning) => {
    const teamKey = inning?.iid;
    const teamName = inning?.short_name;
    const scorecard = inning;
    const scroe = scorecard?.scores_full
    const batsmen = scorecard?.batsmen || [];
    const bowlers = scorecard?.bowlers || [];
    const extraRuns = scorecard?.extra_runs || {};
    const fows = scorecard?.fows || [];

    return (
      <div key={`${teamKey}_${teamName}`} className="w-full mt-5 md:px-5 px-[6px]">
        <div
          id={getSnakeCaseId(inning?.name)}
          className={`${inning?.name === selectedIninng ? "rounded-t-xl md:rounded-xl border border-[#DEDEDE] md:border-none" : "rounded-xl"} bg-gradient-to-r mt-5 h-10 from-black to-blue-900 text-white px-3 md:px-6 py-2 flex justify-between items-center cursor-pointer`}
          onClick={() => {
            scrollSmooth(getSnakeCaseId(selectedIninng));
            setSelectInnings(prev => {
              if (prev === inning?.name) {
                return "";
              }
              return inning?.name
            })
          }}
          aria-expanded={inning?.name === selectedIninng}
        >
          <span>{teamName}</span>
          <div className="flex gap-2">
            <div>
              {scroe}
            </div>
            <img
              src={inning?.name === selectedIninng ? vector1 : vector2}
              alt="vector"
              className="flex justify-items-end"
            />
          </div>
        </div>
        <div
          className={`team-scorecard ${inning?.name === selectedIninng ? "expanded" : "collapsed"
            }`}
        >
          <div className="bg-[#EEF0F3] md:mt-4 border border-b-0 border-[#DEDEDE] md:border-none md:rounded-xl md:px-4 py-2">
            <div className="text-left text-[10px] text-[#6C6C6C] md:text-black md:text-[16px] grid grid-cols-12">
              <div className="col-span-6 md:col-span-7 sm:grid-cols-7 sm:grid">
                <p className="px-4 md:px-0 font-bold text-black md:text-base text-sm leading-4">Batter</p>
              </div>
              <p className="md:px-4 px-2 text-right font-bold">R</p>
              <p className="px-2 text-right font-bold">B</p>
              <p className="md:px-0 px-2 text-right font-bold">4s</p>
              <p className="md:px-0 px-2 text-right font-bold">6s</p>
              <p className="md:px-0 px-4 font-bold md:col-span-1 col-span-2 text-right">SR</p>
            </div>
          </div>
          <div className="bg-white border border-b-0 border-[#DEDEDE] md:border-none">
            {batsmen.map((batsman, index) => (
              <div key={index} className="grid grid-cols-12 items-center md:text-sm text-[10px] border-b">
                <div className="col-span-6 md:col-span-7 sm:grid sm:grid-cols-7 py-1">
                  <div className={`${shimmerClassName} px-4 ${batsman.how_out === 'Not out' ? `text-[#3A32D1]` : `text-[#6C6C6C]`} font-semibold col-span-3`}>
                  <p className="inline-block pr-2 md:text-sm text-xs">
                  {batsman.name} {batsman.role_str}{batsman.position === 'striker' ? '*' : ''}
                    </p>
                    <p className="inline-block mt-1">
                      {batsman.out === 'true' && <img src={iconSUB} className="h-[12px] md:h-[16px]" />}
                    </p>
                    <p className="inline-block mt-1">
                      {batsman.substitute === 'true' && <img src={iconIMP} className="h-[12px] md:h-[16px]" />}
                    </p>
                  </div>
                  <p className="px-4 text-[#6C6C6C] text-[9px] md:text-[12px] md:font-semibold col-span-4">
                    <span className={batsman.how_out === 'Not out' ? 'text-[#222529] md:font-black' : ''}>
                      {batsman.how_out === 'Not out' ? 'Not out' : batsman.how_out}
                    </span>
                  </p>
                </div>
                <p className="md:px-4 px-2 py-2 font-semibold text-right md:text-[#222529] text-[#6C6C6C]">{batsman.runs}</p>
                <p className="md:px-4 px-2 py-2 font-semibold text-right md:text-[#222529] text-[#6C6C6C]">
                  {batsman.balls_faced}
                </p>
                <p className="md:px-4 px-2 py-2 text-right font-semibold md:text-[#222529] text-[#6C6C6C]">{batsman.fours}</p>
                <p className="md:px-4 px-2 py-2 text-right font-semibold md:text-[#222529] text-[#6C6C6C]">{batsman.sixes}</p>
                <p className="md:px-2 text-right px-4 py-2 font-semibold md:col-span-1 col-span-2 md:text-[#222529] text-[#6C6C6C]">
                  {batsman.strike_rate}
                </p>
              </div>
            ))}
            <div className="grid grid-cols-4 text-xs md:text-sm border-b">
              <p className='px-4 py-2 font-semibold'>{`Extras ${extraRuns.total || 0}`}</p>
              <p className="md:px-4 pr-9 py-2 text-end md:text-start col-span-3 md:text-[#222529] text-[#6C6C6C] md:text-sm text-[10px] font-semibold">{`(${extraRuns.byes || 0}b, ${extraRuns.legbyes || 0}lb, ${extraRuns.wides || 0}wd, ${extraRuns.noballs || 0}nb, ${extraRuns.penalty || 0}p)`}</p>
            </div>
            <div className="text-[16px] border-b grid grid-cols-4">
              <p className="px-4 py-2 md:font-bold font-semibold">Total</p>
              <p className="md:px-4 pr-9 py-2 col-span-3 text-end md:text-start md:font-bold font-semibold">{`${inning?.scores_full}`}</p>
            </div>
            {inning?.did_not_bat?.length > 0 &&
              <div className="md:text-sm text-xs md:border-b pb-2">
                <p className='px-4 py-2 font-semibold md:text-[#000000] text-[#6C6C6C]'>
                  Yet to bat <br />
                  <span className="text-blue-800 md:font-medium font-semibold">
                    {inning?.did_not_bat?.length > 0 && inning?.did_not_bat.map((p) => p.name).join(", ")}
                  </span>
                </p>
              </div>
            }
          </div>
          <div className="bg-[#EEF0F3] md:mt-4 border border-y-0 border-[#DEDEDE] md:border-none md:rounded-xl md:px-4 py-2">
            <div className="text-left text-[10px] text-[#6C6C6C] md:text-black md:text-[16px] grid grid-cols-8">
              <p className="md:px-0 px-4 font-bold col-span-3 text-black text-sm md:text-base">
                Bowlers
              </p>
              <p className="sm:px-3 px-4 font-bold text-right">O</p>
              <p className="sm:px-2 px-4 text-right font-bold">M</p>
              <p className="sm:px-2 px-4 font-bold text-right">R</p>
              <p className="sm:px-0 px-4 font-bold text-right">W</p>
              <p className="px-4 text-right font-bold">ER</p>
            </div>
          </div>
          <div className="bg-white border border-b-0 border-[#DEDEDE] md:border-none">
            {bowlers.map((bowler, index) => (
              <div key={index} className="grid grid-cols-8 md:text-sm text-[10px] border-b py-2">
                <p className={`${shimmerClassName} px-4 ${bowler.bowling === 'true' ? `text-[#3A32D1]` : `text-[#6C6C6C]`} font-semibold col-span-3 md:text-sm text-xs`}>
                  {bowler.name} {bowler.bowling === 'true' ? '*' : ''}
                </p>
                <p className="sm:px-4 px-4 font-semibold md:text-[#222529] text-[#6C6C6C] text-right">
                  {bowler.overs}
                </p>
                <p className="sm:px-4 px-4 text-right font-semibold md:text-[#222529] text-[#6C6C6C]">
                  {bowler.maidens}
                </p>
                <p className="sm:px-4 px-4 font-semibold md:text-[#222529] text-[#6C6C6C] text-right">
                  {bowler.runs_conceded}
                </p>
                <p className="sm:px-4 px-4 text-right font-semibold md:text-[#222529] text-[#6C6C6C]">
                  {bowler.wickets}
                </p>
                <p className="sm:px-4 pr-4 text-right font-semibold md:text-[#222529] text-[#6C6C6C]">
                  {bowler.econ}
                </p>
              </div>
            ))}
          </div>
          <div className="bg-[#EEF0F3] border border-y-0 border-[#DEDEDE] md:border-none md:rounded-xl md:mt-4">
            <div className="text-left text-[10px] text-[#6C6C6C] md:text-black md:text-[16px] grid grid-cols-5">
              <p className="px-4 py-2 font-bold col-span-3 text-black text-sm md:text-base">
                Fall Of Wickets
              </p>
              <p className="sm:px-4 px-2 py-2 font-bold">Score</p>
              <p className="px-4 py-2 font-bold md:text-start text-right">Over</p>
            </div>
          </div>
          <div className={`bg-white border border-b border-[#DEDEDE] md:border-none ${inning?.name === selectedIninng ? "rounded-b-xl md:rounded-none" : ""}`}>
            {fows.map((fow, index) => (
              <div key={index} className={`grid grid-cols-5 md:text-sm text-[10px] ${index === fows.length - 1 ? '' : 'border-b'}`}>
                <p className={`${shimmerClassName} px-4 py-2 font-semibold text-[#6C6C6C] col-span-3 md:text-sm text-xs`}>
                  <span className="text-[#000000]">{fow.number} - </span> {fow.name}
                </p>
                <p className="sm:px-4 px-2 py-2 font-semibold md:text-[#222529] text-[#6C6C6C]">
                  {fow.score_at_dismissal}
                </p>
                <p className="md:px-4 px-6 py-2 font-semibold md:text-[#222529] text-[#6C6C6C] md:text-start text-right">
                  {fow.overs_at_dismissal}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  if (isError) {
    return <h2></h2>;
  }

  const shimmerClassName = matchData?.isShimmer ? "bg-gray-300 loading" : "";

  return (
    <div className="relative md:bg-white pb-5 md:mt-5 md:rounded-2xl md:border md:mx-5 " >
      <div ref={containerRef} className={`flex ${inningsData?.info?.scorecard?.innings?.length > 2 ? "pt-5 md:pl-5 pl-[6px]" : ""} overflow-x-auto text-nowrap hideScrollbar`}>
        {inningsData?.info?.scorecard?.innings?.length > 2 &&
          inningsData?.info?.scorecard?.innings?.map((inning, index) => {
            return (
              <div data-testid={getSnakeCaseId(inning?.name)} onClick={() => setSelectInnings(inning?.name)} className={`text-base flex items-center mx-1 px-1 py-1 rounded-[10px] ${selectedIninng === inning?.name ? `bg-blue-700 text-white` : "border-2 bg-white border-[#EEEEEF]"}`}>
                <div className="flex items-center text-xs md:text-base px-3">
                  <div>{inning?.name}</div>
                  <div className="h-[30px] w-[30px] ml-2">
                    <img src={inning?.batting_team_id === matchData?.teama?.team_id ? matchData?.teama?.logo : matchData?.teamb?.logo} />
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      {inningsData?.info?.scorecard?.innings?.map((inning) => {
        return renderTeamScorecard(inning);
      })}
      <div className="px-[6px] md:hidden">
        <div>
          <div className="bg-[#F9FBFF] rounded-[14px]">
            <div id="match-info" className="my-5 rounded-[14px] border-[#DAE6F8] border">
              <div className="bg-[#E8F1FF] rounded-t-[14px] md:text-2xl text-base leading-6 p-4 font-bold">
                <div className="flex items-center gap-1 md:justify-start justify-center col-span-2">
                  <span>Match Info</span>
                </div>
              </div>
              <div className="md:grid md:grid-cols-5 px-2 md:px-4 md:py-3 py-2 border-b border-[#DAE6F8] md:text-[16px] text-[10px] flex items-center justify-between">
                  <div className="flex items-center col-span-2 gap-1.5">
                    <img src={toss} alt="icon" className="w-[24px] h-[24px] md:w-[40px] md:h-[40px]" />
                    <span className="opacity-50">Toss:</span>
                  </div>
                    <p className="md:col-span-3 md:font-semibold font-medium md:text-left text-right md:max-w-full max-w-[240px]">{matchData?.toss || '-'}</p>
                </div>
              <div className="md:grid md:grid-cols-5 px-2 md:px-4 md:py-3 py-2 border-b border-[#DAE6F8] md:text-[16px] text-[10px] flex items-center justify-between">
                  <div className="flex items-center col-span-2 gap-1.5">
                    <img src={serieslogo} alt="icon" className="w-[24px] h-[24px] md:w-[40px] md:h-[40px]" />
                    <span className="opacity-50">Series:</span>
                  </div>
                    <p className="md:col-span-3 md:font-semibold font-medium md:text-left text-right md:max-w-full max-w-[240px]">{matchData?.tournament_name || '-'}</p>
                </div>
              <div className="md:grid md:grid-cols-5 grid-cols-3 px-2 md:px-4 md:py-3 py-2 border-[#DAE6F8] md:text-[16px] text-[10px] flex justify-between">
                <div className="flex items-center col-span-2 gap-1">
                  <img src={datelogo} alt="icon" className="w-[24px] h-[24px] md:w-[40px] md:h-[40px]" />
                  <span className="opacity-50">Date & Time:</span>
                </div>
                <p className={`md:col-span-3 col-span-2 md:font-semibold font-medium md:text-left flex items-center`}>{matchData?.date_start}</p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="bg-[#F9FBFF] rounded-[14px]">
            <div id="umpires" className="my-5 rounded-[14px] border-[#DAE6F8] border">
              <div className="bg-[#E8F1FF] rounded-t-[14px] md:text-2xl text-base leading-6 p-4 font-bold">
                <div className="flex items-center gap-1 md:justify-start justify-center col-span-2">
                  <span>Umpires</span>
                </div>
              </div>
              <div className="md:grid md:grid-cols-5 px-2 md:px-4 md:py-3 py-2 border-b border-[#DAE6F8] md:text-[16px] text-[10px] flex items-center justify-between">
                  <div className="flex items-center col-span-2 gap-1.5">
                    <img src={umpires} alt="icon" className="w-[24px] h-[24px] md:w-[40px] md:h-[40px]" />
                    <span className="opacity-50">Umpires:</span>
                  </div>
                    <p className="md:col-span-3 md:font-semibold font-medium md:text-left text-right md:max-w-full max-w-[220px]">{matchData?.umpires || '-'}</p>
                </div>
              <div className="md:grid md:grid-cols-4 px-2 md:px-4 md:py-3 py-2  border-b border-[#DAE6F8] md:text-[16px] text-[10px] flex items-center justify-between">
                <div className="flex items-center col-span-2 gap-1">
                  <img src={thirdUmpire} alt="icon" className="w-[24px] h-[24px] md:w-[40px] md:h-[40px]" />
                  <span className="opacity-50">Third Umpire:</span>
                </div>
                <p className={`md:col-span-2 md:font-semibold font-medium md:text-left text-right md:max-w-full max-w-[220px]`}>{matchData?.thirdUmpires || '-'}</p>
              </div>
              <div className="md:grid md:grid-cols-4 px-2 md:px-4 md:py-3 py-2 border-[#DAE6F8] md:text-[16px] text-[10px] flex items-center justify-between">
                <div className="flex items-center col-span-2 gap-1">
                  <img src={referee} alt="icon" className="w-[24px] h-[24px] md:w-[40px] md:h-[40px]" />
                  <span className="opacity-50">Match Referee:</span>
                </div>
                <p className={`md:col-span-2 md:font-semibold font-medium md:text-left text-right md:max-w-full max-w-[220px]`}>{matchData?.referee || '-'}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Scorecard;
